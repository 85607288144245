import dayjs from 'dayjs'
import React, { useState } from 'react'
import { usePollingQuery } from '~/base'
import { ActivityIndicator, SimpleBar } from '~/components'
import { Config } from '~/config'
import { MatterportModelsQuery } from '~/models'
import { MatterportProcessModal } from './matterport-process-modal'
import MATTERPORT_SPACE_QUERY from './query-matterport-models.gql'

export const MatterportIntegration = () => {
  const query = usePollingQuery<MatterportModelsQuery>(MATTERPORT_SPACE_QUERY)
  const [process, setProcess] = useState<MatterportModelsQuery['matterportModels'][0]>()

  const models = query?.data?.matterportModels || []
  const loading = query.loading
  const error = query.error

  return (
    <>
      {error && (
        <div className='profile'>
          <div className='error'>{error + ''}</div>
          <br />
          <br />
          <button
            className='profile-form-btn btn'
            onClick={() => {
              const clientId = Config.MatterportClientID
              const redirectURL = `${Config.MatterportRedirectURL}?d=${window.location.origin}`
              const scope = Config.MatterportScope
              const url = `https://authn.matterport.com/oauth/authorize?client_id=${clientId}&response_type=code&scope=${scope}&redirect_uri=${redirectURL}`
              window.location.href = url
            }}
          >
            Authenticate with Matterport
          </button>
        </div>
      )}
      {loading && (
        <div className='profile'>
          <ActivityIndicator />
        </div>
      )}
      {!error && !loading && (
        <SimpleBar>
          <div className='matterport-space-container'>
            <h6>Matterport Spaces</h6>
            <div className='matterport-space-cards'>
              {models.map((m) => {
                return (
                  <div key={m.name} className='matterport-space-card'>
                    <div>
                      <img src={m.imageURL} />
                      <div className='text'>
                        <div className='title' title={m.name}>
                          {m.name}
                        </div>
                        <div className='date' title={m.date}>
                          {dayjs(m.date).format('d MMMM YYYY')}
                        </div>
                      </div>
                      <div className='process' onClick={() => setProcess(m)}>
                        Process
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </SimpleBar>
      )}
      {process && (
        <MatterportProcessModal
          model={process}
          onCancel={() => setProcess(undefined)}
          onComplete={() => {
            setProcess(undefined)
            return Promise.resolve()
          }}
        />
      )}
    </>
  )
}
