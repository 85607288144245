import React, { useEffect, useRef, useState } from 'react'
import { classes, Helmet, PolygonColorRed, useMutation } from '~/components'
import {
  ActionHover,
  DrawerForm,
  DrawerFormBreadcrumbItem,
  DrawerFormBreadcrumbs,
  DrawerFormContent,
  getImageURL,
  Transition,
  useLocation,
  useToasts,
} from '~/components'
import { ProjectionType, useAppState } from '~/state'
import { ClientPermission, useUser } from '~/base'
import {
  ImageByHashQuery,
  ImageByHashQueryVariables,
  IssueStatus,
  RiskType,
  SiteQueryIssue,
  SurveyBasedOperation,
  UpdateManuallyTaggedIssueMutation,
  UpdateManuallyTaggedIssueMutationVariables,
} from '~/models'
import { IssueRemove } from './issue-remove'
import { useQuery } from '~/components'
import IMAGE_BY_HASH_QUERY from '~/state/query-image-by-hash.gql'
import { ExpandedPanel } from '~/state/use-drawer-state'
import { Offset, Point } from './components'
import { IssueBadge, IssueBadgeContainer } from './issue-badge'
import UPDATE_MANUALLY_TAGGED_ISSUE from './mutation-issues-update.gql'
import { IssueRepairEstimate } from './issue-repair-estimate'

export const IssueDetail = () => {
  const user = useUser()
  const location = useLocation()
  const toasts = useToasts()
  const { imageTagger, view, map, site, issues, timeline, asset, drawer } = useAppState()
  const [showRemoveIssue, setShowRemoveIssue] = useState(false)
  const title =
    issues?.issue?.component?.name ||
    issues?.issue?.component?.componentTypeName ||
    issues?.issue?.componentTypeName ||
    'Issue'

  const issueIdx = issues?.issues.findIndex((i) => i.id === issues.issue?.id)
  const issue = issues?.issue

  const imageQuery = useQuery<ImageByHashQuery, ImageByHashQueryVariables>(IMAGE_BY_HASH_QUERY, {
    variables: {
      input: {
        hash: '',
      },
    },
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  const [executeUpdate] = useMutation<UpdateManuallyTaggedIssueMutation, UpdateManuallyTaggedIssueMutationVariables>(
    UPDATE_MANUALLY_TAGGED_ISSUE
  )

  useEffect(() => {
    if (!issue) {
      return
    }

    if (issue.isInternal) {
      if (issue.internalReconstructionID !== timeline.activeInteriorModel?.id) {
        const recon = timeline.activeSurvey.reconstructions.find((r) => r.id === issue.internalReconstructionID)
        if (recon) {
          timeline.setActiveInteriorModel(recon)
          return
        }
      }
    } else {
      if (timeline.activeInteriorModel) {
        timeline.setActiveInteriorModel(undefined)
      }
    }

    setTimeout(() => {
      // Go to issue location
      if (map.projectionType === ProjectionType.Projection3D) {
        imageQuery
          .refetch({
            input: {
              hash: issue.imageHash,
            },
          })
          .then((res) => {
            const lat = issue.location.latitude
            const lng = issue.location.longitude
            const alt = issue.location.altitude
            const target = Cesium.Cartesian3.fromDegrees(lng, lat, alt)

            const photo = res.data.queryImageByHash.image
            const origin = Cesium.Cartesian3.fromDegrees(photo.longitude, photo.latitude, alt + 5)

            const direction = Cesium.Cartesian3.subtract(target, origin, new Cesium.Cartesian3())
            Cesium.Cartesian3.normalize(direction, direction)

            const reverseDir = Cesium.Cartesian3.multiplyByScalar(direction, -50, new Cesium.Cartesian3())
            const destination = Cesium.Cartesian3.add(target, reverseDir, new Cesium.Cartesian3())

            map.viewer.camera.flyTo({
              destination,
              orientation: {
                direction,
                up: new Cesium.Cartesian3(0, 0, 0),
              },
              duration: 0.75,
            })
          })
      } else {
        map.flyTo(issue.location.latitude, issue.location.longitude, 10)
      }

      if (imageTagger.isVisible) {
        imageTagger.loadImageByHash(issue.imageHash)
      }
    }, 0)
  }, [issue?.location?.latitude, map.projectionType, timeline.activeInteriorModel?.id])

  const isDemoSite = site.site?.isDemoSite
  const canUpdate = !isDemoSite && user.hasPermission(ClientPermission.IssuesUpdate)
  const canRemove = !isDemoSite && user.hasPermission(ClientPermission.IssuesRemove)
  const issueAsset = (timeline.activeSurvey?.assets || []).find((a) => a.id === issue?.assetID)

  if (issues.issueToUpdate) {
    return null
  }

  console.log(issue)

  return (
    <DrawerForm>
      <Helmet title={title} />
      <DrawerFormBreadcrumbs>
        <DrawerFormBreadcrumbItem
          title={asset.asset?.name || site.site?.name}
          onClick={() => {
            if (view.assetID) {
              location.setLocation(`/${site.site.id}/${view.surveyID}/assets/${view.assetID}`)
            } else {
              location.setLocation(`/${site.site.id}/${view.surveyID}`)
            }
          }}
        />
        <DrawerFormBreadcrumbItem title={title} />
      </DrawerFormBreadcrumbs>

      <DrawerFormContent noPaddingRight>
        <div className='issue-detail'>
          <div className='issue-detail-issue-number'>
            ISSUE {issue?.number}
            <Transition width={120} in={!!issue}>
              <div className='issue-detail-next-prev-container'>
                <div
                  onClick={() => {
                    if (issueIdx > 0) {
                      if (view.assetID) {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/assets/${view.assetID}/issue/${
                            issues.issues[issueIdx - 1].id
                          }`
                        )
                      } else {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/issue/${issues.issues[issueIdx - 1].id}`
                        )
                      }
                    }
                  }}
                  className={classes({
                    disabled: issueIdx === 0,
                  })}
                >
                  <i className='material-icons'>arrow_left</i>
                </div>
                <div
                  onClick={() => {
                    if (issueIdx < issues.issues.length - 1) {
                      if (view.assetID) {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/assets/${view.assetID}/issue/${
                            issues.issues[issueIdx + 1].id
                          }`
                        )
                      } else {
                        location.setLocation(
                          `/${site.site.id}/${view.surveyID}/issue/${issues.issues[issueIdx + 1].id}`
                        )
                      }
                    }
                  }}
                  className={classes({
                    disabled: issueIdx === issues.issues.length - 1,
                  })}
                >
                  <i className='material-icons'>arrow_right</i>
                </div>
              </div>
            </Transition>
          </div>
          <div className='issue-detail-name'>
            <Transition width={120} in={!!issue}>
              <div className='issue-detail-content'>
                <h1>{title}</h1>
                {canUpdate && (
                  <div style={{ position: 'relative' }}>
                    <ActionHover
                      withContainer
                      canEdit={canUpdate}
                      canRemove={canRemove}
                      onClickDelete={() => {
                        setShowRemoveIssue(true)
                      }}
                      onClickEdit={() => {
                        issues.setIssueToUpdate(issue)
                      }}
                    />
                  </div>
                )}
              </div>
            </Transition>
          </div>
          {showRemoveIssue && (
            <IssueRemove
              onRemove={() => {
                let p: Promise<any>
                if (view.assetID) {
                  p = site.refetch().then(() => {
                    location.setLocation(`/${site.site.id}/${view.surveyID}/assets/${view.assetID}`)
                  })
                } else {
                  p = site.refetch().then(() => {
                    location.setLocation(`/${site.site.id}/${view.surveyID}`)
                  })
                }
                p.then(() => {
                  toasts.addTopLeft('Issue successfully removed')
                })
              }}
              hide={() => {
                setShowRemoveIssue(false)
              }}
            />
          )}
          <IssueBadgeContainer>
            <IssueBadge
              canEdit={canUpdate}
              type='risk'
              subType={issue?.riskType}
              options={[
                {
                  id: RiskType.High,
                  text: 'High Risk',
                },
                {
                  id: RiskType.Medium,
                  text: 'Medium Risk',
                },
                {
                  id: RiskType.Low,
                  text: 'Low Risk',
                },
              ]}
              update={(v) => {
                if (issue.riskType === v) {
                  return Promise.resolve()
                }

                return executeUpdate({
                  variables: {
                    input: {
                      operationType: SurveyBasedOperation.Singular,
                      siteID: view.siteID,
                      surveyID: view.surveyID,
                      items: [
                        {
                          id: issue.id,
                          name: issue.name,
                          priority: issue.priority,
                          riskType: v as RiskType,
                          notes: issue.notes,
                          repairEstimate: issue.repairEstimate / 100,
                          status: issue.status,
                          location: issue.location
                            ? {
                                altitude: issue.location.altitude,
                                latitude: issue.location.latitude,
                                longitude: issue.location.longitude,
                              }
                            : {
                                altitude: 0,
                                latitude: 0,
                                longitude: 0,
                              },
                          coordinates: (issue.coordinates || []).map((c) => ({
                            x: c.x,
                            y: c.y,
                          })),
                          defectTypeID: issue.defectTypeID,
                          defectSubTypeID: issue.defectSubTypeID,
                          componentID: issue.component?.id || '',
                          componentTypeID: issue?.component?.componentTypeID || issue?.componentTypeID || '',
                          assetID: issue.assetID || '',
                          assetTypeID: issue?.assetTypeID || '',
                          materialTypeID: issue?.materialTypeID || '',
                          isInternal: issue.isInternal,
                          internalPoint: issue.internalPoint,
                          internalReconstructionID: issue.internalReconstructionID,
                        },
                      ],
                    },
                  },
                }).then(() => {
                  return site.refetch()
                })
              }}
            />
            <IssueBadge
              canEdit={canUpdate}
              type='status'
              subType={issue?.status}
              options={[
                {
                  id: IssueStatus.AwaitingAction,
                  text: 'Awaiting Action',
                },
                {
                  id: IssueStatus.InProgress,
                  text: 'In Progress',
                },
                {
                  id: IssueStatus.Complete,
                  text: IssueStatus.Complete,
                },
              ]}
              update={(v) => {
                if (issue.status === v) {
                  return Promise.resolve()
                }

                return executeUpdate({
                  variables: {
                    input: {
                      operationType: SurveyBasedOperation.Singular,
                      siteID: view.siteID,
                      surveyID: view.surveyID,
                      items: [
                        {
                          id: issue.id,
                          name: issue.name,
                          priority: issue.priority,
                          riskType: issue.riskType,
                          notes: issue.notes,
                          repairEstimate: issue.repairEstimate / 100,
                          status: v as IssueStatus,
                          location: issue.location
                            ? {
                                altitude: issue.location.altitude,
                                latitude: issue.location.latitude,
                                longitude: issue.location.longitude,
                              }
                            : {
                                altitude: 0,
                                latitude: 0,
                                longitude: 0,
                              },
                          coordinates: (issue.coordinates || []).map((c) => ({
                            x: c.x,
                            y: c.y,
                          })),
                          defectTypeID: issue.defectTypeID,
                          defectSubTypeID: issue.defectSubTypeID,
                          componentID: issue.component?.id || '',
                          componentTypeID: issue?.component?.componentTypeID || issue?.componentTypeID || '',
                          assetID: issue.assetID || '',
                          assetTypeID: issue?.assetTypeID || '',
                          materialTypeID: issue?.materialTypeID || '',
                          isInternal: issue.isInternal,
                          internalPoint: issue.internalPoint,
                          internalReconstructionID: issue.internalReconstructionID,
                        },
                      ],
                    },
                  },
                }).then(() => {
                  return site.refetch()
                })
              }}
            />
            <div className='issue-detail-badges-filler'></div>
          </IssueBadgeContainer>

          {issueAsset && (
            <div className='issue-detail-section'>
              <div className='issue-detail-subheading'>Asset</div>
              <div className='issue-detail-content'>{issueAsset?.name || '-'}</div>
            </div>
          )}
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Asset Type</div>
            <div className='issue-detail-content'>{issueAsset?.assetType?.name || issue?.assetTypeName || '-'}</div>
          </div>
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Priority</div>
            <div className='issue-detail-content'>{issue?.priority}</div>
          </div>
          {issue?.component && (
            <div className='issue-detail-section'>
              <div className='issue-detail-subheading'>Component</div>
              <div className='issue-detail-content'>
                {issue?.component?.name || issue?.component?.componentTypeName || '-'}
              </div>
            </div>
          )}
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Component Type</div>
            <div className='issue-detail-content'>{issue?.componentTypeFullName || '-'}</div>
          </div>
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Material Type</div>
            <div className='issue-detail-content'>{issue?.materialTypeName || '-'}</div>
          </div>
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Defect Type</div>
            <div className='issue-detail-content'>
              {issue?.defectType}
              {issue?.defectSubType ? ` - ${issue?.defectSubType}` : ''}
            </div>
          </div>
          {issue?.notes && (
            <div className='issue-detail-section'>
              <div className='issue-detail-subheading'>Notes</div>
              <div className='issue-detail-content'>{issue?.notes || '-'}</div>
            </div>
          )}
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>Repair Estimate</div>
            <div className='issue-detail-content'>
              <IssueRepairEstimate issue={issue} />
            </div>
          </div>
          <div className='issue-detail-section'>
            <div className='issue-detail-subheading'>PHOTO</div>
            <div className='issue-detail-content'>
              {issue?.imageHash && <IssueItemImage key={issue.id + issue.surveyID} issue={issue} />}
            </div>
          </div>

          <br />
          {!imageTagger.isVisible ? (
            <div
              onClick={() => {
                imageTagger.loadImageByHash(issue.imageHash)
              }}
              className='drawer-list-content-more'
            >
              View photo
            </div>
          ) : (
            <div
              onClick={() => {
                imageTagger.hide()
              }}
              className='drawer-list-content-more flipped'
            >
              Hide photo
            </div>
          )}
          {drawer.expandedPanel !== ExpandedPanel.Issues ? (
            <div
              onClick={() => {
                drawer.setExpandedPanel(ExpandedPanel.Issues)
                //imageTagger.hide()
              }}
              className='drawer-list-content-more'
            >
              View all issues
            </div>
          ) : (
            <div
              onClick={() => {
                drawer.closeExpandedPanel()
              }}
              className='drawer-list-content-more flipped'
            >
              Hide all issues
            </div>
          )}
        </div>
      </DrawerFormContent>
    </DrawerForm>
  )
}

interface IssueItemImageProps {
  issue: SiteQueryIssue
}

function IssueItemImage(props: IssueItemImageProps) {
  const { site } = useAppState()
  const canvas = useRef<HTMLCanvasElement>()
  const [imageUrl, setImageUrl] = useState<string>()

  // Load the image.
  useEffect(() => {
    if (!canvas.current || imageUrl) {
      return
    }

    const canvasCurrent = canvas.current
    const ctxCanvas = canvasCurrent.getContext('2d')
    ctxCanvas.imageSmoothingEnabled = true
    ctxCanvas.imageSmoothingQuality = 'high'
    ctxCanvas.canvas.width = canvasCurrent.clientWidth
    ctxCanvas.canvas.height = canvasCurrent.clientHeight

    const mediumImg = new Image()

    mediumImg.onload = () => {
      if (!canvas.current) {
        return
      }

      const getCanvasPositionFull = (p: Point): Offset => {
        return {
          left: (p.x / mediumImg.width) * canvasCurrent.width,
          top: (p.y / mediumImg.height) * canvasCurrent.height,
        }
      }

      ctxCanvas.drawImage(
        mediumImg,
        0,
        0,
        mediumImg.width,
        mediumImg.height,
        0,
        0,
        canvasCurrent.width,
        canvasCurrent.height
      )

      const coordsFull = [...props.issue.coordinates, props.issue.coordinates[0]]
      const coordsCropped = coordsFull.map((p) => {
        return {
          x: p.x * mediumImg.width,
          y: p.y * mediumImg.height,
        }
      })

      ctxCanvas.save()
      ctxCanvas.fillStyle = PolygonColorRed
      ctxCanvas.strokeStyle = PolygonColorRed
      ctxCanvas.lineWidth = 2

      for (let i = 1; i < coordsCropped.length; i++) {
        const previousPosition = getCanvasPositionFull(coordsCropped[i - 1])
        const currentPosition = getCanvasPositionFull(coordsCropped[i])
        ctxCanvas.beginPath()
        ctxCanvas.moveTo(previousPosition.left, previousPosition.top)
        ctxCanvas.lineTo(currentPosition.left, currentPosition.top)
        ctxCanvas.closePath()
        ctxCanvas.stroke()
      }

      ctxCanvas.restore()

      const dataUrl = canvas.current.toDataURL()
      setImageUrl(dataUrl)
    }

    mediumImg.setAttribute('crossorigin', 'anonymous')
    mediumImg.src = getImageURL(
      {
        hash: props.issue.imageHash,
      },
      site.site.orgID
    )
  }, [canvas.current, imageUrl])

  if (imageUrl) {
    return <img className='issue-detail-image' src={imageUrl} />
  }

  return <canvas className='issue-detail-image' ref={canvas}></canvas>
}
