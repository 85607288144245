import React, { useCallback, useEffect, useRef, useState } from 'react'
import { ClientPermission, useUser } from '~/base'
import { useLocation, SimpleBar } from '~/components'
import { SitesQuerySiteWithExtra } from '~/models'
import { useAppState } from '~/state'
import { SitesCard } from './sites-card'
import { FixedSizeList as List, ReactElementType } from 'react-window'
import { SetShowDemoModal } from './set-show-demo-modal'
import { SitesListTable } from './sites-list-table'

// Taken from https://codesandbox.io/s/t4352?file=/index.js:381-394
const CustomScrollbars = ({ onScroll, forwardedRef, style, children }: any) => {
  const refSetter = useCallback(
    (scrollbarsRef: any) => {
      if (scrollbarsRef) {
        forwardedRef(scrollbarsRef.current.el)
      } else {
        forwardedRef(null)
      }
    },
    [forwardedRef]
  )

  return (
    <SimpleBar ref2={refSetter} style={{ ...style }} ignoreMaxHeight onScrollRaw={onScroll}>
      {children}

      <div style={{ height: '100px' }}></div>
    </SimpleBar>
  )
}

const CustomScrollbarsVirtualList = React.forwardRef((props, ref) => <CustomScrollbars {...props} forwardedRef={ref} />)

interface SitesListCardsProps {
  filter: string[]
  onCreateSite: () => void
  sites: SitesQuerySiteWithExtra[]
  isLoading: boolean
  showEmpty: boolean
  haveSites: boolean
  showFullList: boolean
}

export const SitesListCards = (props: SitesListCardsProps) => {
  const { drawer, sites, timeline } = useAppState()
  const location = useLocation()
  const user = useUser()
  const canListIssues = user.hasPermission(ClientPermission.IssuesList)

  const listRef = React.createRef()
  const outerRef = React.createRef()
  const sitesListRef = useRef<HTMLDivElement>()
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 800,
  })
  const [showHideDemoSites, setShowHideDemoSites] = useState(false)
  const [itemsRendered, setItemsRendered] = useState(false)

  useEffect(() => {
    const elem = document.getElementById('sites-list')
    if (!sitesListRef.current || !elem) {
      return
    }
    const resizer = new ResizeObserver((entries) => {
      setSize({
        width: entries[0].contentRect.width,
        height: entries[0].contentRect.height,
      })
    })

    resizer.observe(sitesListRef.current)

    if (itemsRendered) {
      setSize({
        width: elem.clientWidth,
        height: elem.clientHeight,
      })
    }

    return () => {
      resizer.disconnect()
    }
  }, [sitesListRef.current, itemsRendered, drawer.leftExpanded])

  useEffect(() => {
    if (timeline.activeInteriorModel) {
      timeline.setActiveInteriorModel(undefined)
    }
  }, [])

  const onClickHandler = useCallback((site: SitesQuerySiteWithExtra) => {
    location.setLocation('/' + site.s.id + '/' + site.s.lastSurveyID)
  }, [])

  const Row = ({ index, style }: { index: number; style: React.CSSProperties }) => {
    if (index >= props.sites.length) {
      return <div style={{ height: '100px' }}></div>
    }
    const s = props.sites[index]
    return (
      <SitesCard
        style={style}
        key={s.s.id}
        site={s}
        lastUploadTime={s.extra?.lastUpload}
        setShowHideDemoSites={setShowHideDemoSites}
        onClickHandler={onClickHandler}
        canListIssues={canListIssues}
      />
    )
  }

  return (
    <>
      {(props.isLoading || (!itemsRendered && sites?.data?.sites?.length > 0)) && (
        <div className='sites-list-loading body2'>Loading...</div>
      )}
      {sites.error && (
        <div className='sites-list-error'>
          <p className='body2 mb-3'>
            An error occurred
            <br />
            while loading sites.
          </p>
          <button className='btn btn-link' onClick={sites.refetch}>
            Click to retry
          </button>
        </div>
      )}
      {props.haveSites && !props.showFullList && (
        <div className='sites-list-card-container' ref={sitesListRef}>
          <List
            width={240}
            height={size.height}
            itemCount={props.sites.length}
            itemSize={75}
            innerRef={listRef}
            outerElementType={CustomScrollbarsVirtualList as ReactElementType}
            outerRef={outerRef}
            overscanCount={2}
            onItemsRendered={() => {
              if (!itemsRendered) {
                setItemsRendered(true)
              }
            }}
          >
            {Row}
          </List>
          <div>
            {itemsRendered && props.sites.length === 0 && (
              <div className='sites-list-loading body2'>No sites to display.</div>
            )}
          </div>
        </div>
      )}
      {props.showEmpty && (
        <div className='sites-list-container-empty'>
          <div className='body2 mb-4'>You don't have any sites yet</div>
          <div className='sites-list-add-site' onClick={props.onCreateSite}>
            <span className='sites-list-add-site-title'>Add a site</span>
            <i className='material-icons'>add</i>
          </div>
        </div>
      )}
      {props.haveSites && props.showFullList && (
        <SimpleBar>
          <SitesListTable sites={props.sites} />
        </SimpleBar>
      )}
      {showHideDemoSites && (
        <SetShowDemoModal mustEnable={false} onCancel={() => setShowHideDemoSites(false)} onComplete={sites.refetch} />
      )}
    </>
  )
}
