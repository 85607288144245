import React, { useEffect, useState } from 'react'
import {
  ComponentCreateRequestT,
  CreateComponentMutation,
  SiteQueryAsset,
  ComponentTypeSmallDtoT,
  ComponentDtoT,
  SiteQuerySite,
  AssetTypeDtoT,
  GetAssetTypeByIdQuery,
  GetAssetTypeByIdQueryVariables,
  ComponentCondition,
  MeasurementType,
  SurveyBasedOperation,
  MaterialTypeSmallDtoT,
  SiteQueryComponent,
} from '~/models'
import { FloatingInput, useForm } from '../forms'
import { string, object } from '~/components'
import CREATE_COMPONENT from './mutation-component-create.gql'
import { ComponentTypeSelect } from './component-type-select'
import { useQuery } from '~/components'
import ASSET_TYPE_BY_ID_QUERY from '../../assets/query-asset-type-by-id.gql'
import { ComponentConditionSelect } from './component-condition-select'
import { useAppState, useRefState } from '~/state'
import { PolygonRenderer3D, PolygonRendererCreate3D, PolygonRendererEdit3D } from '../polygon-renderer-3d'
import { PolygonColorRed } from '../polygon-renderer'
import { getTracking } from '../tracking'
import { OperationTypeSelect } from '../operation-type'

const COMPONENT_TYPE_LAST_SELECTED_KEY = 'asseti-last-component-types-selected'
const CONDITION_LAST_SELECTED_KEY = 'asseti-last-condition-selected'

interface ComponentCreateFormProps {
  parentComponent?: ComponentDtoT
  asset?: SiteQueryAsset
  site?: SiteQuerySite
  cancel: () => void
  complete: (component: SiteQueryComponent, hide: boolean) => void
}

export const ComponentCreateForm = (props: ComponentCreateFormProps) => {
  const [selectedComponentTypes, setSelectedComponentTypes] = useState<ComponentTypeSmallDtoT[]>([])
  const [selectedMaterialType, setSelectedMaterialType] = useState<MaterialTypeSmallDtoT>(undefined)
  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeDtoT>()
  const [saveAndAdd, setSaveAndAdd] = useState(false)
  const [resetPolygonRenderer, setResetPolygonRenderer] = useState(false)

  const haveSelectedAComponentType = selectedComponentTypes.length > 0

  const assetTypeQuery = useQuery<GetAssetTypeByIdQuery, GetAssetTypeByIdQueryVariables>(ASSET_TYPE_BY_ID_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  const { map, view, timeline } = useAppState()
  const [points, setPoints] = useRefState<Cesium.Cartesian3[]>([])
  const [measurementType, setMeasurementType] = useState<MeasurementType>(MeasurementType.Area)

  useEffect(() => {
    if (resetPolygonRenderer === true) {
      setResetPolygonRenderer(false)
    }

    let renderer: PolygonRenderer3D

    renderer = new PolygonRendererCreate3D(map, PolygonColorRed, measurementType, (points) => {
      if (!points) {
        props.cancel()
        return
      }
      if (renderer) {
        renderer.destroy()
        renderer = undefined
      }
      renderer = new PolygonRendererEdit3D(
        points.map((p) => {
          const pos = Cesium.Cartographic.fromCartesian(p)
          return {
            latitude: Cesium.Math.toDegrees(pos.latitude),
            longitude: Cesium.Math.toDegrees(pos.longitude),
            altitude: pos.height,
          }
        }),
        map,
        PolygonColorRed,
        measurementType,
        (points) => {
          setPoints(points)
        }
      )
    })

    return () => {
      if (renderer) {
        renderer.destroy()
        renderer = undefined
      }
    }
  }, [measurementType, resetPolygonRenderer])

  useEffect(() => {
    assetTypeQuery
      .refetch({
        assetTypeID: props?.asset?.assetType.id,
      })
      .then((res) => {
        const assetType = res.data.assetTypeByID as AssetTypeDtoT
        setSelectedAssetType(assetType)

        const lastItem = localStorage.getItem(COMPONENT_TYPE_LAST_SELECTED_KEY)
        if (lastItem) {
          const ids = lastItem.split(',')
          if (ids.length === 0) {
            return
          }

          let current = assetType.componentTypes.find((x) => x.id === ids[0])
          if (!current) {
            return
          }

          const selected = [current]

          let idx = 1
          do {
            const next = (current.children || []).find((x) => x.id === ids[idx])
            if (next) {
              idx++
              selected.push(next)
              current = next
              continue
            }

            const mt = current.materialTypes.find((x) => x.id === ids[idx])
            setSelectedComponentTypes(selected)
            setSelectedMaterialType(mt)
            setMeasurementType(selected[selected.length - 1].measurement)
            break
          } while (idx < ids.length)
        }
      })
  }, [props.asset?.assetType?.id])

  const {
    values,
    touched,
    errors,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    error,
    setFieldValue,
    submitForm,
  } = useForm<CreateComponentMutation, ComponentCreateRequestT>({
    enableReinitialize: true,
    initialValues: {
      name: '',
      siteID: '',
      assetID: '',
      componentTypeID: '',
      parentComponentID: '',
      points: [],
      assetTypeID: props?.asset?.assetType?.id || '',
      condition:
        (localStorage.getItem(CONDITION_LAST_SELECTED_KEY) as ComponentCondition) || ComponentCondition.Average,
      operationType: SurveyBasedOperation.GoingForward,
      surveyID: view.surveyID,
      materialTypeID: '',
      isInternal: false,
      internalPoints: [],
    },
    validationSchema: object().shape({
      name: string().optional(),
      assetTypeID: string().required(),
    }),
    mutation: CREATE_COMPONENT,
    mapInput: (input: ComponentCreateRequestT): ComponentCreateRequestT => {
      return {
        name: input.name,
        componentTypeID: selectedComponentTypes[selectedComponentTypes.length - 1].id,
        parentComponentID: props.parentComponent?.id || '',
        assetID: props.asset ? props.asset.id : '',
        siteID: props.site ? props.site.id : '',
        points: points.current.map((p) => {
          const pos = Cesium.Cartographic.fromCartesian(p)
          return {
            latitude: (pos.latitude / Math.PI) * 180,
            longitude: (pos.longitude / Math.PI) * 180,
            altitude: pos.height,
          }
        }),
        assetTypeID: props.asset?.assetType?.id || '',
        condition: input.condition,
        operationType: input.operationType,
        surveyID: view.surveyID,
        materialTypeID: selectedMaterialType?.id || '',
        isInternal: false,
        internalPoints: [],
      }
    },
    onSuccess: (res) => {
      getTracking().event({
        category: 'Component',
        action: `User Created Component`,
        label: 'Component',
      })
      if (saveAndAdd) {
        setPoints([])
        setFieldValue('name', '')
        setResetPolygonRenderer(true)
        props.complete(res.componentCreate, false)
      } else {
        props.complete(res.componentCreate, true)
      }
    },
  })

  const isFormInvalid =
    isSubmitting ||
    points.current.length <
      (measurementType === MeasurementType.Area ? 3 : measurementType === MeasurementType.Distance ? 2 : 1) ||
    !haveSelectedAComponentType

  useEffect(() => {
    const handler = () => {
      if (!isFormInvalid) {
        setSaveAndAdd(true)
        submitForm()
      }
    }
    window.addEventListener('keydown', handler, false)

    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [isFormInvalid])

  return (
    <div className='drawer-panel'>
      <nav aria-label='breadcrumb'>
        <ol className='breadcrumb'>
          <li className='breadcrumb-item'>
            <a
              href='#'
              onClick={(e) => {
                e.preventDefault()
                props.cancel()
              }}
            >
              Site
            </a>
          </li>
          <li className='breadcrumb-item active' aria-current='page'>
            Component
          </li>
        </ol>
      </nav>
      <div className='drawer-panel-title-container'>
        <h6 className='drawer-panel-title'>Create Component</h6>
        {props.parentComponent && (
          <span className='drawer-panel-hint'>
            Sub component of {props.parentComponent.name || props.parentComponent.componentTypeFullName}
          </span>
        )}
      </div>
      <form autoComplete='off' className='drawer-panel-form-container' onSubmit={handleSubmit}>
        {error && <div className='error'>{error}</div>}
        <ComponentTypeSelect
          componentTypes={selectedAssetType?.componentTypes || []}
          onChange={(selectedComponentTypes, selectedMaterialType) => {
            setSelectedComponentTypes(selectedComponentTypes)
            setSelectedMaterialType(selectedMaterialType)

            let key = selectedComponentTypes.map((c) => c.id).join(',')
            if (selectedMaterialType) {
              key += ',' + selectedMaterialType.id
            }
            localStorage.setItem(COMPONENT_TYPE_LAST_SELECTED_KEY, key)

            if (
              selectedComponentTypes.length > 0 &&
              selectedComponentTypes[selectedComponentTypes.length - 1].measurement !== measurementType
            ) {
              setMeasurementType(selectedComponentTypes[selectedComponentTypes.length - 1].measurement)
            }
          }}
          selectedComponentTypes={selectedComponentTypes}
          selectedMaterialType={selectedMaterialType}
        />
        <FloatingInput
          label='Name (Optional)'
          id='name'
          value={values.name}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.name ? errors.name : ''}
          error={touched.name && Boolean(errors.name)}
        />
        <ComponentConditionSelect
          onChange={(c) => {
            setFieldValue('condition', c)
            localStorage.setItem(CONDITION_LAST_SELECTED_KEY, c)
          }}
          condition={values.condition}
        />
        <p className='drawer-panel-hint'>
          {measurementType === MeasurementType.Area && (
            <span>Use your cursor to draw a polygon representing your component on the map.</span>
          )}
          {measurementType === MeasurementType.Distance && (
            <span>
              Use your cursor to draw a line representing your component on the map.
              <br />
              Press enter to finish drawing.
            </span>
          )}
          {measurementType === MeasurementType.Point && (
            <span>Click on any point on the map to set the location of this component.</span>
          )}
        </p>
        <OperationTypeSelect
          name='operationType'
          selectedValue={values.operationType}
          onChange={handleChange}
          survey={timeline.activeSurvey}
        />
        <div className='drawer-panel-form-container-action-container'>
          <button
            type='submit'
            className='btn submit mb-2'
            disabled={isFormInvalid}
            onClick={() => {
              setSaveAndAdd(false)
            }}
          >
            Save
          </button>
          <button
            type='submit'
            className='btn submit mb-2'
            disabled={isFormInvalid}
            onClick={() => {
              setSaveAndAdd(true)
            }}
          >
            Save &amp; Add
          </button>
          <button className='btn cancel' onClick={() => props.cancel()}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  )
}
