import React, { useEffect, useState } from 'react'
import { SiteQueryRecon } from '~/models'
import { InteriorModelLoading, InteriorModelLoadingState } from './interior-model-loading'
import { useAppState } from '~/state'

interface InteriorModelImplProps {
  r: SiteQueryRecon
  setLoadingState: (state: { state: InteriorModelLoadingState; percent: number }) => void
}

let InteriorModelComponent: React.FC<InteriorModelImplProps> = undefined

const InteriorModelImpl = (props: InteriorModelImplProps) => {
  const { map } = useAppState()

  useEffect(() => {
    const originalMaximumRenderTimeChange = map.viewer.scene.maximumRenderTimeChange

    import('./interior-model-loader').then((res) => {
      InteriorModelComponent = res.default
      props.setLoadingState({
        state: InteriorModelLoadingState.Model,
        percent: 0,
      })
      map.viewer.scene.maximumRenderTimeChange = Infinity
    })

    return () => {
      map.viewer.scene.maximumRenderTimeChange = originalMaximumRenderTimeChange
    }
  }, [])

  if (InteriorModelComponent) {
    return <InteriorModelComponent {...props} />
  }

  return <></>
}

interface InteriorModelProps {
  r: SiteQueryRecon
}

export const InteriorModel = (props: InteriorModelProps) => {
  const [loadingState, setLoadingState] = useState<{ state: InteriorModelLoadingState; percent: number }>({
    state: InteriorModelLoadingState.Framework,
    percent: 0,
  })
  const loaded = loadingState.state === InteriorModelLoadingState.Pano && loadingState.percent === 100
  return (
    <>
      {!loaded && <InteriorModelLoading state={loadingState.state} percent={loadingState.percent} />}
      <InteriorModelImpl setLoadingState={setLoadingState} r={props.r} />
    </>
  )
}
