import React from 'react'
import { ActivityIndicator } from '~/components'

export enum InteriorModelLoadingState {
  Framework,
  Model,
  Pano,
}

interface InteriorModelLoadingProps {
  state: InteriorModelLoadingState
  percent: number
}

export const InteriorModelLoading = (props: InteriorModelLoadingProps) => {
  return (
    <div className='interior-model-loading-container'>
      <div className='interior-model-loading'>
        <ActivityIndicator white />
        {props.state === InteriorModelLoadingState.Framework && <div>Loading framework</div>}
        {props.state === InteriorModelLoadingState.Model && (
          <div>
            Loading 3D model
            <br />
            {props.percent.toFixed(0)}%
          </div>
        )}
        {props.state === InteriorModelLoadingState.Pano && (
          <div>
            Loading images
            <br />
            {props.percent.toFixed(0)}%
          </div>
        )}
      </div>
    </div>
  )
}
