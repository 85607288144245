import { useMemo, useState } from 'react'
import { SiteQueryBoundary, SiteQuerySurvey } from '~/models'

export enum BoundaryRenderState {
  None,
  Site,
  SiteAndAssets,
  SiteAndAsset,
  Asset,
}

export enum BoundaryRenderType {
  Lines,
  LinesAndLabels,
}

export function useBoundaryState(assetID: string, boundaryID: string, activeSurvey: SiteQuerySurvey) {
  const drawIn3DKey = 'asseti-sites-and-assets-draw-boundaries-in-3d'
  const drawIn3DState = localStorage.getItem(drawIn3DKey) === 'true'

  const drawIn2DKey = 'asseti-sites-and-assets-draw-boundaries-in-2d'
  const drawIn2DLocalStorage = localStorage.getItem(drawIn2DKey)
  const drawIn2DState = drawIn2DLocalStorage === 'true' || drawIn2DLocalStorage === null

  const [drawIn3D, setDrawIn3D] = useState<boolean>(drawIn3DState)
  const [drawIn2D, setDrawIn2D] = useState<boolean>(drawIn2DState)
  const [renderState, setRenderState] = useState<BoundaryRenderState>(BoundaryRenderState.None)
  const [renderType, setRenderType] = useState<BoundaryRenderType>(BoundaryRenderType.LinesAndLabels)
  const [boundaryToEdit, setBoundaryToEdit] = useState<SiteQueryBoundary>()

  const { activeBoundary, activeBoundaries } = useMemo(() => {
    if (!activeSurvey || !boundaryID) {
      return {
        activeBoundary: undefined,
        activeBoundaries: undefined,
      }
    }
    if (assetID) {
      const asset = activeSurvey.assets.find((x) => x.id === assetID)
      if (!asset) {
        return {
          activeBoundary: undefined,
          activeBoundaries: undefined,
        }
      }
      return {
        activeBoundary: asset.boundaries.find((b) => b.id === boundaryID),
        activeBoundaries: asset.boundaries,
      }
    }

    return {
      activeBoundary: activeSurvey.boundaries.find((b) => b.id === boundaryID),
      activeBoundaries: activeSurvey.boundaries,
    }
  }, [activeSurvey, assetID, boundaryID])

  return {
    renderState,
    setRenderState,
    renderType,
    setRenderType,
    renderLabels: renderType === BoundaryRenderType.LinesAndLabels,
    drawIn3D,
    setDrawIn3D: (b: boolean) => {
      localStorage.setItem(drawIn3DKey, b + '')
      setDrawIn3D(b)
    },
    drawIn2D,
    setDrawIn2D: (b: boolean) => {
      localStorage.setItem(drawIn2DKey, b + '')
      setDrawIn2D(b)
    },
    boundaryToEdit,
    setBoundaryToEdit,
    activeBoundary,
    activeBoundaries,
  }
}
