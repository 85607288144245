query Site($input: SiteGetByIDRequestT!) {
  site(input: $input) {
    id
    number
    orgID
    isDemoSite
    name
    address {
      id
      type
      place_type
      relevance
      properties {
        accuracy
      }
      text
      place_name
      center
      geometry {
        type
        coordinates
      }
      address
      context {
        id
        short_code
        wikidata
        text
      }
    }
    surveys {
      id
      startDate
      endDate
      boundaries {
        id
        name
        points {
          latitude
          longitude
        }
        additionalData
        additional {
          sourceInformation
          category
          subcategory
        }
        markers {
          id
          comment
          location {
            longitude
            latitude
          }
          status
          createdAt
          createdBy
          updatedAt
          updatedBy
          approvalStatusChangedAt
          approvalStatusChangedBy
          approvalStatus
        }
      }
      monitoringZones {
        id
        name
        description
        points {
          latitude
          longitude
        }
        targetID
        targetName
        subTargetID
        subTargetName
      }
      issues {
        id
        surveyID
        assetID
        assetTypeID
        assetTypeName
        name
        number
        notes
        priority
        riskType
        issueType
        status
        componentTypeID
        componentTypeName
        componentTypeFullName
        materialTypeID
        materialTypeName
        component {
          id
          name
          condition
          measurement
          componentTypeName
          componentTypeID
          materialTypeID
          materialTypeName
        }
        location {
          latitude
          longitude
          altitude
        }
        imageID
        imageHash
        imageDate
        coordinates {
          x
          y
        }
        repairEstimate
        defectTypeID
        defectType
        defectSubTypeID
        defectSubType
        defectSuggestedRemedy
        defectDescription
        defectCostToRepair
        defectCostPriorityScalar
        isInternal
        internalPoint {
          latitude
          longitude
          altitude
        }
        internalReconstructionID
      }
      assets {
        id
        siteID
        name
        number
        assetType {
          id
          name
        }
        boundaries {
          id
          name
          points {
            latitude
            longitude
          }
          additionalData
          additional {
            sourceInformation
            category
            subcategory
          }
          markers {
            id
            comment
            location {
              longitude
              latitude
            }
            status
            createdAt
            createdBy
            updatedAt
            updatedBy
            approvalStatusChangedAt
            approvalStatusChangedBy
            approvalStatus
          }
        }
        monitoringZones {
          id
          name
          description
          points {
            latitude
            longitude
          }
          targetID
          targetName
          subTargetID
          subTargetName
        }
        issues {
          id
          surveyID
          assetID
          assetTypeID
          assetTypeName
          name
          number
          notes
          priority
          riskType
          issueType
          status
          componentTypeID
          componentTypeName
          componentTypeFullName
          materialTypeID
          materialTypeName
          component {
            id
            name
            condition
            measurement
            componentTypeName
            componentTypeID
            materialTypeID
            materialTypeName
          }
          location {
            latitude
            longitude
            altitude
          }
          imageID
          imageHash
          imageDate
          coordinates {
            x
            y
          }
          repairEstimate
          defectTypeID
          defectType
          defectSubTypeID
          defectSubType
          defectSuggestedRemedy
          defectDescription
          defectCostToRepair
          defectCostPriorityScalar
          isInternal
          internalPoint {
            latitude
            longitude
            altitude
          }
          internalReconstructionID
        }
        issueCounters {
          high
          medium
          low
        }
        issueCosts {
          high
          medium
          low
        }
        components {
          id
          name
          assetID
          componentTypeID
          componentTypeName
          componentTypeFullName
          materialTypeID
          materialTypeName
          costPerUnit
          labourPerHour
          measurement
          condition
          isInternal
          points {
            latitude
            longitude
            altitude
          }
          internalPoints {
            latitude
            longitude
            altitude
          }
          children {
            id
            name
            componentTypeID
            componentTypeName
            componentTypeFullName
            materialTypeID
            materialTypeName
            costPerUnit
            labourPerHour
            measurement
            condition
            isInternal
            points {
              latitude
              longitude
              altitude
            }
            internalPoints {
              latitude
              longitude
              altitude
            }
          }
        }
      }
      reconstructions {
        id
        instantAssessmentID
        cesium3D {
          id
          translationX
          translationY
          translationZ
          rotateX
          rotateY
          rotateZ
          scaleX
          scaleY
          scaleZ
          clippingPlanes {
            normalX
            normalY
            normalZ
            distance
          }
          cesiumIonID
        }
        ortho {
          id
          state
        }
        matterport {
          id
          originX
          originY
          originZ
          translationX
          translationY
          translationZ
          rotateX
          rotateY
          rotateZ
          scaleX
          scaleY
          scaleZ
          clippingPlanes {
            normalX
            normalY
            normalZ
            distance
          }
          photos {
            id
            hash
            index
          }
          panos {
            id
            hashes
            index
            position {
              latitude
              longitude
              altitude
            }
            rotation {
              x
              y
              z
              w
            }
            anchor {
              latitude
              longitude
              altitude
            }
          }
        }
      }
    }
    lidar {
      id
      name
      state
      hash
      dateTaken
      bbox {
        latitude
        longitude
        altitude
      }
      classifications {
        name
        numPoints
      }
      hasRGB
    }
  }
}
