import React from 'react'
import { useUser } from '~/base'
import { classes, handleCurrencyFormatFull, SimpleBar } from '~/components'
import { DefectType } from '~/models'
import { useAppState } from '~/state'
import { IssueItemWithPrevious, PhotoItem } from '../components'

interface IssuesBulkMovingTableProps {
  issues: IssueItemWithPrevious[]
  setSelectedIssue: (i: IssueItemWithPrevious) => void
  selectedIssue: IssueItemWithPrevious
  defectTypes: DefectType[]
  photos: PhotoItem[]
}

export const IssuesBulkMovingTable = (props: IssuesBulkMovingTableProps) => {
  const { timeline } = useAppState()
  const user = useUser()

  return (
    <div className='issues-bulk-moving-table'>
      <div className='issues-bulk-moving-table-title'>Issues Pending Assignment ({props.issues?.length || 0})</div>
      <SimpleBar>
        <table className='table'>
          <thead>
            <tr>
              <th>Asset</th>
              <th>Component</th>
              <th>Defect</th>
              <th className='center'>Status</th>
              <th className='center'>Priority</th>
              <th className='center'>Risk</th>
              <th className='right'>Repair Estimate</th>
            </tr>
          </thead>
          <tbody>
            {props.issues.map((i) => {
              const defectType = props.defectTypes.find((d) => d.id === i.currentIssue.defectTypeID)
              const defectSubType = defectType && defectType.subTypes.find((s) => s.id === i.currentIssue.defectSubTypeID)
              return (
                <tr
                  key={(i.currentIssue.id || '') + i.currentIssue.imageID + i.currentIssue.defectTypeID}
                  onClick={() => props.setSelectedIssue(i)}
                  className={classes({
                    selected: i.currentIssue.id === props.selectedIssue?.currentIssue?.id,
                  })}
                >
                  <td>{timeline.activeSurvey?.assets.find((a) => a.id === i.currentIssue.assetID)?.name || i.currentIssue.assetTypeName}</td>
                  <td>
                    {typeof i.currentIssue.component?.name === 'string' && i.currentIssue.component?.name !== ''
                      ? `${i.currentIssue.component.name} (${i.currentIssue.componentTypeFullName})`
                      : i.currentIssue.componentTypeFullName || '-'}
                  </td>
                  <td>
                    {defectType?.name || '-'}
                    {defectSubType ? ` - ${defectSubType.name}` : ''}
                  </td>
                  <td className='center'>{(i?.currentIssue.status || '').replace(/([A-Z])/g, ' $1').trim()}</td>
                  <td className='center'>{i.currentIssue.priority}</td>
                  <td className='center'>
                    <span
                      className={classes({
                        'issues-card-risk': true,
                        [i?.currentIssue.riskType.toLowerCase()]: true,
                      })}
                    >
                      {i?.currentIssue.riskType}
                    </span>
                  </td>
                  <td className='right'>{handleCurrencyFormatFull(i.currentIssue.repairEstimate, user)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </SimpleBar>
    </div>
  )
}
