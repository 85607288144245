import { useEffect, useState } from 'react'
import { ImagesByDayQuery, ImagesByDayQueryVariables, Photo, SiteQueryImageTimelineItem } from '~/models'

import { useQuery } from '~/components'
import IMAGES_BY_DAY_QUERY from '~/components/film-strip/query-images-by-day.gql'
import { PhotoItem } from './types'

interface IssuesFilmStripProps {
  days: SiteQueryImageTimelineItem[]
  siteID: string
  surveyID: string
}

export const useIssuesFilmStripImages = (props: IssuesFilmStripProps) => {
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [photos, setPhotos] = useState<PhotoItem[]>([])

  const imagesQuery = useQuery<ImagesByDayQuery, ImagesByDayQueryVariables>(IMAGES_BY_DAY_QUERY, {
    variables: {
      input: {
        days: [],
        siteID: '',
        offset: 0,
        surveyID: '',
        interiorOnly: false,
      },
    },
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  function reload(offset: number, startingPhotos: Photo[]) {
    setLoading(offset === 0)
    setError(false)
    return imagesQuery
      .refetch({
        input: {
          days: (props.days || []).map((i) => i.day).filter((value, index, self) => self.indexOf(value) === index),
          siteID: props.siteID,
          offset,
          surveyID: props.surveyID,
          interiorOnly: false,
        },
      })
      .then((res) => {
        setLoading(false)
        const photosToAdd = [...startingPhotos, ...res.data.queryImagesByDay.images]

        if (res.data.queryImagesByDay.haveMore) {
          setTimeout(() => {
            reload(photosToAdd.length, photosToAdd)
          }, 0)
        } else {
          setPhotos(
            photosToAdd
              .sort((a, b) => {
                return a.dateTaken > b.dateTaken ? 1 : -1
              })
              .map((p, i) => {
                return { ...p, index: i }
              })
          )
        }

        return res
      })
      .catch((e) => {
        console.error(e)
        setLoading(false)
        setError(true)
      })
  }

  useEffect(() => {
    if (!Array.isArray(props.days) || props.days.length === 0 || !props.siteID || !props.surveyID) {
      return
    }

    reload(0, [])
  }, [props.days, props.siteID, props.surveyID])

  return {
    loading,
    error,
    photos,
    reload: () => {
      reload(0, [])
    },
  }
}
