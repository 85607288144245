import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { useAppState } from '../../state/use-app-state'
import { SiteQueryAsset, ImagesByDayQuery, ImagesByDayQueryVariables, Photo, SiteQuerySite } from '~/models'
import { useModelToPhotos } from '~/sites/use-model-to-photos'

import CHEVRONS from '../../images/chevrons-right.svg'
import CAMERA_MARKER_ICON_2D from '../../images/red-marker-transparent.png'
import CAMERA_MARKER_ICON_2D_SELECTED from '../../images/camera-marker-selected.png'
import { classes, getImageURL, handleSimpleBarHeight } from '../helpers'
import { visualizeFrustums } from '../visualizations'
import { FilmStripSettingsPanel } from './film-strip-settings-panel'
import { useQuery } from '~/components'
import IMAGES_BY_DAY_QUERY from './query-images-by-day.gql'
import { ActivityIndicator } from '../activity-indicator'
import { ProjectionType, useRefState } from '../../state/use-cesium'
import { ClientPermission, useUser } from '~/base'

const MARKER_COLOR = Cesium.Color.fromCssColorString('#BA160C').withAlpha(0.67)

interface FilmStripProps {
  onHide: () => void
  site?: SiteQuerySite
  asset?: SiteQueryAsset
  photos: MutableRefObject<Photo[]>
  dataLoaded: (photos: Photo[], reload: () => Promise<void>) => void
  addToData: (photos: Photo[]) => void
}

export const FilmStrip = (props: FilmStripProps) => {
  const user = useUser()
  const { imageTagger, map, timeline, view, issues } = useAppState()
  const scrollNodeRef = useRef<HTMLElement>()
  const [selectedPhoto, setSelectedPhoto] = useRefState<Photo>()
  const [loading, setLoading] = useState<boolean>(true)
  const [firstLoad, setFirstLoad] = useState<boolean>(true)
  const [error, setError] = useState<boolean>(false)
  const [imageDays, setImageDays] = useState<string[]>([])
  const [activeImageDays, setActiveImageDays] = useState<string[]>([])
  // const [boundingBox, setBoundingBox] = useState<Cesium.Rectangle | undefined>(map.viewer.camera.computeViewRectangle())
  const pointPrimitives = useRef(new Cesium.PointPrimitiveCollection())
  const frustumPrimitives = useRef(new Cesium.PrimitiveCollection())
  const canListIssues = user.hasPermission(ClientPermission.IssuesList)

  const localStorageShowLocations = localStorage.getItem('asseti-images-show-locations')
  const [showPhotoLocations, setShowPhotoLocationsImpl] = useRefState<boolean>(
    localStorageShowLocations === 'true' || localStorageShowLocations === null
  )
  const [showPhotoDirections, setShowPhotoDirectionsImpl] = useRefState<boolean>(
    localStorage.getItem('asseti-images-show-directions') === 'true'
  )
  const setShowPhotoLocations = (v: boolean) => {
    localStorage.setItem('asseti-images-show-locations', v ? 'true' : 'false')
    setShowPhotoLocationsImpl(v)
  }
  const setShowPhotoDirections = (v: boolean) => {
    localStorage.setItem('asseti-images-show-directions', v ? 'true' : 'false')
    setShowPhotoDirectionsImpl(v)
  }

  const [scroll, setScroll] = useState<{
    height: number
    scroll: number
  }>()

  useEffect(() => {
    imageTagger.setFilmStripOpen(true)
    map.viewer.scene.primitives.add(pointPrimitives.current)
    map.viewer.scene.primitives.add(frustumPrimitives.current)

    const leftClickHandler = new Cesium.ScreenSpaceEventHandler(map.viewer.canvas)

    leftClickHandler.setInputAction((movement: Cesium.ScreenSpaceEventHandler.PositionedEvent) => {
      const windowPos = movement.position
      const picked = map.viewer.scene.pick(windowPos)
      if (!picked) {
        return
      }

      const photoIndex = props.photos.current.findIndex((x) => x.imageID === picked.id)
      if (photoIndex === -1) {
        return
      }

      setSelectedPhoto(props.photos.current[photoIndex])
      setTimeout(() => {
        scrollNodeRef.current.scrollTo({
          top: 206 * Math.max(0, photoIndex - 1),
        })
      }, 10)
    }, Cesium.ScreenSpaceEventType.LEFT_CLICK)

    return () => {
      imageTagger.hide()
      imageTagger.setFilmStripOpen(false)
      map.viewer.scene.primitives.remove(pointPrimitives.current)
      map.viewer.scene.primitives.remove(frustumPrimitives.current)
      pointPrimitives.current = undefined
      frustumPrimitives.current = undefined
    }
  }, [])

  useEffect(() => {
    if (imageTagger.updatingIssue) {
      setSelectedPhoto(imageTagger.photo)
    }
  }, [imageTagger.updatingIssue])

  useEffect(() => {
    if (!imageTagger.photo) {
      return
    }

    const photo = imageTagger.photo

    // let heightOffset = 0
    // if (photo.yaw !== 0 || photo.pitch !== 0 || photo.roll !== 0) {
    //   heightOffset = getHeightOffset()
    // }

    map.viewer.camera.flyTo({
      destination: Cesium.Cartesian3.fromDegrees(photo.longitude, photo.latitude, photo.altitude + getHeightOffset()),
      orientation: Cesium.HeadingPitchRoll.fromDegrees(photo.yaw, photo.pitch, photo.roll),
      duration: 0.25,
    })
  }, [imageTagger.photo])

  // useEffect(() => {
  //   function moveListener() {
  //     const ellipsoid = Cesium.Ellipsoid.WGS84
  //     let rect = map.viewer.camera.computeViewRectangle(ellipsoid)
  //     if (rect == undefined) {
  //       const cl2 = new Cesium.Cartesian2(0, 0)
  //       const leftTop = ellipsoid.cartesianToCartographic(map.viewer.scene.camera.pickEllipsoid(cl2, ellipsoid))
  //       const cr2 = new Cesium.Cartesian2(map.viewer.scene.canvas.width, map.viewer.scene.canvas.height)
  //       const rightDown = ellipsoid.cartesianToCartographic(map.viewer.scene.camera.pickEllipsoid(cr2, ellipsoid))
  //       rect = new Cesium.Rectangle(leftTop.longitude, rightDown.latitude, rightDown.longitude, leftTop.latitude)
  //     }
  //     setBoundingBox(rect)
  //   }

  //   map.viewer.camera.moveEnd.addEventListener(moveListener)
  //   return () => {
  //     map.viewer.camera.moveEnd.removeEventListener(moveListener)
  //   }
  // }, [])

  const imagesQuery = useQuery<ImagesByDayQuery, ImagesByDayQueryVariables>(IMAGES_BY_DAY_QUERY, {
    variables: {
      input: {
        days: [],
        siteID: '',
        offset: 0,
        surveyID: timeline.activeSurvey?.id,
        interiorOnly: false,
      },
    },
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-first',
  })

  function reload(offset: number) {
    if (firstLoad && activeImageDays.length === 0) {
      setFirstLoad(false)
      return
    }
    setLoading(offset === 0)
    setError(false)
    //reset()
    return imagesQuery
      .refetch({
        input: {
          days: activeImageDays,
          siteID: view.siteID,
          offset,
          surveyID: timeline.activeSurvey?.id,
          interiorOnly: false,
        },
      })
      .then((res) => {
        setLoading(false)
        if (offset === 0) {
          const heightOffset = getHeightOffset()
          const photosToAdd = res.data.queryImagesByDay.images
          props.dataLoaded(photosToAdd, () => Promise.resolve())

          if (showPhotoLocations.current && pointPrimitives.current) {
            photosToAdd.forEach((p) => {
              pointPrimitives.current.add({
                position: Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, p.altitude + heightOffset),
                color: MARKER_COLOR,
                // translucencyByDistance: TRANSLUCENCY_BY_DISTANCE,
                pixelSize: p.imageID === imageTagger.photo?.imageID ? 16 : 12,
                id: p.imageID,
              })
            })
          }
        } else {
          const photosToAdd = [...res.data.queryImagesByDay.images]
          const p = [...props.photos.current, ...photosToAdd]
          props.addToData(p)
        }

        if (res.data.queryImagesByDay.haveMore) {
          setTimeout(() => {
            reload(offset + res.data.queryImagesByDay.images.length)
          }, 0)
        }

        return res
      })
      .catch(() => {
        setLoading(false)
        setError(true)
      })
  }

  useEffect(() => {
    const imageDays: { [day: string]: boolean } = {}
    timeline.activeImages.forEach((i) => {
      imageDays[i.day] = true
    })
    setImageDays(Object.keys(imageDays))
    setActiveImageDays(Object.keys(imageDays))
  }, [timeline.activeImages])

  useEffect(() => {
    if (pointPrimitives.current) {
      pointPrimitives.current.removeAll()
    }
    if (frustumPrimitives.current) {
      frustumPrimitives.current.removeAll()
    }
    reload(0)
  }, [activeImageDays])

  useEffect(() => {
    if (!pointPrimitives.current) {
      return
    }

    if (showPhotoLocations.current) {
      const heightOffset = getHeightOffset()

      photos.forEach((p) => {
        pointPrimitives.current.add({
          position: Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude, p.altitude + heightOffset),
          color: MARKER_COLOR,
          // translucencyByDistance: TRANSLUCENCY_BY_DISTANCE,
          pixelSize: p.imageID === imageTagger.photo?.imageID ? 16 : 12,
          id: p.imageID,
        })
      })
    } else {
      pointPrimitives.current.removeAll()
    }

    return () => {
      if (pointPrimitives.current) {
        pointPrimitives.current.removeAll()
      }
    }
  }, [showPhotoLocations.current, imageTagger.photo?.imageID])

  useEffect(() => {
    if (!frustumPrimitives.current) {
      return
    }
    if (showPhotoDirections.current && map.projectionType === ProjectionType.Projection3D) {
      const heightOffset = getHeightOffset()
      const res = visualizeFrustums(
        photos,
        Cesium.Color.fromCssColorString('#BA160C').withAlpha(0.33),
        photos.length,
        heightOffset,
        imageTagger.photo?.imageID
      )
      res.primitives.map((f) => {
        frustumPrimitives.current.add(f)
      })

      res.entities.map((f) => {
        map.viewer.entities.add(f)
      })
    } else {
      frustumPrimitives.current.removeAll()
    }
  }, [showPhotoDirections.current, map.projectionType, imageTagger.photo?.imageID, props.photos.current])

  const photos = props.photos.current || []

  const { images: filteredPhotos, reset } = useModelToPhotos(!imageTagger.addingIssue && !imageTagger.updatingIssue)
  let photosToUse = filteredPhotos
    ? filteredPhotos.map((i) => {
        return {
          ...i,
          dateTaken: 0,
          id: i.imageID,
        }
      })
    : photos

  const haveFilter = filteredPhotos ? true : false

  if (!haveFilter) {
    // photosToUse = photosToUse.filter((p) => {
    //   if (!boundingBox) {
    //     return true
    //   }
    //   return Cesium.Rectangle.contains(
    //     boundingBox,
    //     Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, p.altitude)
    //   )
    // })
  }

  function getHeightOffset() {
    if (!timeline.activeSurvey || timeline.activeSurvey.reconstructions.length === 0) {
      return 0
    }
    const heightOffset = timeline.activeSurvey.reconstructions[0].cesium3D?.translationZ || 0
    return heightOffset
  }

  useEffect(() => {
    if (!selectedPhoto.current) {
      imageTagger.hide()
      return
    }

    imageTagger.setPhoto(selectedPhoto.current)

    const marker = map.getMarkerById(selectedPhoto.current.imageID)
    if (marker) {
      marker.billboard.image = CAMERA_MARKER_ICON_2D_SELECTED
    }

    return () => {
      if (marker) {
        marker.billboard.image = CAMERA_MARKER_ICON_2D
      }
    }
  }, [selectedPhoto.current])

  // useEffect(() => {
  //   setTimeout(() => {
  //     scrollNodeRef.current.scrollTo({
  //       top: 0,
  //     })
  //   }, 10)
  // }, [filteredPhotos])

  let inViewStartIndex = scroll ? Math.floor(scroll.scroll / 206) - 2 : 0

  if (inViewStartIndex < 0) {
    inViewStartIndex = 0
  }

  let inViewEndIndex = inViewStartIndex + 20

  if (inViewEndIndex > photosToUse.length) {
    inViewEndIndex = photosToUse.length
  }

  const numPrefix = inViewStartIndex
  const numSuffix = photosToUse.length - inViewEndIndex

  return (
    <div
      className={classes({
        'film-strip': true,
        fullscreen: imageTagger.fullscreen,
        dimmed: imageTagger.addingIssue || !!imageTagger.updatingIssue,
      })}
    >
      <div className='film-strip-actions'>
        <div className='film-strip-filter'>
          {haveFilter && (
            <button className='btn btn-secondary' onClick={reset}>
              Clear Filter
            </button>
          )}
        </div>
        {!imageTagger.fullscreen && (
          <div className='film-strip-hide'>
            <img src={CHEVRONS} title='Hide' onClick={() => props.onHide()} />
          </div>
        )}
      </div>
      <div>
        <SimpleBar
          style={{ maxHeight: handleSimpleBarHeight(50) }}
          scrollableNodeProps={{
            ref: scrollNodeRef,
            onScroll: (e: any) => {
              console.log('onScroll', e)
              setScroll({
                height: e.target.clientHeight,
                scroll: e.target.scrollTop,
              })
            },
          }}
        >
          {error && (
            <div className='film-strip-list'>
              <div className='film-strip-list-error'>
                <p className='body2 mb-3'>
                  An error occurred
                  <br />
                  while loading images.
                </p>
                <button className='btn btn-link' onClick={() => reload(0)}>
                  Click to retry
                </button>
              </div>
            </div>
          )}
          {loading && (
            <div className='film-strip-list'>
              <ActivityIndicator />
            </div>
          )}
          {!loading && (
            <div className='film-strip-list'>
              {numPrefix > 0 && <span style={{ height: 206 * numPrefix + 'px' }}>&nbsp;</span>}
              {photosToUse.length > 0 &&
                photosToUse.slice(inViewStartIndex, inViewEndIndex).map((d, i) => (
                  <div
                    key={d.imageID}
                    className={classes({
                      'film-strip-list-item': true,
                      selected: d.imageID === selectedPhoto.current?.imageID,
                    })}
                    onClick={() => {
                      setSelectedPhoto(d)
                    }}
                  >
                    <img src={getImageURL(d, props.site.orgID, i + inViewStartIndex)} />
                    <div>
                      <div className='film-strip-list-item-label'>
                        {i + inViewStartIndex + 1} to {photosToUse.length} of {photos.length}
                      </div>
                      {canListIssues && (
                        <div className='film-strip-list-item-chip'>
                          {issues.issues.filter((x) => x.imageID === d.imageID).length}
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              {photos.length === 0 && !error && <div className='film-strip-empty-list'>No photos added yet.</div>}
              {photosToUse.length === 0 && !error && (
                <div className='film-strip-empty-list'>
                  <p>No visible photos.</p>
                  <div>
                    <ul>
                      <li>Clear the filter (if applicable)</li>
                      <li>Zooming out to being photos into view; or</li>
                      <li>Change the active date on the timeline</li>
                    </ul>
                  </div>
                </div>
              )}
              {numSuffix > 0 && <span style={{ height: 206 * numSuffix + 'px' }}>&nbsp;</span>}
            </div>
          )}
        </SimpleBar>
      </div>
      <FilmStripSettingsPanel
        setShowPhotoDirections={setShowPhotoDirections}
        setShowPhotoLocations={setShowPhotoLocations}
        showPhotoDirections={showPhotoDirections.current}
        showPhotoLocations={showPhotoLocations.current}
        imageDays={imageDays}
        activeImageDays={activeImageDays}
        setActiveImageDays={setActiveImageDays}
      />
    </div>
  )
}
