import { formatArea, formatDistance, formatVolume } from '~/components/measure'
import { AnnotationDtoT, AnnotationType } from '~/models'

export function formatAnnotations(m: AnnotationDtoT): string {
  if (m.type === AnnotationType.Distance) {
    return formatDistance(m.measurements[0])
  } else if (m.type === AnnotationType.Area) {
    return formatArea(m.measurements[0])
  } else if (m.type === AnnotationType.Volume) {
    return formatVolume(m.measurements[0])
  } else if (m.type === AnnotationType.GroundEavesRidge) {
    return formatDistance(m.measurements[0]) + ', ' + formatDistance(m.measurements[1])
  } else if (m.type === AnnotationType.LengthWidth) {
    return formatDistance(m.measurements[0]) + 'x' + formatDistance(m.measurements[1])
  }

  return ''
}

export function formatAnnotationsName(m: AnnotationDtoT): string {
  let name =
    m.name ||
    (m.type === AnnotationType.GroundEavesRidge
      ? 'Ground, eaves and ridge'
      : m.type === AnnotationType.LengthWidth
      ? 'Length & Width'
      : m.type)

  if (m.type === AnnotationType.Distance) {
    name += ' ' + formatDistance(m.measurements[0])
  } else if (m.type === AnnotationType.Area) {
    name += ' ' + formatArea(m.measurements[0])
  } else if (m.type === AnnotationType.Volume) {
    name += ' ' + formatVolume(m.measurements[0])
  } else if (m.type === AnnotationType.GroundEavesRidge) {
    name += ' ' + formatDistance(m.measurements[0]) + ', ' + formatDistance(m.measurements[1])
  } else if (m.type === AnnotationType.LengthWidth) {
    name += ' ' + formatDistance(m.measurements[0]) + 'x' + formatDistance(m.measurements[1])
  }

  return name
}

export function formatAnnotationType(t: AnnotationType) {
  return t === AnnotationType.GroundEavesRidge
    ? 'G,E & R'
    : t === AnnotationType.LengthWidth
    ? 'L & W'
    : t
}
