import React, { useMemo, useState } from 'react'
import { AnnotationDtoT } from '~/models'
import { ClientPermission, useUser } from '~/base'
import { DrawerPopoutListCard, DrawerPopoutList, SearchBox } from '~/components'
import { formatAnnotations, formatAnnotationType } from './annotations-utils'
import { useAppState } from '~/state'
import dayjs from 'dayjs'

export const AnnotationFullList = () => {
  const { timeline, view, site, entityContext, annotations } = useAppState()
  const user = useUser()
  const [search, setSearch] = useState<string | undefined>()
  const [removeAnnotation, setRemoveAnnotation] = useState<AnnotationDtoT>()

  const annotationsToUse = useMemo(() => {
    const lowerSearch = (search || '').toLocaleLowerCase()
    const sorted = [...annotations.annotations]
      .filter((a) => {
        if (lowerSearch === '') {
          return true
        }

        const cLowerName = (a.name || '').toLocaleLowerCase()
        const cLowerType = a.type.toLocaleLowerCase()
        let cMatches = cLowerName.indexOf(lowerSearch) !== -1 || cLowerType.indexOf(lowerSearch) !== -1

        return cMatches
      })
      .sort(sortAnnotations)
    return sorted
  }, [annotations.annotations, search])

  const generateCSV = () => {
    const lines = []
    for (const m of annotationsToUse) {
      const asset = timeline.activeSurvey.assets.find((s) => s.id === view.assetID)
      lines.push(
        [
          m.id,
          site.site.name,
          site.site.number,
          site.site.id,
          asset?.name || '-',
          asset?.number || '-',
          asset?.id || '-',
          '"' + formatAnnotationType(m.type) + '"',
          m.measurements[0],
          m.measurements[1] || '-',
        ].join(',')
      )
    }

    const csv =
      'Annotation ID,Site,Site Number,Site ID,Asset,Asset Number,Asset ID,Type,Annotation 1,Annotation 2\n' +
      lines.join('\n')

    const link = document.createElement('a')
    link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv))
    link.setAttribute('download', `${dayjs().format('YYYY-MM-DD')}-${user.org.name}-${site.site.name}-annotations.csv`)
    document.body.appendChild(link)
    document.getElementById('download-csv').click()
    document.body.removeChild(link)
  }

  const Controls = useMemo(() => {
    return (
      <>
        <SearchBox search={search} setSearch={setSearch} />
        <div className='drawer-popout-controls-icon' onClick={generateCSV} title='Download CSV'>
          <i className='material-icons'>get_app</i>
        </div>
      </>
    )
  }, [search])

  return (
    <DrawerPopoutList
      width={360}
      title='Annotations'
      total={annotations.annotations.length}
      count={annotationsToUse.length}
      emptyText='There are no annotations to display.'
      controls={Controls}
    >
      {annotationsToUse.map((annotation) => {
        return (
          <AnnotationCard
            active={
              annotations.selectedAnnotation?.id === annotation.id ||
              annotations.annotationToUpdate?.id === annotation.id ||
              removeAnnotation?.id === annotation.id
            }
            key={annotation.id}
            annotation={annotation}
            setAnnotationToDelete={setRemoveAnnotation}
            selected={annotations.selectedAnnotation?.id === annotation.id}
            setSelected={entityContext.set}
          />
        )
      })}
    </DrawerPopoutList>
  )
}

function sortAnnotations(a: AnnotationDtoT, b: AnnotationDtoT) {
  return a.updatedAt > b.updatedAt ? -1 : 1
}

interface AnnotationCardProps {
  active: boolean
  annotation: AnnotationDtoT
  setAnnotationToDelete: (toDelete: AnnotationDtoT) => void
  selected?: boolean
  setSelected?: (c: AnnotationDtoT) => void
}

function AnnotationCard(props: AnnotationCardProps) {
  const { view, site, annotations } = useAppState()
  const user = useUser()
  const isDemoSite = site.site?.isDemoSite
  const canEdit = !isDemoSite && user.hasPermission(ClientPermission.AnnotationsUpdate)
  const canRemove = !isDemoSite && user.hasPermission(ClientPermission.AnnotationsRemove)
  const annotation = props.annotation

  return (
    <DrawerPopoutListCard
      key={annotation.id + view.surveyID + view.assetID}
      className='annotations-card'
      active={props.active}
      onClick={(e) => {
        if (e.defaultPrevented) {
          return
        }
        props.setSelected(annotation)
      }}
    >
      <div className='annotations-card-upper'>{annotation.name || 'Unnamed'} </div>
      <div className='annotations-card-middle'>{formatAnnotationType(annotation.type)}</div>
      <div className='annotations-card-middle'>{formatAnnotations(annotation)}</div>
      <div className='annotations-card-lower'>
        {canEdit && (
          <i
            title='Update'
            className='material-icons'
            onClick={(e) => {
              e.preventDefault()
              annotations.setAnnotationToUpdate(annotation)
            }}
          >
            edit
          </i>
        )}
        {canRemove && (
          <i
            title='Remove'
            className='material-icons'
            onClick={(e) => {
              e.preventDefault()
              if (props.setAnnotationToDelete) {
                props.setAnnotationToDelete(annotation)
              }
            }}
          >
            delete
          </i>
        )}
      </div>
    </DrawerPopoutListCard>
  )
}
