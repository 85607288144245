import { useMutation, getTracking } from '~/components'
import React, { useState } from 'react'
import { ClientPermission } from '~/base'
import { SetOrgPolicyMutation, SetOrgPolicyMutationVariables } from '~/models'
import SET_ORG_ACCESS from './mutation-set-policy.gql'

interface CheckboxProps {
  policy: string[]
  permission: ClientPermission
  setPolicy: (policy: string[]) => void
}

export const Checkbox = (props: CheckboxProps) => {
  const arrayIndex = Math.floor(props.permission / 64)
  const bitIndex = BigInt(1) << BigInt(props.permission % 64)
  const policy = BigInt(props.policy[arrayIndex])
  const checked = (policy & bitIndex) !== BigInt(0)

  return (
    <td
      onClick={() => {
        const newBlock = policy ^ bitIndex
        const newPolicy = props.policy.map((p) => p + '')
        newPolicy[arrayIndex] = newBlock.toString()
        props.setPolicy(newPolicy)
      }}
    >
      <input type='checkbox' checked={checked} />
    </td>
  )
}

interface AdvancedAccessTableFullProps {
  guestPolicy: string[]
  setGuestPolicy: (v: string[]) => void
  memberPolicy: string[]
  setMemberPolicy: (v: string[]) => void
  adminPolicy: string[]
  setAdminPolicy: (v: string[]) => void
  canSeeMatterport: boolean
  canSeeCesium: boolean
  canSeeDeflections: boolean
}

export const AdvancedAccessTableFull = (props: AdvancedAccessTableFullProps) => {
  const [executeSetPolicy] = useMutation<SetOrgPolicyMutation, SetOrgPolicyMutationVariables>(SET_ORG_ACCESS)
  const [saving, setSaving] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)
  const { guestPolicy, setGuestPolicy, memberPolicy, setMemberPolicy, adminPolicy, setAdminPolicy } = props

  return (
    <>
      <table className='advanced-access-table'>
        <thead>
          <tr>
            <th>Permission</th>
            <th>Guest</th>
            <th>Member</th>
            <th>Admin</th>
          </tr>
        </thead>
        <thead>
          <tr>
            <th colSpan={4}>Sites</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Create</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.SitesCreate} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.SitesCreate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Update</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.SitesUpdate} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.SitesUpdate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Remove</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.SitesRemove} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.SitesRemove} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Site Groups</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Manage</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.ManageSiteGroups}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.ManageSiteGroups} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Assets</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Create</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.AssetsCreate} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.AssetsCreate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Update</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.AssetsUpdate} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.AssetsUpdate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Remove</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.AssetsRemove} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.AssetsRemove} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Boundaries</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Create</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.BoundariesCreate}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.BoundariesCreate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Update</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.BoundariesUpdate}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.BoundariesUpdate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Remove</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.BoundariesRemove}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.BoundariesRemove} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Monitoring Zones</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Create</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.MonitoringZonesCreate}
              setPolicy={setMemberPolicy}
            />
            <Checkbox
              policy={adminPolicy}
              permission={ClientPermission.MonitoringZonesCreate}
              setPolicy={setAdminPolicy}
            />
          </tr>
          <tr>
            <td>Update</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.MonitoringZonesUpdate}
              setPolicy={setMemberPolicy}
            />
            <Checkbox
              policy={adminPolicy}
              permission={ClientPermission.MonitoringZonesUpdate}
              setPolicy={setAdminPolicy}
            />
          </tr>
          <tr>
            <td>Remove</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.MonitoringZonesRemove}
              setPolicy={setMemberPolicy}
            />
            <Checkbox
              policy={adminPolicy}
              permission={ClientPermission.MonitoringZonesRemove}
              setPolicy={setAdminPolicy}
            />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Data</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Upload</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.DataUpload} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.DataUpload} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Dashboards</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>View</td>
            <Checkbox policy={guestPolicy} permission={ClientPermission.DashboardsList} setPolicy={setGuestPolicy} />
            <Checkbox policy={memberPolicy} permission={ClientPermission.DashboardsList} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.DashboardsList} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Create</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.DashboardsCreate}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.DashboardsCreate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Update</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.DashboardsUpdate}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.DashboardsUpdate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Remove</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.DashboardsRemove}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.DashboardsRemove} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Recent Activity</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>View</td>
            <Checkbox policy={guestPolicy} permission={ClientPermission.ActivityList} setPolicy={setGuestPolicy} />
            <Checkbox policy={memberPolicy} permission={ClientPermission.ActivityList} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.ActivityList} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Issues</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>View</td>
            <Checkbox policy={guestPolicy} permission={ClientPermission.IssuesList} setPolicy={setGuestPolicy} />
            <Checkbox policy={memberPolicy} permission={ClientPermission.IssuesList} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.IssuesList} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Create</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.IssuesCreate} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.IssuesCreate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Update</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.IssuesUpdate} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.IssuesUpdate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Remove</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.IssuesRemove} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.IssuesRemove} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Components</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>View</td>
            <Checkbox policy={guestPolicy} permission={ClientPermission.ComponentsRead} setPolicy={setGuestPolicy} />
            <Checkbox policy={memberPolicy} permission={ClientPermission.ComponentsRead} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.ComponentsRead} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Create</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.ComponentsCreate}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.ComponentsCreate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Update</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.ComponentsUpdate}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.ComponentsUpdate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Remove</td>
            <td></td>
            <Checkbox
              policy={memberPolicy}
              permission={ClientPermission.ComponentsRemove}
              setPolicy={setMemberPolicy}
            />
            <Checkbox policy={adminPolicy} permission={ClientPermission.ComponentsRemove} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Team</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>View</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.TeamList} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.TeamList} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Invite</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.TeamInvite} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.TeamInvite} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Update</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.TeamUpdate} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.TeamUpdate} setPolicy={setAdminPolicy} />
          </tr>
          <tr>
            <td>Remove</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.TeamRemove} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.TeamRemove} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        <thead>
          <tr>
            <th colSpan={4}>Workspace &amp; Advanced Access</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Manage</td>
            <td></td>
            <Checkbox policy={memberPolicy} permission={ClientPermission.ManageOrg} setPolicy={setMemberPolicy} />
            <Checkbox policy={adminPolicy} permission={ClientPermission.ManageOrg} setPolicy={setAdminPolicy} />
          </tr>
        </tbody>
        {props.canSeeMatterport && (
          <>
            <thead>
              <tr>
                <th colSpan={4}>Matterport Integration</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Authenticate</td>
                <td></td>
                <Checkbox
                  policy={memberPolicy}
                  permission={ClientPermission.MatterportAuth}
                  setPolicy={setMemberPolicy}
                />
                <Checkbox
                  policy={adminPolicy}
                  permission={ClientPermission.MatterportCreate}
                  setPolicy={setAdminPolicy}
                />
              </tr>
              <tr>
                <td>Import Spaces</td>
                <td></td>
                <Checkbox
                  policy={memberPolicy}
                  permission={ClientPermission.MatterportAuth}
                  setPolicy={setMemberPolicy}
                />
                <Checkbox
                  policy={adminPolicy}
                  permission={ClientPermission.MatterportCreate}
                  setPolicy={setAdminPolicy}
                />
              </tr>
            </tbody>
          </>
        )}
        {props.canSeeCesium && (
          <>
            <thead>
              <tr>
                <th colSpan={4}>Cesium Integration</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>Authenticate</td>
                <td></td>
                <Checkbox policy={memberPolicy} permission={ClientPermission.CesiumAuth} setPolicy={setMemberPolicy} />
                <Checkbox policy={adminPolicy} permission={ClientPermission.CesiumCreate} setPolicy={setAdminPolicy} />
              </tr>
              <tr>
                <td>Import Assets</td>
                <td></td>
                <Checkbox policy={memberPolicy} permission={ClientPermission.CesiumAuth} setPolicy={setMemberPolicy} />
                <Checkbox policy={adminPolicy} permission={ClientPermission.CesiumCreate} setPolicy={setAdminPolicy} />
              </tr>
            </tbody>
          </>
        )}
        {props.canSeeDeflections && (
          <>
            <thead>
              <tr>
                <th colSpan={4}>Analysis</th>
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>View</td>
                <td></td>
                <Checkbox
                  policy={memberPolicy}
                  permission={ClientPermission.AnalysisRead}
                  setPolicy={setMemberPolicy}
                />
                <Checkbox policy={adminPolicy} permission={ClientPermission.AnalysisRead} setPolicy={setAdminPolicy} />
              </tr>
              <tr>
                <td>Create</td>
                <td></td>
                <Checkbox
                  policy={memberPolicy}
                  permission={ClientPermission.AnalysisCreate}
                  setPolicy={setMemberPolicy}
                />
                <Checkbox
                  policy={adminPolicy}
                  permission={ClientPermission.AnalysisCreate}
                  setPolicy={setAdminPolicy}
                />
              </tr>
              <tr>
                <td>Update</td>
                <td></td>
                <Checkbox
                  policy={memberPolicy}
                  permission={ClientPermission.AnalysisUpdate}
                  setPolicy={setMemberPolicy}
                />
                <Checkbox
                  policy={adminPolicy}
                  permission={ClientPermission.AnalysisUpdate}
                  setPolicy={setAdminPolicy}
                />
              </tr>
              <tr>
                <td>Remove</td>
                <td></td>
                <Checkbox
                  policy={memberPolicy}
                  permission={ClientPermission.AnalysisRemove}
                  setPolicy={setMemberPolicy}
                />
                <Checkbox
                  policy={adminPolicy}
                  permission={ClientPermission.AnalysisRemove}
                  setPolicy={setAdminPolicy}
                />
              </tr>
            </tbody>
          </>
        )}
      </table>

      <br />
      <br />
      <button
        className='settings-tab-content-btn btn'
        type='button'
        disabled={saving || saved}
        onClick={() => {
          getTracking().event({
            category: 'Button',
            action: `User clicked org advanced access save button`,
            label: 'Advanced Access Save',
          })

          setSaving(true)
          executeSetPolicy({
            variables: {
              input: {
                guestPolicy: guestPolicy,
                memberPolicy: memberPolicy,
                adminPolicy: adminPolicy,
              },
            },
          })
            .then((res) => {
              setGuestPolicy(res.data.organizationSetPolicy.guestPolicy)
              setMemberPolicy(res.data.organizationSetPolicy.memberPolicy)
              setAdminPolicy(res.data.organizationSetPolicy.adminPolicy)
              setSaved(true)
              setTimeout(() => {
                setSaved(false)
              }, 3000)
            })
            .finally(() => {
              setSaving(false)
            })
        }}
      >
        {saving ? 'Saving...' : saved ? 'Saved' : 'Save'}
      </button>
    </>
  )
}
