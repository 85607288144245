import React, { useRef, useState } from 'react'
import SimpleBar from 'simplebar-react'
import { classes } from '../../helpers/classes'
import { FloatingInput } from '../floating-input/floating-input'

export interface SelectItem {
  id: string
}

export interface SelectOption<T extends SelectItem> {
  name: string
  id: string
  value: T
  items: SelectOption<T>[]
}

export interface SelectProps<T extends SelectItem> {
  options: Array<SelectOption<T>>
  selectedValue?: T
  onChange: (selectedValue: T) => void
  id: string
  label?: string
  placeholder?: string
  helperText?: string
  small?: boolean
  tiny?: boolean
  canSelectGroup?: boolean
  canSelectNone?: boolean
  disabled?: boolean
}

export function Select<T extends SelectItem = SelectItem>(props: SelectProps<T>) {
  const [focused, setFocused] = useState<boolean>(false)
  const [searchValue, setSearchValue] = useState<string>('')
  const [highlightIndex, setHighlightIndex] = useState<number>(0)
  const containerRef = useRef<HTMLDivElement>()
  const inputRef = useRef<HTMLInputElement>()

  const lowerCaseSearchValue = searchValue.toLocaleLowerCase()

  // const setFocused = (b: boolean) => setFocused2(true)
  const none = {
    name: 'None',
    id: '',
    value: '',
    items: [],
  } as any as SelectOption<T>

  const options: SelectOption<T>[] = props.canSelectNone ? [none, ...props.options] : props.options

  const setSelected = (selected: SelectOption<T>) => {
    props.onChange(selected.value)
    setFocused(false)
  }

  let valueToUse = searchValue
  if (!focused && props.selectedValue) {
    for (const group of options) {
      if (group.id === props.selectedValue.id) {
        valueToUse = group.name
        break
      }

      let haveValueToUse = false
      if (group.items.length > 0) {
        for (const item of group.items) {
          if (item.id === props.selectedValue.id) {
            valueToUse = group.name + ' - ' + item.name
            haveValueToUse = true
            break
          }

          if (item.items.length > 0) {
            for (const i of item.items) {
              if (i.id === props.selectedValue.id) {
                valueToUse = group.name + ' - ' + item.name + ' - ' + i.name
                haveValueToUse = true
                break
              }
            }
          }
        }
      }

      if (haveValueToUse) {
        break
      }
    }
  }

  const close = () => {
    setFocused(false)
    setSearchValue('')
    setHighlightIndex(0)
    if (inputRef.current) {
      inputRef.current.blur()
    }
  }

  let renderIndex = 0

  return (
    <div
      className={classes({
        'asseti-select': true,
        'small-select': props.small,
        'tiny': props.tiny,
      })}
      onFocus={() => {
        if (props.disabled) {
          return
        }
        setFocused(true)
        inputRef.current.focus()
      }}
      ref={containerRef}
    >
      <FloatingInput
        disabled={props.disabled}
        label={props.label}
        // placeholder={props.placeholder}
        id={props.id}
        value={valueToUse}
        inputRef={inputRef}
        onChange={(e) => {
          setSearchValue(e.target.value || '')
        }}
        onFocus={() => {
          if (props.disabled) {
            return
          }
          setFocused(true)
          inputRef.current.focus()
        }}
        onLabelFocus={() => {
          if (props.disabled) {
            return
          }
          setFocused(true)
          inputRef.current.focus()
        }}
        onBlur={() => {
          setTimeout(() => {
            close()
          }, 150)
        }}
        onKeyDown={(e) => {
          if (e.key === 'ArrowDown') {
            setHighlightIndex(Math.min(highlightIndex + 1, renderIndex - 1))
          } else if (e.key === 'ArrowUp') {
            setHighlightIndex(Math.max(highlightIndex - 1, 0))
          } else if (e.key === 'Enter') {
            const elements = document.getElementsByClassName('asseti-select-highlighted')
            if (elements.length > 0) {
              const div = elements[0] as HTMLDivElement
              div.click()
              close()
            }
          } else if (e.key === 'Escape') {
            close()
          }
        }}
        helperText={props.helperText}
      />
      {focused && (
        <div className='asseti-select-options-container'>
          <SimpleBar style={{ maxHeight: '310px' }}>
            {options
              .filter((o) => {
                // Filter options.
                if (lowerCaseSearchValue !== '') {
                  const matchesSearch = o.name.toLocaleLowerCase().includes(lowerCaseSearchValue)
                  if (!matchesSearch) {
                    // Search sub items.
                    if (o.items.length > 0) {
                      for (const item of o.items) {
                        if (item.name.toLocaleLowerCase().includes(lowerCaseSearchValue)) {
                          return true
                        }

                        // Search sub sub items.
                        if (item.items.length > 0) {
                          for (const i of item.items) {
                            if (i.name.toLocaleLowerCase().includes(lowerCaseSearchValue)) {
                              return true
                            }

                            if (i.items.length > 0) {
                              for (const i2 of i.items) {
                                if (i2.name.toLocaleLowerCase().includes(lowerCaseSearchValue)) {
                                  return true
                                }
                              }
                            }
                          }
                        }
                      }
                    }

                    if (!matchesSearch) {
                      return false
                    }
                  }
                }

                return true
              })
              .map((o) => {
                if (o.items.length > 0) {
                  const matchingItems = o.items.filter((item) => {
                    if (lowerCaseSearchValue !== '') {
                      if (!item.name.toLocaleLowerCase().includes(lowerCaseSearchValue)) {
                        return false
                      }
                    }
                    return true
                  })

                  if (props.canSelectGroup) {
                    renderIndex++
                  }

                  return (
                    <>
                      <div
                        key={o.id}
                        className={classes({
                          'asseti-select-options-group': true,
                          'asseti-select-highlighted': renderIndex - 1 === highlightIndex,
                          selectable: props.canSelectGroup,
                        })}
                        onClick={() => {
                          if (props.canSelectGroup) {
                            setSelected(o)
                          }
                        }}
                      >
                        {o.name}
                      </div>
                      {(matchingItems.length === 0 ? o.items : matchingItems)
                        .sort((a, b) => (a.name > b.name ? 1 : -1))
                        .map((item) => {
                          if (item.items.length > 0) {
                            const matchingSubItems = item.items.filter((item) => {
                              if (lowerCaseSearchValue !== '') {
                                if (!item.name.toLocaleLowerCase().includes(lowerCaseSearchValue)) {
                                  return false
                                }
                              }
                              return true
                            })

                            if (props.canSelectGroup) {
                              renderIndex++
                            }

                            return (
                              <>
                                <div
                                  key={item.id + item.name}
                                  className={classes({
                                    'asseti-select-options-sub-group': true,
                                    'asseti-select-highlighted': renderIndex - 1 === highlightIndex,
                                    selectable: props.canSelectGroup,
                                  })}
                                  onClick={() => {
                                    if (props.canSelectGroup) {
                                      setSelected(item)
                                    }
                                  }}
                                >
                                  <i className='material-icons sub-dir'>subdirectory_arrow_right</i> {item.name}
                                </div>

                                {matchingSubItems
                                  .sort((a, b) => (a.name > b.name ? 1 : -1))
                                  .map((subSubItem) => {
                                    renderIndex++
                                    return (
                                      <div
                                        key={subSubItem.id + subSubItem.name}
                                        className={classes({
                                          'asseti-select-options-sub-group-item': true,
                                          selected: props.selectedValue && props.selectedValue.id === subSubItem.id,
                                          'asseti-select-highlighted': renderIndex - 1 === highlightIndex,
                                        })}
                                        onClick={() => {
                                          setSelected(subSubItem)
                                        }}
                                      >
                                        <i className='material-icons sub-dir'>subdirectory_arrow_right</i>{' '}
                                        {subSubItem.name}
                                      </div>
                                    )
                                  })}
                              </>
                            )
                          }

                          renderIndex++
                          return (
                            <div
                              key={item.id + item.name}
                              className={classes({
                                'asseti-select-options-group-item': true,
                                selected: props.selectedValue && props.selectedValue.id === item.id,
                                'asseti-select-highlighted': renderIndex - 1 === highlightIndex,
                              })}
                              onClick={() => {
                                setSelected(item)
                              }}
                            >
                              <i className='material-icons sub-dir'>subdirectory_arrow_right</i> {item.name}
                            </div>
                          )
                        })}
                    </>
                  )
                }

                renderIndex++

                return (
                  <div
                    key={o.id}
                    className={classes({
                      'asseti-select-options-item': true,
                      selected: props.selectedValue && props.selectedValue.id === o.id,
                      'asseti-select-highlighted': renderIndex - 1 === highlightIndex,
                    })}
                    onClick={() => {
                      setSelected(o)
                    }}
                  >
                    {o.name}
                  </div>
                )
              })}
          </SimpleBar>
        </div>
      )}
    </div>
  )
}
