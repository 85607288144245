import React, { useEffect, useState } from 'react'
import { formatBytes, shortDate, useQuery } from '~/components'
import { ActivityIndicator, SimpleBar } from '~/components'
import { AuthStatusQuery, CesiumAssetsQuery } from '~/models'
import { CesiumSetTokenModal } from './cesium-set-token-modal'
import CESIUM_ASSETS_QUERY from './query-cesium-assets.gql'
import AUTH_STATUS_QUERY from '~/auth/query-auth-status.gql'
import { useUser } from '~/base'
import { CesiumProcessModal } from './cesium-process-modal'

export const CesiumIntegration = () => {
  const user = useUser()
  const query = useQuery<CesiumAssetsQuery>(CESIUM_ASSETS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-and-network',
  })
  const authStatusQuery = useQuery<AuthStatusQuery>(AUTH_STATUS_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'network-only',
  })
  const [assets, setAssets] = useState<CesiumAssetsQuery['cesiumAssets']>([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | undefined>()
  const [process, setProcess] = useState<CesiumAssetsQuery['cesiumAssets'][0]>()
  const [showSetToken, setShowSetToken] = useState(false)

  useEffect(() => {
    if (!user.org.hasCesiumAuth) {
      return
    }

    setLoading(true)
    query
      .refetch()
      .then((res) => {
        setAssets([...res.data.cesiumAssets].sort((a, b) => (parseInt(a.id, 10) > parseInt(b.id, 10) ? -1 : 1)))
      })
      .catch((e) => {
        setError(JSON.stringify(e))
      })
      .finally(() => {
        setLoading(false)
      })
  }, [user.org.hasCesiumAuth])

  return (
    <>
      {user.org.hasCesiumAuth && loading && (
        <div className='profile'>
          <ActivityIndicator />
        </div>
      )}
      {user.org.hasCesiumAuth && !error && !loading && (
        <SimpleBar>
          <div className='matterport-space-container'>
            <h6>Cesium Ion Assets</h6>
            <table className='table cesium-integration-table'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Name</th>
                  <th>Added</th>
                  <th>Size</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {assets.map((a) => {
                  return (
                    <tr key={a.id}>
                      <td>{a.id}</td>
                      <td>{a.name}</td>
                      <td>{shortDate(a.date)}</td>
                      <td>{formatBytes(a.bytes)}</td>
                      <td>
                        <div className='cesium-integration-table-button btn btn-sm btn-secondary'  onClick={() => setProcess(a)}>
                          Import
                        </div>
                      </td>
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
        </SimpleBar>
      )}
      {!loading && (
        <div className='profile'>
          {error && <div className='error'>{error + ''}</div>}
          <br />
          <br />
          <button
            className='profile-form-btn btn'
            onClick={() => {
              setShowSetToken(true)
            }}
          >
            Set Cesium Ion Token
          </button>
        </div>
      )}
      {showSetToken && (
        <CesiumSetTokenModal
          onComplete={() => {
            return authStatusQuery
              .refetch()
              .then((res) => {
                user.setSignIn(res.data.authStatus)
              })
              .catch(() => {
                window.location.pathname = '/signed-out'
              })
          }}
          onCancel={() => setShowSetToken(false)}
        />
      )}
      {process && (
        <CesiumProcessModal
          model={process}
          onCancel={() => setProcess(undefined)}
          onComplete={() => {
            setProcess(undefined)
            return Promise.resolve()
          }}
        />
      )}
    </>
  )
}
