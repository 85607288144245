query organizationFull {
  organizationFull {
    users {
      user {
        id
        firstName
        lastName
        email
        emailConfirmedAt
      }
      role
      policy
      whitelistedSiteIDs
      whitelistedGroupIDs
    }
    invites {
      id
      firstName
      lastName
      email
      role
      status
      createdAt
    }
    sites {
      id
      name
    }
  }
}
