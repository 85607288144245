import { useEffect } from 'react'
import { useAppState, OrthotileLayer, ProjectionType, TileLayer3D, Marker, MarkerIconType } from '~/state'
import { ReconstructionState, SiteQuerySite, SystemRole } from '~/models'
import { useUser } from '~/base'

export function useSiteLayers(site: SiteQuerySite) {
  const { sites, map, timeline } = useAppState()
  const user = useUser()

  useEffect(() => {
    if (!site || !timeline.activeSurvey || map.showLidar) {
      return
    }

    const rect = sites.getBoundsForSite(site)
    map.setHomeView(rect)

    // Create 2D layers.
    let layers: OrthotileLayer[] = []
    let tileLayers3D: TileLayer3D[] = []
    let markers: Marker[] = []
    if (map.projectionType === ProjectionType.Projection2D) {
      layers = layers.concat(
        timeline.activeSurvey.reconstructions
          .filter((r) => r.ortho && r.ortho.state === ReconstructionState.Processed)
          .map((r) => map.addOrthotileLayer(r.ortho.id, site.orgID))
      )

      tileLayers3D = tileLayers3D.concat(
        timeline.activeSurvey.reconstructions
          .filter((r) => !r.ortho && r.cesium3D)
          .map((r) => map.add3DTiles(r.cesium3D.id, r.cesium3D, site.orgID))
      )

      if (user.systemRole === SystemRole.Staff) {
        const matterportRecons = timeline.activeSurvey.reconstructions.filter((x) => x.matterport)
        markers = matterportRecons.map((r) => {
          const longitude = r.matterport.originX + Cesium.Math.toDegrees(r.matterport.translationX)
          const latitude = r.matterport.originY + Cesium.Math.toDegrees(r.matterport.translationY)
          return map.addMarker(
            r.matterport.id,
            [longitude, latitude],
            () => {
              timeline.setActiveInteriorModel(r)
            },
            'View interior',
            24,
            '#000000',
            MarkerIconType.Interior
          )
        })
      }
    }

    // Create 3D layers.
    if (map.projectionType === ProjectionType.Projection3D) {
      tileLayers3D = tileLayers3D.concat(
        timeline.activeSurvey.reconstructions
          .map((r) => {
            if (r.matterport) {
              return
            }

            return map.add3DTiles(r.cesium3D.id, r.cesium3D, site.orgID)
          })
          .filter((x) => !!x)
      )
    }

    return () => {
      map.clearOrthotileLayers()
      map.clear3DTiles()
      markers.map((m) => {
        map.removeMarker(m)
      })
    }
  }, [site?.id, map.projectionType, timeline.activeSurvey?.id, map.showLidar])
}
