import React, { useState } from 'react'
import { fullDate, useMutation } from '~/components'
import {
  CesiumAssetsQuery,
  CesiumCreateMutation,
  CesiumCreateMutationVariables,
  SitesQuerySite,
} from '~/models'
import ASSETI_LOGO from '../images/asseti-logo.svg'
import { Select, useToasts } from '~/components'
import { useAppState } from '~/state'
import CESIUM_CREATE_MUTATION from './mutation-cesium-create.gql'

interface CesiumProcessModalProps {
  model: CesiumAssetsQuery['cesiumAssets'][0]
  onComplete: () => Promise<any>
  onCancel: () => void
}

export const CesiumProcessModal = (props: CesiumProcessModalProps) => {
  const [createMutation] = useMutation<CesiumCreateMutation, CesiumCreateMutationVariables>(
    CESIUM_CREATE_MUTATION
  )
  const [loading, setLoading] = useState(false)
  const { sites } = useAppState()
  const toasts = useToasts()

  const [selectedSite, setSelectedSite] = useState<SitesQuerySite>()

  return (
    <div className='requires-subscription-popup'>
      <div className='requires-subscription-popup-content matterport'>
        <div className='requires-subscription-popup-content-header'>
          <img src={ASSETI_LOGO} alt='Asseti Logo' /> <span>Asseti</span>
        </div>
        <div className='requires-subscription-popup-content-text'>
          <h5>
            Import <b>{props.model.id} - {props.model.name}</b>?
          </h5>
          <Select<SitesQuerySite>
            id='site'
            label='Site'
            placeholder='Select site'
            onChange={(selected) => {
              setSelectedSite(selected)
            }}
            selectedValue={selectedSite}
            options={sites.data.sites.map((s) => {
              return {
                id: s.id,
                name: s.name,
                value: s,
                items: [],
              }
            })}
          />
        </div>
        <div className='requires-subscription-popup-content-buttons'>
          <button className='btn btn-secondary' onClick={props.onCancel} disabled={loading}>
            Cancel
          </button>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              e.preventDefault()
              setLoading(true)
              createMutation({
                variables: {
                  input: {
                    cesiumID: props.model.id,
                    day: fullDate(props.model.date),
                    siteID: selectedSite.id,
                  },
                },
              })
                .then(() => {
                  toasts.addTopLeft('Import successfully submitted')
                  return props.onComplete()
                })
                .finally(() => {
                  setLoading(false)
                  props.onCancel()
                })
            }}
            disabled={loading || !selectedSite}
          >
            {loading ? 'Importing...' : 'Import'}
          </button>
        </div>
      </div>
      <div className='requires-subscription-popup-overlay'></div>
    </div>
  )
}
