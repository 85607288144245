import React from 'react'
import { useUser } from '~/base'
import { classes, handleCurrencyFormatFull, SimpleBar } from '~/components'
import { DefectType } from '~/models'
import { useAppState } from '~/state'
import { IssueItemWithIndex, PhotoItem } from '../components'

interface IssuesBulkTaggingTableProps {
  issues: IssueItemWithIndex[]
  setSelectedIssue: (i: IssueItemWithIndex) => void
  selectedIssue: IssueItemWithIndex
  defectTypes: DefectType[]
  photos: PhotoItem[]
}

export const IssuesBulkTaggingTable = (props: IssuesBulkTaggingTableProps) => {
  const { timeline } = useAppState()
  const user = useUser()

  return (
    <div className='issues-bulk-tagging-table'>
      <div className='issues-bulk-tagging-table-title'>Issues ({props.issues?.length || 0})</div>
      <SimpleBar>
        <table className='table'>
          <thead>
            <tr>
              <th>Image</th>
              <th>Asset</th>
              <th>Component</th>
              <th>Defect</th>
              <th className='center'>Status</th>
              <th className='center'>Priority</th>
              <th className='center'>Risk</th>
              <th className='right'>Repair Estimate</th>
            </tr>
          </thead>
          <tbody>
            {props.issues.map((i) => {
              const defectType = props.defectTypes.find((d) => d.id === i.defectTypeID)
              const defectSubType = defectType && defectType.subTypes.find((s) => s.id === i.defectSubTypeID)
              const ctName = i.componentTypeFullName + (i.materialTypeName === '' ? '' : ` - ${i.materialTypeName}`)
              return (
                <tr
                  key={(i.id || '') + i.imageID + i.defectTypeID}
                  onClick={() => props.setSelectedIssue(i)}
                  className={classes({
                    selected: i.id === props.selectedIssue?.id,
                  })}
                >
                  <td className='dimmed'>#{i.photoIndex + 1}</td>
                  <td>{timeline.activeSurvey?.assets.find((a) => a.id === i.assetID)?.name || i.assetTypeName}</td>
                  <td>
                    {typeof i.component?.name === 'string' && i.component?.name !== ''
                      ? `${i.component.name} (${ctName})`
                      : ctName || '-'}
                  </td>
                  <td>
                    {defectType?.name || '-'}
                    {defectSubType ? ` - ${defectSubType.name}` : ''}
                  </td>
                  <td className='center'>{(i?.status || '').replace(/([A-Z])/g, ' $1').trim()}</td>
                  <td className='center'>{i.priority}</td>
                  <td className='center'>
                    <span
                      className={classes({
                        'issues-card-risk': true,
                        [i?.riskType.toLowerCase()]: true,
                      })}
                    >
                      {i?.riskType}
                    </span>
                  </td>
                  <td className='right'>{handleCurrencyFormatFull(i.repairEstimate, user)}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </SimpleBar>
    </div>
  )
}
