import React, { useState, useEffect, useRef, useMemo } from 'react'
import { useUser } from '~/base'
import {
  useLocation,
  useToasts,
  classes,
  getImageURL,
  PolygonColorRed,
  useMutation,
  formalDate,
  DrawerPopoutList,
  DrawerPopoutListCard,
} from '~/components'
import { GenerateReportMutation, IssueStatus, MutationReportGenerateArgs, RiskType, SiteQueryIssue } from '~/models'
import { useAppState } from '~/state'
import { Offset, Point } from './components'
import GENERATE_REPORT from './mutation-issues-generate-report.gql'
import { Config } from '~/config'
import dayjs from 'dayjs'
import { getIssueEstimatedRepairCost } from './issue-repair-cost-calculator'
import { IssueFilter } from './issue-filter'

export const IssueCardList = () => {
  const { view, site, issues, timeline } = useAppState()
  const toasts = useToasts()
  const user = useUser()

  const [selectedRiskFilter, setSelectedRiskFilter] = useState<RiskType | string>('Risk')
  const [selectedComponentType, setSelectedComponentType] = useState<string>('Component Type')
  const [selectedDefectType, setSelectedDefectType] = useState<string>('Defect Type')
  const [selectedPriorityFilter, setSelectedPriorityFilter] = useState<number | string>('Priority')
  const [selectedStatusFilter, setSelectedStatusFilter] = useState<string>('Status')

  const [doGenerateReport] = useMutation<GenerateReportMutation, MutationReportGenerateArgs>(GENERATE_REPORT)

  useEffect(() => {
    let issuesToUse = [...issues.issues]

    if (selectedRiskFilter !== 'Risk') {
      issuesToUse = issuesToUse.filter((i) => i.riskType === selectedRiskFilter)
    }

    if (selectedPriorityFilter !== 'Priority') {
      issuesToUse = issuesToUse.filter((i) => i.priority === selectedPriorityFilter)
    }

    if (selectedStatusFilter !== 'Status') {
      issuesToUse = issuesToUse.filter((i) => i.status === selectedStatusFilter)
    }

    if (selectedComponentType !== 'Component Type') {
      issuesToUse = issuesToUse.filter((i) => i.componentTypeID === selectedComponentType)
    }

    if (selectedDefectType !== 'Defect Type') {
      issuesToUse = issuesToUse.filter((i) => (i.defectSubTypeID || i.defectTypeID) === selectedDefectType)
    }

    const statusMap = {
      [IssueStatus.AwaitingAction]: 2,
      [IssueStatus.InProgress]: 1,
      [IssueStatus.Complete]: 0,
    }

    const riskMap = {
      [RiskType.Low]: 0,
      [RiskType.Medium]: 1,
      [RiskType.High]: 2,
    }

    issues.setFilteredIssues(
      issuesToUse.sort((a, b) => {
        if (statusMap[a.status] > statusMap[b.status]) {
          return -1
        }

        if (statusMap[a.status] < statusMap[b.status]) {
          return 1
        }

        if (riskMap[a.riskType] > riskMap[b.riskType]) {
          return -1
        }

        return a.priority > b.priority ? -1 : 1
      })
    )
  }, [
    selectedRiskFilter,
    selectedPriorityFilter,
    selectedStatusFilter,
    issues.allIssues,
    selectedComponentType,
    selectedDefectType,
  ])

  useEffect(() => {
    return () => {
      issues.setFilteredIssues([])
    }
  }, [])

  const generateReport = () => {
    doGenerateReport({
      variables: {
        input: {
          siteID: view.siteID,
          assetID: view.assetID || '',
          surveyID: timeline.activeSurvey.id,
          issueIDs: issues.filteredIssues.map(i => i.id),
        },
      },
    }).then(() => {
      toasts.addTopLeft('Report generation has been scheduled and will be sent to your registered email address', 5000)
    })
  }

  const generateCSV = () => {
    const lines = []
    for (const i of issues.filteredIssues) {
      const asset = timeline.activeSurvey.assets.find((s) => s.id === i.assetID)
      const cost = getIssueEstimatedRepairCost(user, i).cost
      lines.push(
        [
          i.id,
          `"${site.site.name}"`,
          site.site.number,
          site.site.id,
          `"${asset?.name || '-'}"`,
          asset?.number || '-',
          asset?.id || '-',
          i.assetTypeName || '-',
          `"${i?.component?.name || '-'}"`,
          i.componentTypeFullName + (i.materialTypeName === '' ? '' : ' - ' + i.materialTypeName) || '-',
          i.defectType + (i.defectSubType ? ' - ' + i.defectSubType : ''),
          i.status,
          i.riskType,
          i.priority,
          cost === 0 ? '-' : (cost / 100).toFixed(2),
          i.location?.latitude || '-',
          i.location?.longitude || '-',
          i.location?.altitude || '-',
          asset
            ? `${Config.BaseUrl}/${site.site.id}/${timeline.activeSurvey.id}/assets/${asset.id}/issue/${i.id}`
            : `${Config.BaseUrl}/${site.site.id}/${timeline.activeSurvey.id}/issue/${i.id}`,
          `${window.location.origin}/photo?h=${i.imageHash}`,
          formalDate(i.imageDate),
        ].join(',')
      )
    }

    const csv =
      'Issue ID,Site,Site Number,Site ID,Asset,Asset Number,Asset ID,Asset Type,Component,Component Type,Defect,Status,Risk,Priority,Cost,Latitude,Longitude,Altitude,Issue Link,Image Link,Image Date\n' +
      lines.join('\n')

    const link = document.createElement('a')
    link.id = 'download-csv'
    link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(csv))
    link.setAttribute('download', `${dayjs().format('YYYY-MM-DD')}-${user.org.name}-${site.site.name}-issues.csv`)
    document.body.appendChild(link)
    document.getElementById('download-csv').click()
    document.body.removeChild(link)
  }

  const { componentTypes, defectTypes, priorities, risks, statuses } = useMemo(() => {
    const componentTypes: { [k: string]: { id: string; text: string } } = issues.allIssues.reduce((a, b) => {
      a[b.componentTypeID] = {
        id: b.componentTypeID,
        text: b.componentTypeFullName,
      }
      return a
    }, {} as { [k: string]: { id: string; text: string } })

    const defectTypes: { [k: string]: { id: string; text: string } } = issues.allIssues.reduce((a, b) => {
      a[b.defectSubTypeID || b.defectTypeID] = {
        id: b.defectSubTypeID || b.defectTypeID,
        text: b.defectSubType ? b.defectType + ' - ' + b.defectSubType : b.defectType,
      }
      return a
    }, {} as { [k: string]: { id: string; text: string } })

    const priorities: { [k: string]: { id: number; text: string } } = issues.allIssues.reduce((a, b) => {
      a[b.priority] = {
        id: b.priority,
        text: b.priority + '',
      }
      return a
    }, {} as { [k: string]: { id: number; text: string } })

    const risks: { [k: string]: { id: RiskType; text: string } } = issues.allIssues.reduce((a, b) => {
      a[b.riskType + ''] = {
        id: b.riskType,
        text: b.riskType,
      }
      return a
    }, {} as { [k: string]: { id: RiskType; text: string } })

    const statuses: { [k: string]: { id: IssueStatus; text: string } } = issues.allIssues.reduce((a, b) => {
      a[b.status + ''] = {
        id: b.status,
        text:
          b.status === IssueStatus.AwaitingAction
            ? 'Awaiting Action'
            : b.status === IssueStatus.InProgress
            ? 'In Progress'
            : 'Complete',
      }
      return a
    }, {} as { [k: string]: { id: IssueStatus; text: string } })

    return {
      componentTypes,
      defectTypes,
      priorities,
      risks,
      statuses,
    }
  }, [issues.allIssues])

  const Controls = (
    <div className='issues-card-filters'>
      <IssueFilter
        type='status'
        subType={selectedStatusFilter as IssueStatus}
        options={[
          {
            text: 'All',
            id: 'Status' as IssueStatus,
            alt: true,
          },
          ...Object.keys(statuses)
            .map((x) => statuses[x])
            .sort((a, b) => (a.text > b.text ? 1 : -1)),
        ]}
        update={(v) => {
          setSelectedStatusFilter(v)
        }}
      />
      <IssueFilter
        type='risk'
        subType={selectedRiskFilter as RiskType}
        options={[
          {
            text: 'All',
            id: 'Risk' as RiskType,
            alt: true,
          },
          ...Object.keys(risks)
            .map((x) => risks[x])
            .sort((a, b) => (a.text > b.text ? 1 : -1)),
        ]}
        update={(v) => {
          setSelectedRiskFilter(v)
        }}
      />
      <IssueFilter
        type='priority'
        subType={selectedPriorityFilter as any}
        options={[
          {
            text: 'All',
            id: 'Priority' as any,
            alt: true,
          },
          ...Object.keys(priorities)
            .map((x) => priorities[x])
            .sort((a, b) => (a.text > b.text ? 1 : -1)),
        ]}
        update={(v) => {
          setSelectedPriorityFilter(v)
        }}
      />

      <IssueFilter
        type='component-type'
        subType={selectedComponentType}
        options={[
          {
            text: 'All',
            id: 'Component Type',
            alt: true,
          },
          ...Object.keys(componentTypes)
            .map((x) => componentTypes[x])
            .sort((a, b) => (a.text > b.text ? 1 : -1)),
        ]}
        update={(v) => {
          setSelectedComponentType(v)
        }}
      />

      <IssueFilter
        type='defect-type'
        subType={selectedDefectType}
        options={[
          {
            text: 'All',
            id: 'Defect Type',
            alt: true,
          },
          ...Object.keys(defectTypes)
            .map((x) => defectTypes[x])
            .sort((a, b) => (a.text > b.text ? 1 : -1)),
        ]}
        update={(v) => {
          setSelectedDefectType(v)
        }}
      />
    </div>
  )

  let exportOptions = [
    {
      text: 'CSV',
      id: 'CSV',
    },
  ]

  if (!site.site?.isDemoSite) {
    exportOptions.push({
      text: 'PDF',
      id: 'PDF',
    })
  }

  const PageNumberControls = (
    <IssueFilter
      type='defect-type'
      subType={'Export'}
      inline
      options={exportOptions}
      update={(v) => {
        if (v === 'PDF') {
          generateReport()
        } else {
          generateCSV()
        }
      }}
    />
  )

  return (
    <DrawerPopoutList
      width={440}
      title='Issues'
      total={issues.issues.length}
      count={issues.filteredIssues.length}
      emptyText='There are no issues to display.'
      controls={Controls}
      pageNumberControls={PageNumberControls}
    >
      {issues.filteredIssues.map((issue) => {
        return <IssueCard key={issue.id} issue={issue} />
      })}
    </DrawerPopoutList>
  )
}

interface IssueItemImageProps {
  issue: SiteQueryIssue
}

function IssueItemImage(props: IssueItemImageProps) {
  const canvas = useRef<HTMLCanvasElement>()
  const [imageUrl, setImageUrl] = useState<string>()
  const { site } = useAppState()

  // Load the image.
  useEffect(() => {
    if (!canvas.current || imageUrl) {
      return
    }

    const canvasCurrent = canvas.current
    const ctxCanvas = canvasCurrent.getContext('2d')
    ctxCanvas.imageSmoothingEnabled = true
    ctxCanvas.imageSmoothingQuality = 'high'
    ctxCanvas.canvas.width = canvasCurrent.clientWidth
    ctxCanvas.canvas.height = canvasCurrent.clientHeight

    const mediumImg = new Image()

    mediumImg.onload = () => {
      if (!canvas.current) {
        return
      }

      const minX = Math.min(...props.issue.coordinates.map((x) => x.x)) * mediumImg.width
      const minY = Math.min(...props.issue.coordinates.map((x) => x.y)) * mediumImg.height
      const maxX = Math.max(...props.issue.coordinates.map((x) => x.x)) * mediumImg.width
      const maxY = Math.max(...props.issue.coordinates.map((x) => x.y)) * mediumImg.height

      const desiredRatio = 4 / 3
      let cx = (maxX + minX) / 2
      let cy = (maxY + minY) / 2
      let width = Math.max(maxX - minX, 100)
      let height = maxY - minY
      let currentRatio = width / height
      if (currentRatio < desiredRatio) {
        width = height * desiredRatio
      } else if (currentRatio > desiredRatio) {
        height = width / desiredRatio
      }

      let x = Math.floor(Math.max(cx - width / 2, 0))
      let y = Math.floor(Math.max(cy - height / 2, 0))
      if (x + width > mediumImg.width) {
        x = mediumImg.width - width
      }
      if (y + height > mediumImg.height) {
        y = mediumImg.height - height
      }

      const getCanvasPositionCropped = (p: Point): Offset => {
        return {
          left: ((p.x - x) / width) * canvasCurrent.width,
          top: ((p.y - y) / height) * canvasCurrent.height,
        }
      }

      ctxCanvas.drawImage(mediumImg, x, y, width, height, 0, 0, canvasCurrent.width, canvasCurrent.height)

      const coordsFull = [...props.issue.coordinates, props.issue.coordinates[0]]
      const coordsCropped = coordsFull.map((p) => {
        return {
          x: p.x * mediumImg.width,
          y: p.y * mediumImg.height,
        }
      })

      ctxCanvas.save()
      ctxCanvas.fillStyle = PolygonColorRed
      ctxCanvas.strokeStyle = PolygonColorRed
      ctxCanvas.lineWidth = 2

      for (let i = 1; i < coordsCropped.length; i++) {
        const previousPosition = getCanvasPositionCropped(coordsCropped[i - 1])
        const currentPosition = getCanvasPositionCropped(coordsCropped[i])
        ctxCanvas.beginPath()
        ctxCanvas.moveTo(previousPosition.left, previousPosition.top)
        ctxCanvas.lineTo(currentPosition.left, currentPosition.top)
        ctxCanvas.closePath()
        ctxCanvas.stroke()
      }

      ctxCanvas.restore()

      const dataUrl = canvas.current.toDataURL()
      setImageUrl(dataUrl)
    }

    mediumImg.setAttribute('crossorigin', 'anonymous')
    mediumImg.src = getImageURL(
      {
        hash: props.issue.imageHash,
      },
      site.site.orgID
    )
  }, [canvas.current, imageUrl])

  if (imageUrl) {
    return <img src={imageUrl} />
  }

  return <canvas ref={canvas}></canvas>
}

interface IssueCardProps {
  issue: SiteQueryIssue
}

function IssueCard(props: IssueCardProps) {
  const { view } = useAppState()
  const location = useLocation()
  const user = useUser()
  const issue = props.issue

  return (
    <DrawerPopoutListCard
      key={issue.surveyID + issue.id}
      active={issue.id === view.issueID}
      className={classes({
        'issues-card': true,
        [issue.riskType.toLowerCase()]: true,
      })}
      onClick={() => {
        let url = ''
        if (view.assetID) {
          url = `/${view.siteID}/${view.surveyID}/assets/${view.assetID}/issue/${issue.id}`
        } else {
          url = `/${view.siteID}/${view.surveyID}/issue/${issue.id}`
        }

        location.setLocation(url)
      }}
    >
      <div className='issues-card-left'>
        <IssueItemImage issue={issue} />
      </div>
      <div className='issues-card-right'>
        <div className='issues-card-right-upper'>
          {issue.defectType}
          {issue.defectSubType ? ' - ' + issue.defectSubType : ''}
        </div>
        <div className='issues-card-right-middle'>
          {issue.component?.name ||
            issue.componentTypeFullName + (issue.materialTypeName === '' ? '' : ' - ' + issue.materialTypeName) ||
            '-'}
        </div>
        <div className='issues-card-right-lower'>
          <div
            className={classes({
              'issues-card-repair-estimate': true,
            })}
          >
            {getIssueEstimatedRepairCost(user, issue).formatted}
          </div>
          <div
            className={classes({
              'issues-card-status': true,
              [issue.status]: true,
              truncate: true,
            })}
          >
            {(issue.status || '').replace(/([A-Z])/g, ' $1').trim()}
          </div>
          <div
            className={classes({
              'issues-card-risk': true,
              [issue.riskType.toLowerCase()]: true,
            })}
          >
            {issue.riskType}
          </div>
          <div
            className={classes({
              'issues-card-priority': true,
              ['s' + issue.priority]: true,
            })}
          >
            {issue.priority}
          </div>
        </div>
      </div>
    </DrawerPopoutListCard>
  )
}
