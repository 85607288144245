import React, { useEffect, useState } from 'react'
import { useQuery, SimpleBar, classes, useMutation, useToasts, FloatingInput } from '~/components'
import { BoundaryRenderState, MonitoringZoneRenderState, useAppState, useRefState } from '~/state'
import COVERAGE_BY_POLYGON_QUERY from './query-instant-assessments-coverage-by-polygon.gql'
import METADATA_QUERY from './query-instant-assessments-metadata.gql'
import INSTANT_ASSESSMENTS_BY_SITE from './query-instant-assessments-by-site.gql'
import CREATE_MUTATION from './mutation-instant-assessment-create.gql'
import {
  InstantAssessmentCreateMutation,
  InstantAssessmentCreateMutationVariables,
  InstantAssessmentsBySiteQuery,
  InstantAssessmentsBySiteQueryVariables,
  InstantAssessmentsCoverageByPolygonQuery,
  InstantAssessmentsCoverageByPolygonQueryVariables,
  InstantAssessmentsMetadataQuery,
  InstantAssessmentsMetadataQueryVariables,
  PointT,
} from '~/models'
import { ConfirmModal } from '~/admin/modals'
import dayjs from 'dayjs'
import { getOffsetPolygon } from '~/components/helpers/get-offset-polygon'
import { PolygonRendererView } from '~/admin/photogrammetry/reconstruction-creation/polygon-renderer-view'

interface SurveyResourceMetadata {
  [surveyResourceID: string]: {
    [boundaryID: string]: InstantAssessmentsMetadataQuery['instantAssessmentsMetadata']['surveyResources'][0]['photos']
  }
}

export const ToolsInstantAssessmentsCoverage = () => {
  const { map, timeline, components, issues, boundaryState, monitoringZoneState, view, site } = useAppState()
  const toasts = useToasts()

  const coverageQuery = useQuery<
    InstantAssessmentsCoverageByPolygonQuery,
    InstantAssessmentsCoverageByPolygonQueryVariables
  >(COVERAGE_BY_POLYGON_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'network-only',
  })

  const metadataQuery = useQuery<InstantAssessmentsMetadataQuery, InstantAssessmentsMetadataQueryVariables>(
    METADATA_QUERY,
    {
      fetchPolicy: 'standby',
      nextFetchPolicy: 'network-only',
    }
  )

  const assessmentsBySiteQuery = useQuery<InstantAssessmentsBySiteQuery, InstantAssessmentsBySiteQueryVariables>(
    INSTANT_ASSESSMENTS_BY_SITE,
    {
      fetchPolicy: 'standby',
      nextFetchPolicy: 'network-only',
    }
  )

  const [executeCreate] = useMutation<InstantAssessmentCreateMutation, InstantAssessmentCreateMutationVariables>(
    CREATE_MUTATION
  )

  const [coverage, setCoverage] =
    useState<InstantAssessmentsCoverageByPolygonQuery['instantAssessmentsCoverageByPolygon']>()
  const [surveysWithPhotos, setSurveysWithPhotos] = useState<
    InstantAssessmentsCoverageByPolygonQuery['instantAssessmentsCoverageByPolygon']['surveys']
  >([])
  const [coverageMetadata, setCoverageMetadata] = useState<SurveyResourceMetadata>({})
  const [activeSurvey, setActiveSurvey] =
    useState<InstantAssessmentsCoverageByPolygonQuery['instantAssessmentsCoverageByPolygon']['surveys'][0]>()
  const [process, setProcess] = useState(false)
  const [processAll, setProcessAll] = useState(false)
  const [instantAssessments, setInstantAssessments] =
    useState<InstantAssessmentsBySiteQuery['instantAssessmentsBySite']>()

  const [gridSizeInMeters, setGridSizeInMeters] = useState(75)
  const [gridExpansionInSegments, setGridExpansionInSegments] = useState(2)
  const [boundaryExpansion, setBoundaryExpansion] = useState(10)
  const [pointPrimitives] = useRefState(new Cesium.PointPrimitiveCollection())

  // Hide everything on the map.
  useEffect(() => {
    if (!map.initialized) {
      return
    }
    components.setDraw(false)
    issues.setShow(false)
    boundaryState.setRenderState(BoundaryRenderState.Site)
    monitoringZoneState.setRenderState(MonitoringZoneRenderState.None)
    timeline.setHidden(true)
    map.viewer.scene.primitives.add(pointPrimitives.current)

    return () => {
      components.setDraw(true)
      issues.setShow(true)
      boundaryState.setRenderState(BoundaryRenderState.SiteAndAssets)
      monitoringZoneState.setRenderState(MonitoringZoneRenderState.SiteAndAsset)
      timeline.setHidden(false)
      map.viewer.scene.primitives.remove(pointPrimitives.current)
    }
  }, [map.initialized])

  useEffect(() => {
    if (!site.site) {
      return
    }

    assessmentsBySiteQuery
      .refetch({
        input: {
          orgID: site.site.orgID,
          siteID: site.site.id,
        },
      })
      .then((res) => {
        setInstantAssessments(res.data.instantAssessmentsBySite)
      })
  }, [site.site])

  // Load the coverage for the site.
  useEffect(() => {
    if (!map.initialized || !timeline.activeSurvey || timeline.activeSurvey.boundaries.length === 0) {
      return
    }

    coverageQuery
      .refetch({
        input: {
          polygon: timeline.activeSurvey.boundaries[0].points.map((p) => {
            return {
              latitude: p.latitude,
              longitude: p.longitude,
            }
          }),
        },
      })
      .then((res) => {
        setCoverage(res.data.instantAssessmentsCoverageByPolygon)
        setSurveysWithPhotos(
          res.data.instantAssessmentsCoverageByPolygon.surveys.filter((s) => s.resources.photos.length > 0)
        )
      })
  }, [map.initialized, timeline.activeSurvey])

  // Load the metadata.
  useEffect(() => {
    if (surveysWithPhotos.length === 0) {
      setCoverageMetadata({})
      return
    }

    setCoverageMetadata({})

    const timeout = setTimeout(() => {
      metadataQuery
        .refetch({
          input: {
            surveyResources: surveysWithPhotos.map((s) => {
              return {
                surveyResourceID: s.resources.photos[0].id,
              }
            }),
            aois: timeline.activeSurvey.boundaries.map((b) => {
              return {
                id: b.id,
                polygon: b.points.map((p) => {
                  return {
                    latitude: p.latitude,
                    longitude: p.longitude,
                  }
                }),
              }
            }),
            siteID: view.siteID,
            gridExpansionInSegments: gridExpansionInSegments,
            gridSizeInMeters: gridSizeInMeters,
          },
        })
        .then((res) => {
          const meta: SurveyResourceMetadata = {}

          for (const surveyResource of res.data.instantAssessmentsMetadata.surveyResources) {
            if (!meta[surveyResource.surveyResourceID]) {
              meta[surveyResource.surveyResourceID] = {}
            }

            meta[surveyResource.surveyResourceID][surveyResource.aoi] = surveyResource.photos
          }

          setCoverageMetadata(meta)
        })
    }, 2500)

    return () => {
      clearTimeout(timeout)
    }
  }, [surveysWithPhotos, gridSizeInMeters, gridExpansionInSegments])

  useEffect(() => {
    if (!timeline.activeSurvey) {
      return
    }

    const points = timeline.activeSurvey.boundaries.reduce<Cesium.Cartographic[]>((a, b) => {
      return [...a, ...b.points.map((p) => Cesium.Cartographic.fromDegrees(p.longitude, p.latitude))]
    }, [])

    const rect = Cesium.Rectangle.fromCartographicArray(points)
    let topLeft = Cesium.Cartographic.fromRadians(rect.west, rect.north)
    let topRight = Cesium.Cartographic.fromRadians(rect.east, rect.north)
    let bottomLeft = Cesium.Cartographic.fromRadians(rect.west, rect.south)
    let geodesicHorizontal = new Cesium.EllipsoidGeodesic(topLeft, topRight)
    let geodesicVertical = new Cesium.EllipsoidGeodesic(topLeft, bottomLeft)
    let horizontalMeters = geodesicHorizontal.surfaceDistance
    let verticalMeters = geodesicVertical.surfaceDistance
    const horizontalDegreesPerM =
      (Cesium.Math.toDegrees(rect.east) - Cesium.Math.toDegrees(rect.west)) / horizontalMeters
    const verticalDegreesPerM = (Cesium.Math.toDegrees(rect.north) - Cesium.Math.toDegrees(rect.south)) / verticalMeters
    const horizontalDegrees = horizontalDegreesPerM * gridSizeInMeters
    const verticalDegrees = verticalDegreesPerM * gridSizeInMeters

    const minLng = Cesium.Math.toDegrees(rect.west) - gridExpansionInSegments * horizontalDegrees
    const maxLng = Cesium.Math.toDegrees(rect.east) + gridExpansionInSegments * horizontalDegrees
    const minLat = Cesium.Math.toDegrees(rect.south) - gridExpansionInSegments * verticalDegrees
    const maxLat = Cesium.Math.toDegrees(rect.north) + gridExpansionInSegments * verticalDegrees

    topLeft = Cesium.Cartographic.fromDegrees(minLng, maxLat)
    topRight = Cesium.Cartographic.fromDegrees(maxLng, maxLat)
    bottomLeft = Cesium.Cartographic.fromDegrees(minLng, minLat)
    geodesicHorizontal = new Cesium.EllipsoidGeodesic(topLeft, topRight)
    geodesicVertical = new Cesium.EllipsoidGeodesic(topLeft, bottomLeft)
    horizontalMeters = geodesicHorizontal.surfaceDistance
    verticalMeters = geodesicVertical.surfaceDistance

    const numHorizontalSegments = Math.ceil(horizontalMeters / gridSizeInMeters)
    const numVerticalSegments = Math.ceil(verticalMeters / gridSizeInMeters)
    const deltaX = (maxLng - minLng) / (numHorizontalSegments - 1)
    const deltaY = (maxLat - minLat) / (numVerticalSegments - 1)

    for (let x = 0; x < numHorizontalSegments; x++) {
      for (let y = 0; y < numVerticalSegments; y++) {
        const lng = minLng + deltaX * x
        const lat = minLat + deltaY * y

        pointPrimitives.current.add({
          position: Cesium.Cartesian3.fromDegrees(lng, lat, 0),
          pixelSize: 4.0,
          color: Cesium.Color.BLUE,
        })
      }
    }

    pointPrimitives.current.add({
      position: Cesium.Cartesian3.fromDegrees(minLng, minLat, 0),
      pixelSize: 6.0,
      color: Cesium.Color.YELLOW,
    })
    pointPrimitives.current.add({
      position: Cesium.Cartesian3.fromDegrees(minLng, maxLat, 0),
      pixelSize: 6.0,
      color: Cesium.Color.YELLOW,
    })
    pointPrimitives.current.add({
      position: Cesium.Cartesian3.fromDegrees(maxLng, maxLat, 0),
      pixelSize: 6.0,
      color: Cesium.Color.YELLOW,
    })
    pointPrimitives.current.add({
      position: Cesium.Cartesian3.fromDegrees(maxLng, minLat, 0),
      pixelSize: 6.0,
      color: Cesium.Color.YELLOW,
    })

    return () => {
      pointPrimitives.current.removeAll()
    }
  }, [timeline.activeSurvey, gridSizeInMeters, gridExpansionInSegments])

  useEffect(() => {
    if (!activeSurvey) {
      return
    }

    const meta = coverageMetadata[activeSurvey.resources.photos[0].id]
    const allPhotos = Object.keys(meta)
      .map((k) => meta[k])
      .reduce((a, b) => [...a, ...b], [])
    const points = allPhotos.map((p) => {
      return map.addMarker(p.id, [p.gpsCoordinate.coordinates[0], p.gpsCoordinate.coordinates[1]])
    })

    // const polys = allPhotos.map((p) => {
    //   const color = Cesium.Color.fromRandom()
    //   return {
    //     poly: map.viewer.entities.add({
    //       polygon: {
    //         hierarchy: p.projectedExtent.coordinates[0].polygon.map((p) =>
    //           Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude)
    //         ),
    //         heightReference: Cesium.HeightReference.CLAMP_TO_GROUND,
    //         material: color.withAlpha(0.01),
    //       },
    //       show: true,
    //     }),
    //     line: map.viewer.entities.add({
    //       polyline: {
    //         positions: p.projectedExtent.coordinates[0].polygon.map((p) =>
    //           Cesium.Cartesian3.fromDegrees(p.longitude, p.latitude)
    //         ),
    //         clampToGround: true,
    //         material: color.withAlpha(0.8),
    //         width: 2,
    //       },
    //       show: true,
    //     }),
    //   }
    // })

    return () => {
      points.forEach((p) => map.removeMarkerById(p.id))
      // polys.forEach((p) => {
      //   map.viewer.entities.remove(p.line)
      //   map.viewer.entities.remove(p.poly)
      // })
    }
  }, [activeSurvey])

  useEffect(() => {
    if (!timeline.activeSurvey) {
      return
    }
    const polygons: PolygonRendererView[] = []

    let addFromAOI = false

    if (activeSurvey) {
      const assessment = instantAssessments.find((x) => x.surveyResourceID === activeSurvey.resources.photos[0].id)
      if (assessment) {
        addFromAOI = true
        for (const b of assessment.aois) {
          const polygon = new PolygonRendererView({
            id: Math.random() + 'boundary',
            points: b.points,
            color: '#0B7FB9',
            viewer: map.viewer,
            renderPoints: false,
          })
          polygons.push(polygon)
        }
      }
    }

    if (!addFromAOI) {
      for (const b of timeline.activeSurvey.boundaries) {
        const points = getPointsToUse(b.points, boundaryExpansion)
        const polygon = new PolygonRendererView({
          id: Math.random() + 'boundary',
          points,
          color: '#0B7FB9',
          viewer: map.viewer,
          renderPoints: false,
        })
        polygons.push(polygon)
      }
    }

    return () => {
      if (polygons.length > 0) {
        polygons.forEach((b) => b.destroy())
        polygons.slice(0, polygons.length)
      }
    }
  }, [activeSurvey, boundaryExpansion])

  const isLoading = !coverage || assessmentsBySiteQuery.loading || !instantAssessments
  console.log('Loading', !coverage, assessmentsBySiteQuery.loading, !instantAssessments)

  const canProcessAll =
    surveysWithPhotos.filter((s) => {
      const assessment = (instantAssessments || []).find((x) => x.surveyResourceID === s.resources.photos[0].id)
      if (assessment) {
        return false
      }

      const meta = coverageMetadata[s.resources.photos[0].id]
      if (meta) {
        const numPhotos = Object.keys(meta)
          .map((k) => meta[k])
          .reduce((a, b) => a + b.length, 0)

        if (numPhotos > 0) {
          return true
        }
      }

      return false
    }).length > 0

  return (
    <>
      <div className='tools-instant-assessments-container'>
        {isLoading && 'Loading coverage...'}
        {!isLoading && (
          <>
            <h6>Surveys</h6>
            Loaded {coverage.surveys.length} of {coverage.total} ({surveysWithPhotos.length} have photos)
            <br />
            <br />
            <div className='tools-instant-assessments-controls'>
              <div className='tools-instant-assessments-controls-title'>
                <span>Configuration</span>
                <button
                  className='btn btn-sm btn-primary'
                  onClick={(e) => {
                    e.preventDefault()
                    setProcessAll(true)
                  }}
                  disabled={!canProcessAll}
                >
                  Process All
                </button>
              </div>
              <div className='tools-instant-assessments-controls-content'>
                <FloatingInput
                  id='gridSizeInMeters'
                  label='Grid Size (meters)'
                  type='number'
                  min={10}
                  max={150}
                  step={1}
                  value={gridSizeInMeters}
                  onChange={(e) => {
                    let newGridSize = parseInt(e.target.value, 10) || 20
                    if (newGridSize < 10) {
                      newGridSize = 10
                    }
                    if (newGridSize > 150) {
                      newGridSize = 150
                    }
                    setGridSizeInMeters(newGridSize)
                  }}
                />
                <FloatingInput
                  id='gridExpansionInSegments'
                  label='Grid Expansion (segments)'
                  type='number'
                  min={0}
                  max={10}
                  step={1}
                  value={gridExpansionInSegments}
                  onChange={(e) => {
                    let newGridSize = parseInt(e.target.value, 10) || 0
                    if (newGridSize < 0) {
                      newGridSize = 0
                    }
                    if (newGridSize > 10) {
                      newGridSize = 10
                    }
                    setGridExpansionInSegments(newGridSize)
                  }}
                />
                <FloatingInput
                  id='boundaryExpansion'
                  label='Boundary Expansion (meters)'
                  type='number'
                  min={0}
                  max={50}
                  step={1}
                  value={boundaryExpansion}
                  onChange={(e) => {
                    let newBoundaryExpansion = parseFloat(e.target.value) || 10
                    if (newBoundaryExpansion < 0) {
                      newBoundaryExpansion = 0
                    }
                    if (newBoundaryExpansion > 50) {
                      newBoundaryExpansion = 50
                    }
                    setBoundaryExpansion(newBoundaryExpansion)
                  }}
                />
              </div>
            </div>
            <SimpleBar>
              {surveysWithPhotos.map((s) => {
                let numPhotos = -1
                const meta = coverageMetadata[s.resources.photos[0].id]
                if (meta) {
                  numPhotos = Object.keys(meta)
                    .map((k) => meta[k])
                    .reduce((a, b) => a + b.length, 0)
                }
                const assessment = instantAssessments.find((x) => x.surveyResourceID === s.resources.photos[0].id)
                return (
                  <div
                    key={s.id}
                    className={classes({
                      'tools-instant-assessments-survey-container': true,
                      active: activeSurvey && activeSurvey.id === s.id,
                    })}
                    onClick={(e) => {
                      if (e.defaultPrevented) {
                        return
                      }
                      if (numPhotos === -1) {
                        return
                      }
                      if (activeSurvey && activeSurvey.id === s.id) {
                        setActiveSurvey(undefined)
                      } else {
                        setActiveSurvey(s)
                      }
                    }}
                  >
                    <div className='tools-instant-assessments-survey-id'>{s.id}</div>
                    <div className='tools-instant-assessments-survey-date'>
                      {dayjs(s.captureDate).format('DD MMM YYYY')}
                      {assessment && (
                        <span className='issue-badge risk Medium' style={{ float: 'right' }}>
                          Processed
                        </span>
                      )}
                    </div>
                    <div className='tools-instant-assessments-survey-gsd'>{s.pixelSize * 100} cm/pixel</div>
                    <div className='tools-instant-assessments-survey-photos'>
                      {numPhotos === -1 ? 'Loading' : `${numPhotos} images`}
                    </div>
                    {activeSurvey && activeSurvey.id === s.id && !assessment && (
                      <div onClick={(e) => e.preventDefault()}>
                        {/* <div className='tools-instant-assessments-survey-control'>
                          <label htmlFor='expansion'>Expansion (m)</label>
                          <input
                            id='smoothingFactor'
                            className='form-control'
                            value={buffer}
                            type='number'
                            min={0.01}
                            max={100}
                            step={0.5}
                            onChange={(e) => {
                              const newBuffer = e.target.value || '10'
                              setBuffer(parseFloat(newBuffer))
                            }}
                            autoComplete='off'
                          />
                        </div> */}
                        <button
                          className='btn btn-primary btn-sm'
                          onClick={(e) => {
                            e.preventDefault()
                            setProcess(true)
                          }}
                        >
                          Process
                        </button>
                      </div>
                    )}
                  </div>
                )
              })}
            </SimpleBar>
          </>
        )}
      </div>
      {activeSurvey && process && (
        <ConfirmModal
          close={() => setProcess(false)}
          confirmText='Process'
          confirmSubmittingText='Processing'
          title={`Process survey on ${activeSurvey.captureDate}?`}
          text={`Are you sure you want to process the survey?`}
          onConfirm={() => {
            const meta = coverageMetadata[activeSurvey.resources.photos[0].id]
            const allPhotos = Object.keys(meta)
              .map((k) => meta[k])
              .reduce((a, b) => [...a, ...b], [])

            const surveyResourceID = activeSurvey.resources.photos[0].id
            return executeCreate({
              variables: {
                input: {
                  orgID: site.site.orgID,
                  siteID: site.site.id,
                  surveys: [
                    {
                      surveyDate: dayjs(activeSurvey.captureDate).unix(),
                      surveyResourceID: surveyResourceID,
                      externalImageIDs: allPhotos.map((p) => p.id),
                    },
                  ],
                  aois: timeline.activeSurvey.boundaries.map((b) => {
                    const points = getPointsToUse(b.points, boundaryExpansion)
                    return {
                      points,
                    }
                  }),
                  gridExpansionInSegments: gridExpansionInSegments,
                  gridSizeInMeters: gridSizeInMeters,
                },
              },
            })
              .then(() => {
                toasts.addTopLeft('Successfully created instant assessment')
                setInstantAssessments(undefined)
                return assessmentsBySiteQuery
                  .refetch({
                    input: {
                      orgID: site.site.orgID,
                      siteID: site.site.id,
                    },
                  })
                  .then((res) => {
                    setInstantAssessments(res.data.instantAssessmentsBySite)
                  })
              })
              .catch(() => {
                return 'Failed to process survey'
              })
          }}
          color='danger'
        />
      )}
      {processAll && (
        <ConfirmModal
          close={() => setProcessAll(false)}
          confirmText='Process'
          confirmSubmittingText='Processing'
          title={`Process all surveys?`}
          text={`Are you sure you want to process all surveys? Surveys which have already been processed will be skipped.`}
          onConfirm={() => {
            const surveys = []
            for (const s of surveysWithPhotos) {
              const assessment = instantAssessments.find((x) => x.surveyResourceID === s.resources.photos[0].id)
              if (assessment) {
                continue
              }

              const meta = coverageMetadata[s.resources.photos[0].id]
              const allPhotos = Object.keys(meta)
                .map((k) => meta[k])
                .reduce((a, b) => [...a, ...b], [])

              const surveyResourceID = s.resources.photos[0].id

              surveys.push({
                surveyDate: dayjs(s.captureDate).unix(),
                surveyResourceID: surveyResourceID,
                externalImageIDs: allPhotos.map((p) => p.id),
              })
            }

            if (surveys.length === 0) {
              toasts.addTopLeft('Successfully created instant assessments')
              return Promise.resolve()
            }

            return executeCreate({
              variables: {
                input: {
                  orgID: site.site.orgID,
                  siteID: site.site.id,
                  surveys,
                  aois: timeline.activeSurvey.boundaries.map((b) => {
                    const points = getPointsToUse(b.points, boundaryExpansion)
                    return {
                      points,
                    }
                  }),
                  gridExpansionInSegments: gridExpansionInSegments,
                  gridSizeInMeters: gridSizeInMeters,
                },
              },
            })
              .then(() => {
                toasts.addTopLeft('Successfully created instant assessments')
                setInstantAssessments(undefined)
                return assessmentsBySiteQuery
                  .refetch({
                    input: {
                      orgID: site.site.orgID,
                      siteID: site.site.id,
                    },
                  })
                  .then((res) => {
                    setInstantAssessments(res.data.instantAssessmentsBySite)
                  })
              })
              .catch(() => {
                return 'Failed to process surveys'
              })
          }}
          color='danger'
        />
      )}
    </>
  )
}

function isClockwise(points: PointT[]) {
  let sum = 0.0
  let v1 = points[points.length - 1]
  for (let i = 0; i < points.length; i++) {
    let v2 = points[i]
    sum += (v2.longitude - v1.longitude) * (v2.latitude + v1.latitude)
    v1 = v2
  }
  return sum > 0.0
}

function getPointsToUse(points: PointT[], buffer: number) {
  const mustReverse = isClockwise([...points])
  let pointsToUse = [...points, points[0]]
  if (mustReverse) {
    pointsToUse.reverse()
  }

  const toReturn = getOffsetPolygon(pointsToUse, buffer, true)
  return toReturn
}
