import React, { useEffect, useState } from 'react'
import { AuthLayout, AuthLayoutHeadingType } from '~/auth'
import { AuthError } from '~/auth/auth-error'
import { Helmet, AuthButton, useLocation, ActivityIndicator, useMutation } from '~/components'
import { MatterportAuthMutation, MutationMatterportAuthArgs } from '~/models'
import MUTATION_MATTERPORT_AUTH from './mutation-matterport-auth.gql'


export const MatterportAuthSuccess = () => {
  const location = useLocation()
  const code = new URLSearchParams(window.location.search).get('code')
  const domain = decodeURIComponent(new URLSearchParams(window.location.search).get('d'))

  if (!code || !domain) {
    location.setLocation('/404')
    return null
  }

  if(domain !== window.location.origin) {
    window.location.href = `${domain}/matterport-auth-success?d=${domain}&code=${code}`
    return null
  }

  const [complete, setComplete] = useState(false)
  const [error, setError] = useState<string>()

  const [execute] = useMutation<MatterportAuthMutation, MutationMatterportAuthArgs>(MUTATION_MATTERPORT_AUTH)

  useEffect(() => {
    if(complete) {
      return
    }
    execute({
      variables: {
        input: {
          code,
          domain,
        },
      },
    }).then((result) => {
      if(result.data.matterportAuth.success) {
        setComplete(true)
      } else {
        setError('An unknown error occurred, please try again.')
      }
    }).catch((e) => {
      setError('An unknown error occurred, please try again.')
    })
  }, [code, complete])

  return (
    <AuthLayout headingType={AuthLayoutHeadingType.SIGN_IN_OR_SIGN_UP}>
      <Helmet title='Matterport Authentication' />
      <div className='auth-form-container mb-3'>
        <h3 className='subheader-dark mt-5 mb-5'>Matterport Authentication</h3>
        {error && <AuthError className='alert alert-warning mb-4' error={error} />}
        {!error && !complete && (
          <ActivityIndicator />
        )}
        {!error && complete && (
          <>
          <p className='auth-description-text mb-4'>
            Your Matterport account has successfully been authenticated.
          </p>
          <AuthButton onClick={() => location.setLocation('/')}>Continue</AuthButton>
          </>
        )}
      </div>
    </AuthLayout>
  )
}
