import { useQuery } from '~/components'
import React, { useEffect, useState } from 'react'
import { string, object, number, array } from '~/components'
import { DrawerFormCancelButton, DrawerFormSubmitButton, getCurrencyPrefix, Helmet } from '~/components'
import {
  FloatingInput,
  FloatingTextArea,
  Select,
  useForm,
  DrawerForm,
  DrawerFormBreadcrumbs,
  DrawerFormBreadcrumbItem,
  DrawerFormHeading,
  DrawerFormContent,
  DrawerFormButtons,
  getTracking,
  useToasts,
} from '~/components'
import { BaseLayerType, LeftClickModelPosition, MarkerIconType, ProjectionType, useAppState } from '~/state'
import UPDATE_MANUALLY_TAGGED_ISSUE from './mutation-issues-update.gql'
import DEFECT_TYPES_QUERY from './query-defect-types.gql'
import { PrioritySelect, DefectTypeSelect, RiskTypeSelect, StatusSelect } from './selects'
import {
  DefectType,
  DefectSubType,
  GetDefectTypesQuery,
  UpdateManuallyTaggedIssueMutation,
  UpdateManuallyTaggedIssueRequestT,
  ComponentDtoT,
  SiteQueryAsset,
  Issue,
  ComponentTypeSmallDtoT,
  AssetTypeDtoT,
  GetAssetTypeByIdQuery,
  GetAssetTypeByIdQueryVariables,
  SurveyBasedOperation,
  MaterialTypeSmallDtoT,
  ComponentTypeDtoT,
  Point3Dt,
} from '~/models'
import { ComponentTypeSelect } from '~/components/components2/component-type-select'
import ASSET_TYPE_BY_ID_QUERY from '../assets/query-asset-type-by-id.gql'
import { AssetTypeSelect } from './selects/asset-types-select'
import { useUser } from '~/base'
import { getIssueEstimatedRepairCost } from './issue-repair-cost-calculator'

interface IssuesUpdateFormProps {
  selectedIssue: Issue
  internalPoint?: Point3Dt
}

export const IssuesUpdateForm = (props: IssuesUpdateFormProps) => {
  const { map, timeline, imageTagger, view, site, asset, issues } = useAppState()
  const toasts = useToasts()
  const user = useUser()

  const [showFormValidationMessages, setShowFormValidationMessages] = useState(false)
  const [defectType, setDefectType] = useState<DefectType | undefined>()
  const [defectSubType, setDefectSubType] = useState<DefectSubType | undefined>()
  const [selectedComponent, setSelectedComponent] = useState<ComponentDtoT>()

  const [selectedComponentTypes, setSelectedComponentTypes] = useState<ComponentTypeSmallDtoT[]>([])
  const [selectedMaterialType, setSelectedMaterialType] = useState<MaterialTypeSmallDtoT>(undefined)

  const defectTypesQuery = useQuery<GetDefectTypesQuery>(DEFECT_TYPES_QUERY)
  const [defectTypeList, setDefectTypeList] = useState([])
  const [components, setComponents] = useState<ComponentDtoT[]>([])
  const [selectedAsset, setSelectedAsset] = useState<SiteQueryAsset>(
    timeline.activeSurvey?.assets.find((a) => a.id === props.selectedIssue.assetID)
  )
  const [selectedAssetType, setSelectedAssetType] = useState<AssetTypeDtoT>()

  const { values, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue, submitForm } = useForm<
    UpdateManuallyTaggedIssueMutation,
    UpdateManuallyTaggedIssueRequestT['items'][0] & { operationType: SurveyBasedOperation },
    UpdateManuallyTaggedIssueRequestT
  >({
    enableReinitialize: true,
    validationSchema: object().shape({
      name: string().optional(),
      priority: number().required(),
      riskType: string().required(),
      notes: string().optional(),
      repairEstimate: number().optional(),
      status: string().required(),
      location: object().required(),
      coordinates: array().required(),
      defectTypeID: string().required(),
      defectSubTypeID: string().optional(),
      componentID: string().optional(),
      componentTypeID: string().required(),
    }),
    initialValues: {
      id: props.selectedIssue.id,
      name: props.selectedIssue.name,
      priority: props.selectedIssue.priority,
      riskType: props.selectedIssue.riskType,
      notes: props.selectedIssue.notes,
      repairEstimate: (props.selectedIssue.repairEstimate || 0) / 100,
      status: props.selectedIssue.status,
      location: props.selectedIssue.location
        ? {
            altitude: props.selectedIssue.location.altitude,
            latitude: props.selectedIssue.location.latitude,
            longitude: props.selectedIssue.location.longitude,
          }
        : {
            altitude: 0,
            latitude: 0,
            longitude: 0,
          },
      coordinates: (props.selectedIssue.coordinates || []).map((c) => ({
        x: c.x,
        y: c.y,
      })),
      defectTypeID: props.selectedIssue.defectTypeID,
      defectSubTypeID: props.selectedIssue.defectSubTypeID,
      componentID: props.selectedIssue.component?.id || '',
      componentTypeID: props.selectedIssue?.component?.componentTypeID || props.selectedIssue?.componentTypeID || '',
      assetID: selectedAsset?.id || props.selectedIssue.assetID || '',
      assetTypeID: props.selectedIssue?.assetTypeID || '',
      materialTypeID: props.selectedIssue?.materialTypeID || '',
      operationType: SurveyBasedOperation.Singular,
      isInternal: props.selectedIssue?.isInternal,
      internalPoint: props.selectedIssue?.internalPoint,
      internalReconstructionID: props.selectedIssue?.internalReconstructionID,
    },
    mutation: UPDATE_MANUALLY_TAGGED_ISSUE,
    mapInput: (input) => {
      // const opType = input.operationType
      delete input['operationType']
      const toSubmit: UpdateManuallyTaggedIssueRequestT = {
        operationType: SurveyBasedOperation.Singular,
        siteID: view.siteID,
        surveyID: view.surveyID,
        items: [
          {
            ...input,
            repairEstimate: Math.floor(input.repairEstimate * 100),
            coordinates: (imageTagger.addingPoints || []).map((c) => ({
              x: c.x,
              y: c.y,
            })),
            materialTypeID: selectedMaterialType?.id || '',
            isInternal: !!timeline.activeInteriorModel,
            internalPoint: props.internalPoint,
            internalReconstructionID: timeline.activeInteriorModel?.id || '',
          },
        ],
      }
      return toSubmit
    },
    onSuccess: () => {
      getTracking().event({
        category: 'Form',
        action: `User updated a issue`,
        label: 'Issue',
      })
      site.refetch().then(() => {
        issues.setIssueToUpdate(undefined)
        toasts.addTopLeft('Issue successfully updated')
      })
    },
  })

  // Set UI state.
  useEffect(() => {
    if(timeline.activeInteriorModel) {
      return
    }
    map.setShowControls(false)
    timeline.setHidden(true)
    map.setBaseLayerType(BaseLayerType.PhotoModel).then(() => {
      function getHeightOffset() {
        if (!timeline.activeSurvey) {
          return 0
        }
        const heightOffset = timeline.activeSurvey.reconstructions.find(x => !!x.cesium3D)?.cesium3D?.translationZ || 0
        return heightOffset
      }

      const photo = imageTagger.photo
      map.viewer.camera.flyTo({
        destination: Cesium.Cartesian3.fromDegrees(photo.longitude, photo.latitude, photo.altitude + getHeightOffset()),
        orientation: Cesium.HeadingPitchRoll.fromDegrees(photo.yaw, photo.pitch, photo.roll),
        duration: 0.25,
      })
    })

    return () => {
      map.setShowControls(true)
      timeline.setHidden(false)
    }
  }, [timeline.activeInteriorModel])

  // Load defect types.
  useEffect(() => {
    if (defectTypesQuery.data?.defectTypes[0]) {
      const defectTypesArray = defectTypesQuery.data.defectTypes

      const sortedDefectTypes = [...defectTypesArray].sort((a, b) => {
        return a.name < b.name ? -1 : 1
      })

      setDefectTypeList(sortedDefectTypes)

      for (const d of sortedDefectTypes) {
        if (d.id === props.selectedIssue.defectTypeID) {
          setFieldValue('defectTypeID', d.id)
          setDefectType(d)
        }

        for (const d2 of d.subTypes) {
          if (d2.id === props.selectedIssue.defectSubTypeID) {
            setFieldValue('defectSubTypeID', d2.id)
            setDefectSubType(d2)
            break
          }
        }
      }
    }
  }, [defectTypesQuery.data])

  // Load components.
  useEffect(() => {
    if (!site || !selectedAssetType) {
      return
    }

    let components: ComponentDtoT[] = []
    if (!view.assetID) {
      if (selectedAsset) {
        components = [...(selectedAsset.components as any)]
      } else {
        components = []
      }
    } else {
      if (asset.asset) {
        components = [...(asset.asset.components as ComponentDtoT[])]
      }
    }
    setComponents(components)

    const newSelectedComponentTypes: ComponentTypeDtoT[] = []

    function evaluateCT(c: ComponentTypeDtoT): boolean {
      if (c.id === props.selectedIssue.componentTypeID) {
        newSelectedComponentTypes.push(c)
        return true
      }
      const shouldAdd = (c.children || []).map(evaluateCT).reduce((a, b) => a || b, false)
      if (shouldAdd) {
        newSelectedComponentTypes.push(c)
      }

      return shouldAdd
    }

    selectedAssetType.componentTypes.forEach(evaluateCT)

    setSelectedComponentTypes(newSelectedComponentTypes.reverse())
    if (newSelectedComponentTypes.length > 0) {
      const last = newSelectedComponentTypes[newSelectedComponentTypes.length - 1]
      const mt = last.materialTypes.find(
        (x) => x.id === (props.selectedIssue.component?.materialTypeID || props.selectedIssue.materialTypeID)
      )
      setSelectedMaterialType(mt)
    }

    for (const c of components) {
      if (c.id === props.selectedIssue.component?.id) {
        setSelectedComponent(c)
        setFieldValue('componentTypeID', c.componentTypeID)
        break
      }

      for (const c2 of c.children) {
        if (c2.id === props.selectedIssue.component?.id) {
          setSelectedComponent(c2)
          setFieldValue('componentTypeID', c2.componentTypeID)
          break
        }

        for (const c3 of c.children) {
          if (c3.id === props.selectedIssue.component?.id) {
            setSelectedComponent(c3)
            setFieldValue('componentTypeID', c3.componentTypeID)
            break
          }
        }
      }
    }
  }, [site.site, asset.asset, selectedAsset, selectedAssetType])

  const assetTypeQuery = useQuery<GetAssetTypeByIdQuery, GetAssetTypeByIdQueryVariables>(ASSET_TYPE_BY_ID_QUERY, {
    fetchPolicy: 'standby',
    nextFetchPolicy: 'cache-and-network',
  })

  useEffect(() => {
    if (typeof selectedAsset?.assetType?.id !== 'string' && typeof props.selectedIssue?.assetTypeID !== 'string') {
      return
    }

    let id = props.selectedIssue?.assetTypeID
    if (view.assetID) {
      id = selectedAsset?.assetType?.id
    }

    assetTypeQuery
      .refetch({
        assetTypeID: id,
      })
      .then((res) => {
        setSelectedAssetType(res.data.assetTypeByID as AssetTypeDtoT)
        setFieldValue('assetTypeID', id)
      })
  }, [props.selectedIssue, selectedAsset])

  // Handle 3D clicks.
  useEffect(() => {
    if (map.projectionType !== ProjectionType.Projection3D || timeline.activeInteriorModel) {
      return
    }

    const onModelLeftClick = (pos: LeftClickModelPosition) => {
      const carto = Cesium.Cartographic.fromCartesian(pos.position)
      setFieldValue('location', {
        longitude: Cesium.Math.toDegrees(carto.longitude),
        latitude: Cesium.Math.toDegrees(carto.latitude),
        altitude: carto.height,
      })
    }

    map.addLeftClickModelHandler(onModelLeftClick)

    return () => {
      map.removeLeftClickModelHandler(onModelLeftClick)
    }
  }, [map.projectionType, timeline.activeInteriorModel])

  // Render 3D Marker.
  useEffect(() => {
    if (!values.location || timeline.activeInteriorModel) {
      return
    }

    const pos = Cesium.Cartographic.fromDegrees(
      values.location.longitude,
      values.location.latitude,
      values.location.altitude
    )
    const photo = imageTagger.photo
    const marker = map.addMarker(
      `tmp-issue-marker-${photo.imageID}`,
      [Cesium.Math.toDegrees(pos.longitude), Cesium.Math.toDegrees(pos.latitude), pos.height],
      undefined,
      null,
      16,
      '#fff',
      values.riskType === 'Low'
        ? MarkerIconType.IssueLow
        : values.riskType === 'Medium'
        ? MarkerIconType.IssueMedium
        : values.riskType === 'High'
        ? MarkerIconType.IssueHigh
        : MarkerIconType.IssueLow
    )

    return () => {
      map.removeMarker(marker)
    }
  }, [values.location, values.riskType, timeline.activeInteriorModel])

  useEffect(() => {
    if (!props.internalPoint) {
      return
    }
    const movement = timeline.activeInteriorModel.matterport
    const offset = Cesium.Cartesian3.fromRadians(
      Cesium.Math.toRadians(movement.originX) + movement.translationX,
      Cesium.Math.toRadians(movement.originY) + movement.translationY,
      movement.originZ + movement.translationZ
    )
    const hpr = Cesium.HeadingPitchRoll.fromDegrees(movement.rotateX, movement.rotateY, movement.rotateZ)
    const rotation = Cesium.Transforms.headingPitchRollQuaternion(offset, hpr)
    const scale = new Cesium.Cartesian3(movement.scaleX, movement.scaleY, movement.scaleZ)
    const transformationMatrix = Cesium.Matrix4.fromTranslationQuaternionRotationScale(offset, rotation, scale)
    const p = props.internalPoint
    const cartesian = new Cesium.Cartesian3(p.longitude, p.latitude, p.altitude)
    Cesium.Matrix4.multiplyByPoint(transformationMatrix, cartesian, cartesian)
    const pos = Cesium.Cartographic.fromCartesian(cartesian)

    setFieldValue('location', {
      latitude: (pos.latitude / Math.PI) * 180,
      longitude: (pos.longitude / Math.PI) * 180,
      altitude: pos.height,
    })
  }, [props.internalPoint])

  const formInvalid =
    imageTagger.addingPoints?.length < 3 ||
    !values.location ||
    values.location.altitude === 0 ||
    !values.assetTypeID ||
    !values.componentTypeID ||
    isSubmitting

  const formValidationMessage =
    imageTagger.addingPoints?.length < 3
      ? 'Polygon needs to be drawn on the image'
      : !values?.location || values.location?.altitude === 0
      ? 'A location needs to be selected on the 3D model'
      : !values.assetTypeID
      ? 'An asset or asset type needs to be selected'
      : !values.componentTypeID
      ? 'A component or component type needs to be selected'
      : ''

  const repairEstimate = getIssueEstimatedRepairCost(user, {
    priority: values.priority,
    repairEstimate: values.repairEstimate,
    defectCostToRepair: (defectSubType || defectType)?.costToRepair || 0,
    defectCostPriorityScalar: (defectSubType || defectType)?.costToRepair || 0,
  })

  return (
    <DrawerForm>
      <Helmet title='Update issue' />
      <DrawerFormBreadcrumbs>
        <DrawerFormBreadcrumbItem
          title={asset.asset?.name || site.site.name}
          onClick={() => {
            imageTagger.setAddingIssue(false)
          }}
        />
        <DrawerFormBreadcrumbItem title='Update issue' />
      </DrawerFormBreadcrumbs>
      <DrawerFormHeading
        title='Issue Details'
        helpText='Use your cursor to draw a boundary around the image and click on the 3D map to set the position of the issue.'
      />
      <DrawerFormContent>
        <form autoComplete='off' id='create-new-issue-form' name='create-new-issue-form' onSubmit={handleSubmit}>
          {!view.assetID && (
            <Select<SiteQueryAsset>
              id='asset'
              label='Asset'
              placeholder='Select asset'
              onChange={(selected) => {
                setSelectedAsset(selected)
                setFieldValue('assetID', selected?.id || '')
                setFieldValue('componentID', '')
                setSelectedComponent(undefined)
              }}
              canSelectGroup
              canSelectNone
              selectedValue={selectedAsset}
              options={timeline.activeSurvey?.assets.map((a) => {
                return {
                  id: a.id,
                  name: a.name,
                  value: a,
                  items: [],
                }
              })}
            />
          )}
          {(!view.assetID || typeof selectedAsset?.assetType?.id !== 'string') && (
            <AssetTypeSelect
              onChange={(s) => {
                setSelectedAssetType(s)
                setFieldValue('assetTypeID', s?.id || '')
              }}
              assetTypeID={selectedAssetType?.id}
              disabled={!!selectedAsset}
            />
          )}
          <Select<ComponentDtoT>
            id='component'
            label='Component'
            placeholder='Select component'
            onChange={(selected) => {
              setFieldValue('componentID', selected?.id || '')
              setFieldValue('componentTypeID', selected?.componentTypeID || '')
              setSelectedComponent(selected)
              if (selected && selectedAssetType) {
                const newSelectedComponentTypes: ComponentTypeDtoT[] = []

                const evaluateCT = (c: ComponentTypeDtoT): boolean => {
                  if (c.id === selected.componentTypeID) {
                    newSelectedComponentTypes.push(c)
                    return true
                  }
                  const shouldAdd = (c.children || []).map(evaluateCT).reduce((a, b) => a || b, false)
                  if (shouldAdd) {
                    newSelectedComponentTypes.push(c)
                  }

                  return shouldAdd
                }

                selectedAssetType.componentTypes.forEach(evaluateCT)

                setSelectedComponentTypes(newSelectedComponentTypes.reverse())
                if (newSelectedComponentTypes.length > 0) {
                  const last = newSelectedComponentTypes[newSelectedComponentTypes.length - 1]
                  const mt = last.materialTypes.find((x) => x.id === selected.materialTypeID)
                  setSelectedMaterialType(mt)
                }
              }
            }}
            canSelectGroup
            canSelectNone
            selectedValue={selectedComponent}
            options={(components as ComponentDtoT[]).map((c) => {
              return {
                id: c.id,
                name: c.materialTypeName === '' ? '' : c.materialTypeName + ' ' + (c.name || c.componentTypeName),
                value: c,
                items: c.children.map((child) => {
                  return {
                    id: child.id,
                    name: child.name || child.componentTypeName,
                    value: child,
                    items: child.children.map((subChild) => ({
                      id: subChild.id,
                      name: subChild.name || subChild.componentTypeName,
                      value: subChild,
                      items: [],
                    })),
                  }
                }),
              }
            })}
          />
          <ComponentTypeSelect
            componentTypes={selectedAssetType?.componentTypes || []}
            onChange={(selectedComponentTypes, selectedMaterialType) => {
              setSelectedComponentTypes(selectedComponentTypes)
              setSelectedMaterialType(selectedMaterialType)
              setFieldValue(
                'componentTypeID',
                selectedComponentTypes.length > 0 ? selectedComponentTypes[selectedComponentTypes.length - 1].id : ''
              )
            }}
            selectedComponentTypes={selectedComponentTypes}
            selectedMaterialType={selectedMaterialType}
            disabled={!!selectedComponent}
          />
          <PrioritySelect
            selectedValue={values.priority}
            onChange={(v) => {
              setFieldValue('priority', v)
            }}
          />
          <DefectTypeSelect
            defectType={defectType}
            defectSubType={defectSubType}
            defectTypes={defectTypeList}
            onChange={(d, s) => {
              setDefectType(d)
              setDefectSubType(s)
              setFieldValue('defectTypeID', d?.id || '')
              setFieldValue('defectSubTypeID', s?.id || '')
            }}
          />
          <RiskTypeSelect
            selectedValue={values.riskType}
            onChange={(v) => {
              setFieldValue('riskType', v)
            }}
          />
          <StatusSelect
            selectedValue={values.status}
            onChange={(v) => {
              setFieldValue('status', v)
            }}
          />
          <FloatingInput
            label='Repair Estimate'
            id='repairEstimate'
            type='number'
            value={values.repairEstimate === 0 ? undefined : values.repairEstimate}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={onfocus}
            helperText={''}
            prefix={getCurrencyPrefix(user)}
            placeholder={repairEstimate.cost / 100 + ''}
          />
          <div className='mb-3'>
            <FloatingTextArea
              label='Notes'
              id='notes'
              value={values.notes}
              onChange={handleChange}
              onBlur={handleBlur}
              onFocus={onfocus}
              helperText={''}
              rows={values.notes.split('\n').length + 3}
            />
          </div>
          {/* <OperationTypeSelect
            name='operationType'
            selectedValue={values.operationType}
            onChange={handleChange}
            survey={timeline.activeSurvey}
          /> */}
          {error && <div className='alert alert-warning ml-3 mr-3'>{error}</div>}
        </form>
      </DrawerFormContent>
      <DrawerFormButtons>
        {showFormValidationMessages && formInvalid && <div className='error'>{formValidationMessage}</div>}
        <DrawerFormSubmitButton
          form='create-new-issue-form'
          onClick={() => {
            if (formInvalid) {
              setShowFormValidationMessages(true)
            } else {
              submitForm()
            }
          }}
          disabled={showFormValidationMessages && formInvalid}
          title={formValidationMessage}
        >
          {isSubmitting ? 'Saving...' : 'Save'}
        </DrawerFormSubmitButton>
        <DrawerFormCancelButton
          onClick={() => {
            issues.setIssueToUpdate(undefined)
          }}
        >
          Cancel
        </DrawerFormCancelButton>
      </DrawerFormButtons>
    </DrawerForm>
  )
}
