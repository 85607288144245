import { useEffect, useState } from 'react'
import { PolygonColorMZ } from '~/components'
import { MonitoringZoneRenderState, ProjectionType, useAppState } from '~/state'
import { AnnotationType, SiteQuerySite } from '~/models'
import { PolygonRenderer3DView } from '~/components/polygon-renderer-3d'

export function useSiteMonitoringZones(site?: SiteQuerySite) {
  const { map, monitoringZoneState, timeline, asset, entityContext } = useAppState()
  const [polygonRenderers, setPolygonRenderers] = useState<PolygonRenderer3DView[]>([])

  const activeSurveyID = timeline.activeSurvey?.id
  const activeSurvey = site?.surveys?.find((s) => s.id === activeSurveyID)

  // 2D Monitoring Zones.
  useEffect(() => {
    if (!site || monitoringZoneState.renderState === MonitoringZoneRenderState.None) {
      return
    }

    if (map.morphing || (map.projectionType === ProjectionType.Projection3D && !monitoringZoneState.drawIn3D)) {
      return
    }
    if (map.morphing || (map.projectionType === ProjectionType.Projection2D && !monitoringZoneState.drawIn2D)) {
      return
    }

    const renderers: PolygonRenderer3DView[] = []
    if (
      activeSurveyID &&
      (monitoringZoneState.renderState === MonitoringZoneRenderState.Site ||
        monitoringZoneState.renderState === MonitoringZoneRenderState.SiteAndAsset ||
        monitoringZoneState.renderState === MonitoringZoneRenderState.SiteAndAssets) &&
      activeSurvey.monitoringZones.length > 0
    ) {
      for (const z of activeSurvey.monitoringZones) {
        const points = z.points.map((x) => ({
          ...x,
          altitude: map.projectionType === ProjectionType.Projection3D ? 10000 : 0,
        }))
        renderers.push(
          new PolygonRenderer3DView({
            points,
            color: PolygonColorMZ,
            label: z.name,
            renderPoints: monitoringZoneState.renderPoints,
            map,
            onClick: () => {
              entityContext.set(z)
            },
            annotationType: AnnotationType.Area,
            id: z.id,
            fill: false,
            clamp: map.projectionType === ProjectionType.Projection3D,
            scaleByDistance: new Cesium.NearFarScalar(10, 0.8, 40, 0.8),
            translucencyByDistance: new Cesium.NearFarScalar(120, 1, 180, 0),
          })
        )
      }
    }

    if (
      activeSurveyID &&
      (monitoringZoneState.renderState === MonitoringZoneRenderState.SiteAndAsset ||
        monitoringZoneState.renderState === MonitoringZoneRenderState.SiteAndAssets)
    ) {
      for (const asset of activeSurvey.assets) {
        for (const z of asset.monitoringZones) {
          const points = z.points.map((x) => ({
            ...x,
            altitude: map.projectionType === ProjectionType.Projection3D ? 10000 : 0,
          }))
          renderers.push(
            new PolygonRenderer3DView({
              points,
              color: PolygonColorMZ,
              label: z.name,
              renderPoints: monitoringZoneState.renderPoints,
              map,
              onClick: () => {
                entityContext.set(z)
              },
              annotationType: AnnotationType.Area,
              id: z.id,
              fill: false,
              clamp: map.projectionType === ProjectionType.Projection3D,
              scaleByDistance: new Cesium.NearFarScalar(10, 0.8, 40, 0.8),
              translucencyByDistance: new Cesium.NearFarScalar(120, 1, 180, 0),
            })
          )
        }
      }
    }

    setPolygonRenderers(renderers)

    return () => {
      renderers.forEach((r) => r.destroy())
      setPolygonRenderers([])
    }
  }, [
    site?.id,
    map.projectionType,
    monitoringZoneState.renderState,
    activeSurvey?.monitoringZones.length,
    activeSurveyID,
    asset.asset?.monitoringZones.length,
    monitoringZoneState.drawIn3D,
    monitoringZoneState.drawIn2D,
    map.morphing,
  ])

  // Draw selected polygon
  useEffect(() => {
    if (
      map.morphing ||
      !monitoringZoneState.selectedMonitoringZone ||
      monitoringZoneState.monitoringZoneToEdit ||
      !activeSurvey?.monitoringZones
    ) {
      return
    }

    const sel = activeSurvey.monitoringZones.find((x) => x.id === monitoringZoneState.selectedMonitoringZone.id)
    if (!sel) {
      return
    }

    const pr = new PolygonRenderer3DView({
      id: sel.id + '-selected',
      label: sel.name,
      renderPoints: monitoringZoneState.renderPoints,
      points: sel.points.map((x) => ({
        ...x,
        altitude: map.projectionType === ProjectionType.Projection3D ? 10000 : 0,
      })),
      map,
      color: '#ef7c10',
      annotationType: AnnotationType.Area,
      fill: true,
      onClick: () => {
        entityContext.clear()
      },
      clamp: map.projectionType === ProjectionType.Projection3D,
      scaleByDistance: new Cesium.NearFarScalar(10, 0.8, 40, 0.8),
      translucencyByDistance: new Cesium.NearFarScalar(120, 1, 180, 0),
    })

    const existingPrIndex = polygonRenderers.findIndex((p) => p.id === monitoringZoneState.selectedMonitoringZone.id)
    if (existingPrIndex !== -1) {
      polygonRenderers[existingPrIndex].setVisible(false)
    }

    return () => {
      pr.destroy()
      if (existingPrIndex !== -1) {
        polygonRenderers[existingPrIndex].setVisible(true)
      }
    }
  }, [
    monitoringZoneState.selectedMonitoringZone?.id,
    monitoringZoneState.monitoringZoneToEdit?.id,
    map.projectionType,
    map.morphing,
    activeSurvey?.monitoringZones,
  ])
}
