import { useEffect, useState } from 'react'
import { SiteQueryAsset, SiteQuerySurvey } from '~/models'

export function useAsset(assetID?: string, activeSurvey?: SiteQuerySurvey) {
  const [asset, setAsset] = useState<SiteQueryAsset>()

  useEffect(() => {
    if (!activeSurvey) {
      if (asset) {
        setAsset(undefined)
      }
      return
    }

    setAsset(activeSurvey?.assets?.find((a) => a.id === assetID))
  }, [assetID, activeSurvey])

  return {
    asset,
    error: false,
  }
}
