mutation createComponent($input: ComponentCreateRequestT!) {
  componentCreate(input: $input) {
    id
    name
    assetID
    componentTypeID
    componentTypeName
    componentTypeFullName
    materialTypeID
    materialTypeName
    costPerUnit
    labourPerHour
    measurement
    condition
    isInternal
    points {
      latitude
      longitude
      altitude
    }
    internalPoints {
      latitude
      longitude
      altitude
    }
    children {
      id
      name
      componentTypeID
      componentTypeName
      componentTypeFullName
      materialTypeID
      materialTypeName
      costPerUnit
      labourPerHour
      measurement
      condition
      isInternal
      points {
        latitude
        longitude
        altitude
      }
      internalPoints {
        latitude
        longitude
        altitude
      }
    }
  }
}
