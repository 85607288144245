mutation signInWith2FAToken($input: SignInWith2FATokenRequestT!) {
  signInWith2FAToken(input: $input) {
    token
    firstName
    lastName
    email
    phoneNumber
    systemRole
    userId
    currentOrgId
    currentSupplierID
    orgs {
      id
      name
      role
      domainName
      requiresSubscription
      canManageAdvancedAccess
      canAccessMatterportIntegration
      hasMatterportAuth
      canAccessCesiumIntegration
      canAccessDeflections
      hasCesiumAuth
      requires2FA
      currency
      isBasic
      isIA
    }
    suppliers {
      id
      name
      role
      requires2FA
    }
    policy
    showDemoDashboards
    showDemoSites
    dataKeyID
    dataPolicy
    dataSignature
    requires2FA
    waitFor2FA
    orgPendingApproval
    supplierPendingApproval
  }
}
