import React, { useEffect, useState } from 'react'
import { FloatingInput, OperationTypeSelect, Select } from '~/components'
import { useComponentView } from '~/components/components2/use-component-view'
import { SiteQueryAsset, AnnotationDtoT, AnnotationType, SurveyBasedOperation } from '~/models'
import { ProjectionType, useAppState } from '~/state'
import { useAnnotationsCreate, useAnnotationsUpdate } from './data'
import { AnnotationsFormCreateArea } from './annotations-form-create-area'
import { AnnotationsFormCreateDistance } from './annotations-form-create-distance'
import { AnnotationsFormCreateGroundEavesRidge } from './annotations-form-create-ground-eaves-ridge'
import { AnnotationsFormCreateLengthWidth } from './annotations-form-create-length-width'
import { AnnotationsModel } from './annotations-form-create-props'
import { AnnotationsFormCreateVolume } from './annotations-form-create-volume'
import { AnnotationTypeSelect } from './annotations-type-select'
import { AnnotationsFormCreatePoint } from './annotations-form-create-point'

interface AnnotationsFormCreateProps {
  onComplete: (changed: boolean, hide: boolean) => void
  existing?: AnnotationDtoT
}

export const AnnotationsFormCreate = (props: AnnotationsFormCreateProps) => {
  useComponentView()
  const { timeline, view, map, components } = useAppState()
  const [annotationType, setAnnotationType] = useState<AnnotationType>(
    props.existing?.type || AnnotationType.LengthWidth
  )
  const [operationType, setOperationType] = useState<SurveyBasedOperation>(SurveyBasedOperation.Singular)
  const [selectedAsset, setSelectedAsset] = useState<SiteQueryAsset>(
    timeline.activeSurvey.assets.find((a) => a.id === (props.existing ? props.existing?.assetID : view.assetID))
  )
  const [nameFocused, setNameFocused] = useState(false)

  const [saving, setSaving] = useState(false)
  const [error, setError] = useState<string>()
  const [valid, setValid] = useState(false)
  const [model, setModel] = useState<AnnotationsModel>({
    name: props.existing?.name || '',
    measurements: props.existing?.measurements || [],
    points: props.existing?.points || [],
  })

  useEffect(() => {
    components.setHidden(true)
    return () => {
      components.setHidden(false)
    }
  }, [])

  useEffect(() => {
    if (!model) {
      setValid(false)
      return
    }

    if (annotationType === AnnotationType.Point) {
      if (!Array.isArray(model.points) || model.points.length !== 1) {
        setValid(false)
        return
      }

      if (!Array.isArray(model.measurements) || model.measurements.length !== 0) {
        setValid(false)
        return
      }

      if (typeof model.name !== 'string' || model.name === '') {
        setValid(false)
        return
      }

      setValid(true)
    } else if (annotationType === AnnotationType.Distance) {
      if (!Array.isArray(model.points) || model.points.length < 2) {
        setValid(false)
        return
      }

      if (!Array.isArray(model.measurements) || model.measurements.length !== 1) {
        setValid(false)
        return
      }

      setValid(true)
    } else if (annotationType === AnnotationType.Area) {
      if (!Array.isArray(model.points) || model.points.length < 3) {
        setValid(false)
        return
      }

      if (!Array.isArray(model.measurements) || model.measurements.length !== 1) {
        setValid(false)
        return
      }

      setValid(true)
    } else if (annotationType === AnnotationType.Volume) {
      if (!Array.isArray(model.points) || model.points.length < 3) {
        setValid(false)
        return
      }

      if (!Array.isArray(model.measurements) || model.measurements.length !== 1) {
        setValid(false)
        return
      }

      setValid(true)
    } else if (annotationType === AnnotationType.GroundEavesRidge) {
      if (!Array.isArray(model.points) || model.points.length != 4) {
        setValid(false)
        return
      }

      if (!Array.isArray(model.measurements) || model.measurements.length !== 2) {
        setValid(false)
        return
      }

      setValid(true)
    } else if (annotationType === AnnotationType.LengthWidth) {
      if (!Array.isArray(model.points) || model.points.length < 3) {
        setValid(false)
        return
      }

      if (!Array.isArray(model.measurements) || model.measurements.length !== 2) {
        setValid(false)
        return
      }

      setValid(true)
    }
  }, [model])

  const [createMutation] = useAnnotationsCreate()
  const [updateMutation] = useAnnotationsUpdate()

  const doSave = () => {
    setSaving(true)

    let promise = props.existing
      ? updateMutation({
          variables: {
            input: {
              assetID: selectedAsset?.id || view.assetID || '',
              measurements: model.measurements,
              name: model.name || '',
              operationType,
              points: model.points,
              siteID: view.siteID,
              surveyID: view.surveyID,
              type: annotationType,
              AnnotationID: props.existing.id,
            },
          },
        })
      : createMutation({
          variables: {
            input: {
              assetID: selectedAsset?.id || view.assetID || '',
              measurements: model.measurements,
              name: model.name || '',
              operationType,
              points: model.points,
              siteID: view.siteID,
              surveyID: view.surveyID,
              type: annotationType,
            },
          },
        })

    return promise
      .then(() => {
        setError(undefined)
      })
      .catch((e) => {
        setError(e)
      })
      .finally(() => {
        setSaving(false)
      })
  }

  const save = () => {
    doSave().then(() => {
      props.onComplete(true, true)
    })
  }

  const saveAndAdd = () => {
    return doSave().then(() => {
      props.onComplete(true, false)
      setModel({
        measurements: [],
        points: [],
      })
    })
  }

  useEffect(() => {
    if (saving) {
      return
    }

    function onKeyDown(e: KeyboardEvent) {
      const setAsset = (a: SiteQueryAsset) => {
        setSelectedAsset(a)

        const points: Cesium.Cartographic[] = []

        for (const boundary of a.boundaries) {
          for (const p of boundary.points) {
            points.push(
              Cesium.Cartographic.fromDegrees(p.longitude, p.latitude, map.viewer.camera.positionCartographic.height)
            )
          }
        }

        if (points.length > 0) {
          const destinationCarto = Cesium.Rectangle.center(Cesium.Rectangle.fromCartographicArray(points))
          const samplePositions = [destinationCarto]
          Cesium.sampleTerrain(map.viewer.terrainProvider, 11, samplePositions).then(() => {
            samplePositions[0].height += 30

            map.viewer.camera.flyTo({
              destination: Cesium.Cartographic.toCartesian(samplePositions[0]),
              duration: 0.75,
            })
          })
        }
      }

      const key = e.key.toLocaleLowerCase()
      if (valid && key === 'enter') {
        saveAndAdd().then(() => {
          if (selectedAsset) {
            const assetIndex = timeline.activeSurvey.assets.findIndex((a) => a.id === selectedAsset.id)
            if (assetIndex === timeline.activeSurvey.assets.length - 1) {
              setAsset(timeline.activeSurvey.assets[0])
            } else {
              setAsset(timeline.activeSurvey.assets[assetIndex + 1])
            }
          } else {
            if (timeline.activeSurvey.assets.length > 0) {
              setAsset(timeline.activeSurvey.assets[0])
            }
          }
        })
      } else if (key === 'n') {
        if (selectedAsset) {
          const assetIndex = timeline.activeSurvey.assets.findIndex((a) => a.id === selectedAsset.id)
          if (assetIndex === timeline.activeSurvey.assets.length - 1) {
            setAsset(timeline.activeSurvey.assets[0])
          } else {
            setAsset(timeline.activeSurvey.assets[assetIndex + 1])
          }
        } else {
          if (timeline.activeSurvey.assets.length > 0) {
            setAsset(timeline.activeSurvey.assets[0])
          }
        }
      } else if (key === 'p') {
        if (selectedAsset) {
          const assetIndex = timeline.activeSurvey.assets.findIndex((a) => a.id === selectedAsset.id)
          if (assetIndex === 0) {
            setAsset(timeline.activeSurvey.assets[timeline.activeSurvey.assets.length - 1])
          } else {
            setAsset(timeline.activeSurvey.assets[assetIndex - 1])
          }
        } else {
          if (timeline.activeSurvey.assets.length > 0) {
            setAsset(timeline.activeSurvey.assets[0])
          }
        }
      }
    }

    if (!nameFocused) {
      window.addEventListener('keydown', onKeyDown, false)
    }

    return () => {
      if (!nameFocused) {
        window.removeEventListener('keydown', onKeyDown)
      }
    }
  }, [nameFocused, valid, saving, selectedAsset])

  const rulerProps = {
    model,
    setModel,
    asset: selectedAsset,
    setAsset: setSelectedAsset,
    nameFocused,
  }

  const is3D = map.projectionType === ProjectionType.Projection3D

  return (
    <div className='components-draw'>
      <div className='drawer-panel'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  if (!saving) {
                    props.onComplete(false, true)
                  }
                }}
              >
                Site
              </a>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              Annotation
            </li>
          </ol>
        </nav>
        <div className='drawer-panel-title-container'>
          <h6 className='drawer-panel-title'>{props.existing ? 'Update' : 'Create'} Annotation</h6>
        </div>
        <div className='drawer-panel-form-container'>
          <Select<SiteQueryAsset>
            id='asset'
            label='Asset'
            placeholder='Select asset'
            onChange={(selected) => {
              setSelectedAsset(selected)
            }}
            canSelectGroup
            canSelectNone
            selectedValue={selectedAsset}
            options={timeline.activeSurvey?.assets
              .map((a) => {
                return {
                  id: a.id,
                  name: a.name,
                  value: a,
                  items: [],
                }
              })
              .sort((a, b) => (a.name > b.name ? 1 : -1))}
          />
          <AnnotationTypeSelect
            selectedValue={annotationType}
            onChange={(t) => {
              setAnnotationType(t)
              setModel({
                ...model,
                measurements: [],
                points: [],
              })
            }}
          />
          <FloatingInput
            label={'Name' +(annotationType === AnnotationType.Point ? '' : ' (Optional)')}
            id='name'
            value={model.name}
            onChange={(e) =>
              setModel({
                ...model,
                name: e.target.value,
              })
            }
            onFocus={() => setNameFocused(true)}
            onBlur={() => setNameFocused(false)}
          />
          {is3D && annotationType === AnnotationType.Distance && <AnnotationsFormCreateDistance {...rulerProps} />}
          {is3D && annotationType === AnnotationType.Area && <AnnotationsFormCreateArea {...rulerProps} />}
          {is3D && annotationType === AnnotationType.Volume && <AnnotationsFormCreateVolume {...rulerProps} />}
          {is3D && annotationType === AnnotationType.GroundEavesRidge && (
            <AnnotationsFormCreateGroundEavesRidge {...rulerProps} />
          )}
          {is3D && annotationType === AnnotationType.LengthWidth && (
            <AnnotationsFormCreateLengthWidth {...rulerProps} />
          )}
          {is3D && annotationType === AnnotationType.Point && <AnnotationsFormCreatePoint {...rulerProps} />}
          <OperationTypeSelect
            name='operationType'
            survey={timeline.activeSurvey}
            selectedValue={operationType}
            onChange={(e) => setOperationType((e.target as any).value)}
          />
          <div className='drawer-panel-form-container-action-container'>
            {error && <div className='error'>{JSON.stringify(error, null, 2)}</div>}
            <button type='submit' className='btn submit mb-2' disabled={!valid || saving} onClick={save}>
              Save
            </button>
            {!props.existing && (
              <button type='submit' className='btn submit mb-2' disabled={!valid || saving} onClick={saveAndAdd}>
                Save &amp; Add
              </button>
            )}
            <button className='btn cancel' disabled={saving} onClick={() => props.onComplete(false, true)}>
              Cancel
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  )
}
