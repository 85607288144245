import React, { useState } from 'react'
import ASSETI_LOGO from '../../images/asseti-logo.svg'
import { OperationTypeSelect, useMutation } from '~/components'
import { ImportBoundaryMutation, ImportBoundaryMutationVariables, SurveyBasedOperation } from '~/models'
import IMPORT_BOUNDARY from './mutation-boundary-import.gql'
import { useAppState } from '~/state'

interface BoundariesMultipleModalProps {
  boundaries: Array<Cesium.Cartesian3>[]
  onComplete: () => Promise<any>
  onCancel: () => void
}

export const BoundariesMultipleModal = (props: BoundariesMultipleModalProps) => {
  const [importMutation] = useMutation<ImportBoundaryMutation, ImportBoundaryMutationVariables>(IMPORT_BOUNDARY)
  const [loading, setLoading] = useState(false)
  const [operationType, setOperationType] = useState(SurveyBasedOperation.GoingForwardsAndBackwards)
  const { view, timeline } = useAppState()

  return (
    <div className='requires-subscription-popup'>
      <div className='requires-subscription-popup-content'>
        <div className='requires-subscription-popup-content-header'>
          <img src={ASSETI_LOGO} alt='Asseti Logo' /> <span>Asseti</span>
        </div>
        <div className='requires-subscription-popup-content-text'>
          <h5>
            Multiple boundaries detected in uploaded file.
            <br />
            <br /> Do you want to import all of them?
            <br />
            <br />
            <OperationTypeSelect
              name='operationType'
              selectedValue={operationType}
              onChange={(o) => setOperationType(o.target)}
              survey={timeline.activeSurvey}
            />
          </h5>
        </div>
        <div className='requires-subscription-popup-content-buttons'>
          <button className='btn btn-secondary' onClick={props.onCancel} disabled={loading}>
            No
          </button>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              e.preventDefault()
              setLoading(true)
              importMutation({
                variables: {
                  input: {
                    siteID: view.siteID,
                    surveyID: view.surveyID,
                    assetID: view.assetID,
                    operationType: SurveyBasedOperation.GoingForwardsAndBackwards,
                    boundaries: props.boundaries.map((b) => {
                      return {
                        points: b.map((p) => {
                          const pos = Cesium.Cartographic.fromCartesian(p)
                          return {
                            latitude: (pos.latitude / Math.PI) * 180,
                            longitude: (pos.longitude / Math.PI) * 180,
                          }
                        }),
                      }
                    }),
                  },
                },
              })
                .then(() => {
                  return props.onComplete()
                })
                .finally(() => {
                  setLoading(false)
                })
            }}
            disabled={loading}
          >
            {loading ? 'Importing...' : 'Yes'}
          </button>
          <br />
          <br />
        </div>
      </div>
      <div className='requires-subscription-popup-overlay'></div>
    </div>
  )
}
