import { OrganizationFullQuery, UserInvitationStatus } from '~/models'
import React, { useState } from 'react'
import { getTracking, SimpleBar, useQuery } from '~/components'
import { PersonRow } from './people-person-row'
import { TeamInviteModal } from './team-invite-modal'
import { ClientPermission, useUser } from '~/base'
import ORG_QUERY from './query-org-full.gql'
import { useAppState } from '~/state'

export const People = () => {
  const user = useUser()
  const [inviteModal, setInviteModal] = useState<boolean>(false)
  const [addingTeamLoading, setAddingTeamLoading] = useState<boolean>(false)
  const {sites} = useAppState()

  const query = useQuery<OrganizationFullQuery>(ORG_QUERY)

  const showLoading = query.loading
  const showError = !query.loading && query.error
  const showPeople = !showLoading && !showError

  const canInvite = user.hasPermission(ClientPermission.TeamInvite)

  if(!sites.data) {
    return null
  }

  return (
    <div className='people-tab-content'>
      {canInvite && (
        <button
          className='people-tab-content-btn btn'
          onClick={() => {
            setInviteModal(true)
            getTracking().event({
              category: 'Modal',
              action: `User opened team invite modal`,
              label: 'Team',
            })
          }}
        >
          Invite
        </button>
      )}
      <SimpleBar>
        <ul className='people-tab-content-list'>
          {showLoading && (
            <>
              <PersonRow reload={query.refetch} />
              <PersonRow reload={query.refetch} />
              <PersonRow reload={query.refetch} />
            </>
          )}
          {showError && (
            <div>
              <div>An error occurred while loading team members</div>
              <br />
              <button
                className='profile-form-btn btn'
                onClick={() => {
                  query.refetch()
                }}
              >
                Retry
              </button>
            </div>
          )}

          {showPeople &&
            query.data.organizationFull.users.map((user) => (
              <PersonRow key={user.user.id} user={user} reload={query.refetch} />
            ))}
          {showPeople &&
            query.data.organizationFull.invites
              .filter((i) => i.status === UserInvitationStatus.Pending && i.id !== '01EWCHQV6BDTW05Z0H3CYH0A9M')
              .map((invite) => <PersonRow key={invite.id} invite={invite} reload={query.refetch} />)}
        </ul>
        <div style={{height: '200px'}}></div>
      </SimpleBar>
      <TeamInviteModal
        inviteModal={inviteModal}
        addingTeamLoading={addingTeamLoading}
        changeTeamLoading={setAddingTeamLoading}
        reload={() => {
          query.refetch().finally(() => {
            setAddingTeamLoading(false)
          })
        }}
        close={() => {
          setAddingTeamLoading(false)
          setInviteModal(false)
        }}
      />
    </div>
  )
}
