query imagesByModel($input: QueryImagesByModelRequestT!) {
  queryImagesByModel(input: $input) {
    images {
      imageID
      hash
      latitude
      longitude
      altitude
      yaw
      pitch
      roll
      dateTaken
      qx
      qy
      qz
      qw
      panoSet
      panoIndex
    }
  }
}