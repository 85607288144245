import React, { useState } from 'react'
import { FloatingInput, useMutation } from '~/components'
import { CesiumAuthMutation, CesiumAuthMutationVariables } from '~/models'
import ASSETI_LOGO from '../images/asseti-logo.svg'
import { useToasts } from '~/components'
import CESIUM_AUTH_MUTATION from './mutation-cesium-auth.gql'

interface CesiumSetTokenModalProps {
  onComplete: () => Promise<any>
  onCancel: () => void
}

export const CesiumSetTokenModal = (props: CesiumSetTokenModalProps) => {
  const [setTokenMutation] = useMutation<CesiumAuthMutation, CesiumAuthMutationVariables>(CESIUM_AUTH_MUTATION)
  const [loading, setLoading] = useState(false)
  const toasts = useToasts()

  const [token, setToken] = useState<string>('')

  return (
    <div className='requires-subscription-popup'>
      <div className='requires-subscription-popup-content matterport'>
        <div className='requires-subscription-popup-content-header'>
          <img src={ASSETI_LOGO} alt='Asseti Logo' /> <span>Asseti</span>
        </div>
        <div className='requires-subscription-popup-content-text'>
          <h5>Enter your Cesium Ion Access Token</h5>
          <FloatingInput
            id='token'
            label='Token'
            placeholder='Token'
            onChange={(e) => {
              setToken(e.target.value)
            }}
            value={token}
          />
          <h6>
            Click{' '}
            <a href='https://cesium.com/learn/ion/cesium-ion-access-tokens/' target='_blank'>
              here
            </a>{' '}
            to learn how to create an access token
          </h6>
        </div>
        <div className='requires-subscription-popup-content-buttons'>
          <button className='btn btn-secondary' onClick={props.onCancel} disabled={loading}>
            Cancel
          </button>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              e.preventDefault()
              setLoading(true)
              setTokenMutation({
                variables: {
                  input: {
                    token,
                  },
                },
              })
                .then(() => {
                  toasts.addTopLeft('Cesium Ion access token successfully saved')
                  return props.onComplete()
                })
                .finally(() => {
                  setLoading(false)
                  props.onCancel()
                })
            }}
            disabled={loading || typeof token !== 'string' || token.length < 10}
          >
            {loading ? 'Saving...' : 'Save'}
          </button>
        </div>
      </div>
      <div className='requires-subscription-popup-overlay'></div>
    </div>
  )
}
