import { useMutation, getTracking } from '~/components'
import React, { useEffect, useMemo, useState } from 'react'
import { ClientPermission } from '~/base'
import {
  OrganizationFullQuery,
  OrganizationRole,
  SitesQuerySiteGroupWithExtra,
  SitesQuerySiteWithExtra,
  UserSetPolicyMutation,
  UserSetPolicyMutationVariables,
} from '~/models'
import { Checkbox } from './advanced-access-tables'
import SET_USER_ACCESS from './mutation-user-set-policy.gql'
import { useAppState } from '~/state'
import { SiteAccessTable } from './site-access-table'
import { GroupAccessTable } from './group-access-table'

interface AdvancedAccessTableFullProps {
  user: OrganizationFullQuery['organizationFull']['users'][0]
  sites: OrganizationFullQuery['organizationFull']['sites']
  guestPolicy: string[]
  memberPolicy: string[]
  adminPolicy: string[]
  canSeeMatterport: boolean
  canSeeCesium: boolean
  canSeeDeflections: boolean
}

export const AdvancedAccessTableUser = (props: AdvancedAccessTableFullProps) => {
  const [executeSetPolicy] = useMutation<UserSetPolicyMutation, UserSetPolicyMutationVariables>(SET_USER_ACCESS)
  const [saving, setSaving] = useState<boolean>(false)
  const [saved, setSaved] = useState<boolean>(false)

  const [userPolicy, setUserPolicy] = useState<string[]>(['0', '0', '0', '0'])
  const { sites } = useAppState()

  const sitesToUse: SitesQuerySiteWithExtra[] = useMemo(() => {
    if (!sites?.data?.sites) {
      return undefined
    }

    return sites.sites.filter((s) => !s.s.isDemoSite)
  }, [sites?.data?.sites, sites.summaryQuery.data])

  const [excludedSites, setExcludedSites] = useState<SitesQuerySiteWithExtra[]>([])
  const [includedSites, setIncludedSites] = useState<SitesQuerySiteWithExtra[]>([])
  const [excludedGroups, setExcludedGroups] = useState<SitesQuerySiteGroupWithExtra[]>([])
  const [includedGroups, setIncludedGroups] = useState<SitesQuerySiteGroupWithExtra[]>([])

  useEffect(() => {
    const p = props.user.policy
    if (!Array.isArray(p) || p.length !== 4 || p.filter((x) => x === '0').length === 4) {
      if (props.user.role === OrganizationRole.AccountOwner || props.user.role === OrganizationRole.Admin) {
        setUserPolicy(props.adminPolicy)
      } else if (props.user.role === OrganizationRole.Member) {
        setUserPolicy(props.memberPolicy)
      } else if (props.user.role === OrganizationRole.Guest) {
        setUserPolicy(props.guestPolicy)
      }
    } else {
      setUserPolicy(p)
    }

    const allSites = sites.sortSites(sitesToUse)
    setExcludedSites(allSites.filter((s) => props.user.whitelistedSiteIDs.findIndex((x) => x === s.s.id) === -1))
    setIncludedSites(allSites.filter((s) => props.user.whitelistedSiteIDs.findIndex((x) => x === s.s.id) !== -1))

    const allGroups = sites.sortGroups(sites.groups)
    setExcludedGroups(allGroups.filter((s) => props.user.whitelistedGroupIDs.findIndex((x) => x === s.group.id) === -1))
    setIncludedGroups(allGroups.filter((s) => props.user.whitelistedGroupIDs.findIndex((x) => x === s.group.id) !== -1))
  }, [props.guestPolicy, props.memberPolicy, props.adminPolicy, props.user.policy])

  return (
    <>
      <table className='advanced-access-table'>
        <thead>
          <tr>
            <th>Permission</th>
            <th>View</th>
            <th>Create</th>
            <th>Update</th>
            <th>Remove</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Sites</td>
            <td></td>
            <Checkbox policy={userPolicy} permission={ClientPermission.SitesCreate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.SitesUpdate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.SitesRemove} setPolicy={setUserPolicy} />
          </tr>
          <tr>
            <td>Assets</td>
            <td></td>
            <Checkbox policy={userPolicy} permission={ClientPermission.AssetsCreate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.AssetsUpdate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.AssetsRemove} setPolicy={setUserPolicy} />
          </tr>
          <tr>
            <td>Boundaries</td>
            <td></td>
            <Checkbox policy={userPolicy} permission={ClientPermission.BoundariesCreate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.BoundariesUpdate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.BoundariesRemove} setPolicy={setUserPolicy} />
          </tr>
          <tr>
            <td>Monitoring Zones</td>
            <td></td>
            <Checkbox
              policy={userPolicy}
              permission={ClientPermission.MonitoringZonesCreate}
              setPolicy={setUserPolicy}
            />
            <Checkbox
              policy={userPolicy}
              permission={ClientPermission.MonitoringZonesUpdate}
              setPolicy={setUserPolicy}
            />
            <Checkbox
              policy={userPolicy}
              permission={ClientPermission.MonitoringZonesRemove}
              setPolicy={setUserPolicy}
            />
          </tr>
          <tr>
            <td>Issues</td>
            <Checkbox policy={userPolicy} permission={ClientPermission.IssuesList} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.IssuesCreate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.IssuesUpdate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.IssuesRemove} setPolicy={setUserPolicy} />
          </tr>
          <tr>
            <td>Components</td>
            <Checkbox policy={userPolicy} permission={ClientPermission.ComponentsRead} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.ComponentsCreate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.ComponentsUpdate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.ComponentsRemove} setPolicy={setUserPolicy} />
          </tr>
          {props.canSeeDeflections && (
            <tr>
              <td>Analysis</td>
              <Checkbox policy={userPolicy} permission={ClientPermission.AnalysisRead} setPolicy={setUserPolicy} />
              <Checkbox policy={userPolicy} permission={ClientPermission.AnalysisCreate} setPolicy={setUserPolicy} />
              <Checkbox policy={userPolicy} permission={ClientPermission.AnalysisUpdate} setPolicy={setUserPolicy} />
              <Checkbox policy={userPolicy} permission={ClientPermission.AnalysisRemove} setPolicy={setUserPolicy} />
            </tr>
          )}
          <tr>
            <td>Dashboards</td>
            <Checkbox policy={userPolicy} permission={ClientPermission.DashboardsList} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.DashboardsCreate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.DashboardsUpdate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.DashboardsRemove} setPolicy={setUserPolicy} />
          </tr>
          <tr>
            <td>Team Members</td>
            <Checkbox policy={userPolicy} permission={ClientPermission.TeamList} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.TeamInvite} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.TeamUpdate} setPolicy={setUserPolicy} />
            <Checkbox policy={userPolicy} permission={ClientPermission.TeamRemove} setPolicy={setUserPolicy} />
          </tr>
          <tr>
            <td>Recent Activity</td>
            <Checkbox policy={userPolicy} permission={ClientPermission.ActivityList} setPolicy={setUserPolicy} />
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
        <thead>
          <tr>
            <th>Permission</th>
            <th>Manage</th>
            <th>Upload</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Workspace &amp; Advanced Access</td>
            <Checkbox policy={userPolicy} permission={ClientPermission.ManageOrg} setPolicy={setUserPolicy} />
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Site Groups</td>
            <Checkbox policy={userPolicy} permission={ClientPermission.ManageSiteGroups} setPolicy={setUserPolicy} />
            <td></td>
            <td></td>
            <td></td>
          </tr>
          <tr>
            <td>Data</td>
            <td></td>
            <Checkbox policy={userPolicy} permission={ClientPermission.DataUpload} setPolicy={setUserPolicy} />
            <td></td>
            <td></td>
          </tr>
          {!props.canSeeMatterport && (
            <tr>
              <td>Matterport Integration</td>
              <Checkbox policy={userPolicy} permission={ClientPermission.MatterportAuth} setPolicy={setUserPolicy} />
              <Checkbox policy={userPolicy} permission={ClientPermission.MatterportCreate} setPolicy={setUserPolicy} />
              <td></td>
              <td></td>
            </tr>
          )}
          {!props.canSeeCesium && (
            <tr>
              <td>Cesium Integration</td>
              <Checkbox policy={userPolicy} permission={ClientPermission.CesiumAuth} setPolicy={setUserPolicy} />
              <Checkbox policy={userPolicy} permission={ClientPermission.CesiumCreate} setPolicy={setUserPolicy} />
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </table>

      <br />
      <br />

      <GroupAccessTable
        includedGroups={includedGroups}
        setIncludedGroups={setIncludedGroups}
        excludedGroups={excludedGroups}
        setExcludedGroups={setExcludedGroups}
      />

      <br />
      <br />

      <SiteAccessTable
        includedSites={includedSites}
        setIncludedSites={setIncludedSites}
        excludedSites={excludedSites}
        setExcludedSites={setExcludedSites}
      />

      <br />
      <br />

      <button
        className='settings-tab-content-btn btn'
        type='button'
        disabled={saving || saved}
        onClick={() => {
          getTracking().event({
            category: 'Button',
            action: `User clicked org advanced access user save button`,
            label: 'Advanced Access User Save',
          })

          setSaving(true)
          executeSetPolicy({
            variables: {
              input: {
                userID: props.user.user.id,
                policy: userPolicy,
                whitelistedSiteIDs: includedSites.map((s) => s.s.id),
                whitelistedGroupIDs: includedGroups.map((s) => s.group.id),
              },
            },
          })
            .then((res) => {
              setUserPolicy(res.data.userSetPolicy.policy)
              setSaved(true)
              setTimeout(() => {
                setSaved(false)
              }, 3000)
            })
            .finally(() => {
              setSaving(false)
            })
        }}
      >
        {saving ? 'Saving...' : saved ? 'Saved' : 'Save'}
      </button>
    </>
  )
}
