import { kml } from '@tmcw/togeojson'

export function kmlToPolygon(xml: string): Array<Cesium.Cartesian3[]> {
  const allCoords = []
  const geoJSON = kml(new DOMParser().parseFromString(xml, 'text/xml'))
  for (const feature of geoJSON.features) {
    if (feature.geometry.type === 'Polygon') {
      const coords = feature.geometry.coordinates[0]
      allCoords.push(coords.map((c) => Cesium.Cartesian3.fromDegrees(c[0], c[1], 0)))
    } else if (feature.geometry.type === 'LineString') {
      const coords = feature.geometry.coordinates
      allCoords.push(coords.map((c) => Cesium.Cartesian3.fromDegrees(c[0], c[1], 0)))
    }
  }

  return allCoords
}
