import React, { useState } from 'react'
import { useMutation } from '~/components'
import {
  MatterportModelsQuery,
  MatterportCreateMutation,
  MatterportCreateMutationVariables,
  SitesQuerySite,
} from '~/models'
import ASSETI_LOGO from '../images/asseti-logo.svg'
import { Select, useToasts } from '~/components'
import { useAppState } from '~/state'
import MATTERPORT_CREATE_MUTATION from './mutation-matterport-create.gql'

interface MatterportProcessModalProps {
  model: MatterportModelsQuery['matterportModels'][0]
  onComplete: () => Promise<any>
  onCancel: () => void
}

export const MatterportProcessModal = (props: MatterportProcessModalProps) => {
  const [hideMutation] = useMutation<MatterportCreateMutation, MatterportCreateMutationVariables>(
    MATTERPORT_CREATE_MUTATION
  )
  const [loading, setLoading] = useState(false)
  const { sites } = useAppState()
  const toasts = useToasts()

  const [selectedSite, setSelectedSite] = useState<SitesQuerySite>()

  return (
    <div className='requires-subscription-popup'>
      <div className='requires-subscription-popup-content matterport'>
        <div className='requires-subscription-popup-content-header'>
          <img src={ASSETI_LOGO} alt='Asseti Logo' /> <span>Asseti</span>
        </div>
        <div className='requires-subscription-popup-content-text'>
          <h5>
            Submit <b>{props.model.name}</b> for processing?
          </h5>
          <Select<SitesQuerySite>
            id='site'
            label='Site'
            placeholder='Select site'
            onChange={(selected) => {
              setSelectedSite(selected)
            }}
            selectedValue={selectedSite}
            options={sites.data.sites.map((s) => {
              return {
                id: s.id,
                name: s.name,
                value: s,
                items: [],
              }
            })}
          />
          <h6>If a Matterpak needs to be ordered, additional costs may be incurred for your Matterport account</h6>
        </div>
        <div className='requires-subscription-popup-content-buttons'>
          <button className='btn btn-secondary' onClick={props.onCancel} disabled={loading}>
            Cancel
          </button>
          <button
            className='btn btn-primary'
            onClick={(e) => {
              e.preventDefault()
              setLoading(true)
              hideMutation({
                variables: {
                  input: {
                    matterportID: props.model.id,
                    day: props.model.date,
                    hasMatterpak: false,
                    siteID: selectedSite.id,
                  },
                },
              })
                .then(() => {
                  toasts.addTopLeft('Processing successfully submitted')
                  return props.onComplete()
                })
                .finally(() => {
                  setLoading(false)
                  props.onCancel()
                })
            }}
            disabled={loading || !selectedSite}
          >
            {loading ? 'Processing...' : 'Process'}
          </button>
        </div>
      </div>
      <div className='requires-subscription-popup-overlay'></div>
    </div>
  )
}
