import React, { useEffect, useState } from 'react'

import {
  BoundaryCategoryType,
  BoundaryCreateRequestT,
  BoundarySubcategoryType,
  CreateBoundaryMutation,
  SurveyBasedOperation,
} from '~/models'
import { string, object, kmlToPolygon } from '~/components'
import { FloatingInput, FloatingTextArea, useForm } from '../forms'
import { getTracking } from '../tracking'
import { useAppState } from '~/state'
import { OperationTypeSelect } from '../operation-type'
import CREATE_BOUNDARY from './mutation-boundary-create.gql'
import { FileSelector } from '../upload-panel/upload-panel-file-selector'
import { BoundariesMultipleModal } from './boundaries-multiple-modal'
import { useUser } from '~/base'
import { BOUNDARIES_ALLOWED_ORG_IDS, BoundaryAdditionalData } from './boundaries-extension-data'
import { BOUNDARY_CATEGORIES, BoundaryCategoryTypeSelect, BoundarySubcategoryTypeSelect } from './boundary-categories'

interface BoundaryCreateFormProps {
  cancel: () => void
  complete: () => Promise<void>
  points: React.MutableRefObject<Cesium.Cartesian3[]>
  setPointsFromKML: React.Dispatch<React.SetStateAction<Cesium.Cartesian3[]>>
}

export const BoundaryCreateForm = (props: BoundaryCreateFormProps) => {
  const { view, timeline } = useAppState()
  const [multipleBoundaries, setMultipleBoundaries] = useState<Array<Cesium.Cartesian3[]>>()
  const user = useUser()
  const canSeeAdditionalData = !view.assetID && BOUNDARIES_ALLOWED_ORG_IDS.findIndex((x) => x === user.currentOrgId) !== -1

  const { values, touched, errors, isSubmitting, handleChange, handleBlur, handleSubmit, error, setFieldValue } =
    useForm<CreateBoundaryMutation, BoundaryCreateRequestT & BoundaryAdditionalData, BoundaryCreateRequestT>({
      enableReinitialize: false,
      initialValues: {
        siteID: view.siteID,
        assetID: view.assetID,
        name: 'Boundary',
        points: [],
        surveyID: timeline.activeSurvey?.id,
        operationType: SurveyBasedOperation.GoingForward,
        additionalData: '',
        category: BoundaryCategoryType.Cadastral,
        subcategory: BoundarySubcategoryType.Owned,
        sourceInformation: '',
      },
      validationSchema: object().shape({
        name: string().required('Required'),
        category: canSeeAdditionalData ? string().required('Required') : string(),
        subcategory: canSeeAdditionalData ? string().required('Required') : string(),
        sourceInformation: canSeeAdditionalData ? string().required('Required') : string(),
      }),
      mutation: CREATE_BOUNDARY,
      mapInput: (input) => {
        const toRet: BoundaryCreateRequestT = {
          siteID: view.siteID,
          assetID: view.assetID || '',
          name: input.name,
          points: props.points.current.map((p) => {
            const pos = Cesium.Cartographic.fromCartesian(p)
            return {
              latitude: (pos.latitude / Math.PI) * 180,
              longitude: (pos.longitude / Math.PI) * 180,
            }
          }),
          surveyID: timeline.activeSurvey.id,
          operationType: input.operationType,
          additionalData: '',
          additional: canSeeAdditionalData
            ? {
                category: input.category,
                subcategory: input.subcategory,
                sourceInformation: input.sourceInformation || '',
              }
            : undefined,
        }
        return toRet
      },
      onSuccess: () => {
        getTracking().event({
          category: 'Boundary',
          action: `User Created Boundary`,
          label: 'Boundary',
        })
        props.complete()
      },
    })

  useEffect(() => {
    const cat = BOUNDARY_CATEGORIES.find((x) => x.type === values.category)
    if (cat) {
      if (cat.type === BoundaryCategoryType.Cadastral) {
        setFieldValue('subcategory', BoundarySubcategoryType.Owned)
      } else {
        setFieldValue('subcategory', cat.subcategories[0].type)
      }
    }
  }, [values.category])

  if (!timeline.activeSurvey) {
    props.cancel()
    return null
  }

  return (
    <>
      <div className='drawer-panel'>
        <nav aria-label='breadcrumb'>
          <ol className='breadcrumb'>
            <li className='breadcrumb-item'>
              <a
                href='#'
                onClick={(e) => {
                  e.preventDefault()
                  props.cancel()
                }}
              >
                Site
              </a>
            </li>
            <li className='breadcrumb-item active' aria-current='page'>
              Boundary
            </li>
          </ol>
        </nav>
        <div className='drawer-panel-title-container'>
          <h6 className='drawer-panel-title'>Create Boundary</h6>
        </div>
        <form autoComplete='off' className='drawer-panel-form-container' onSubmit={handleSubmit}>
          {error && <div className='error'>{error}</div>}
          <FloatingInput
            label='Name'
            id='name'
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
            helperText={touched.name ? errors.name : ''}
            error={touched.name && Boolean(errors.name)}
          />
          {canSeeAdditionalData && (
            <>
              <BoundaryCategoryTypeSelect name='category' selectedValue={values.category} onChange={handleChange} />
              <BoundarySubcategoryTypeSelect
                name='subcategory'
                selectedCategory={values.category}
                selectedValue={values.subcategory}
                onChange={handleChange}
              />

              <FloatingTextArea
                label='Source Information'
                id='sourceInformation'
                value={values.sourceInformation}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={touched.sourceInformation ? errors.sourceInformation : ''}
                error={touched.sourceInformation && Boolean(errors.sourceInformation)}
                rows={8}
              />
              <br />
            </>
          )}
          <p className='drawer-panel-hint'>
            Use your cursor to draw your boundary on the map or upload the boundary from a KML file. <br />
            <FileSelector
              singleFile
              accept='.kml'
              text='Select KML file'
              className='profile-form-btn btn boundaries-file-select'
              filesSelected={(files) => {
                const reader = new FileReader()
                reader.onload = function () {
                  const xml = reader.result.toString()
                  const allCoords = kmlToPolygon(xml)
                  if (allCoords.length > 0) {
                    if (allCoords.length === 1) {
                      props.setPointsFromKML(allCoords[0])
                    } else {
                      setMultipleBoundaries(allCoords)
                    }
                  }
                }
                reader.readAsText(files[0])
              }}
            />
          </p>
          <OperationTypeSelect
            name='operationType'
            selectedValue={values.operationType}
            onChange={handleChange}
            survey={timeline.activeSurvey}
          />
          <div className='drawer-panel-form-container-action-container'>
            <button
              type='submit'
              className='btn submit mb-2'
              disabled={
                isSubmitting || props.points.current.length < 3 || (canSeeAdditionalData && !values.subcategory)
              }
            >
              Save
            </button>
            <button className='btn cancel' onClick={() => props.cancel()}>
              Cancel
            </button>
          </div>
        </form>
      </div>
      {multipleBoundaries && (
        <BoundariesMultipleModal
          boundaries={multipleBoundaries}
          onComplete={() => props.complete()}
          onCancel={() => {
            props.setPointsFromKML(multipleBoundaries[0])
            setMultipleBoundaries(undefined)
          }}
        />
      )}
    </>
  )
}
