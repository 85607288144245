import React, { useEffect, useMemo, useState } from 'react'
import { ComponentCondition, ComponentDtoT, MeasurementType, SiteQueryComponent } from '~/models'
import { useUser, ClientPermission } from '~/base'
import {
  calculateComponentMeasurement,
  calculateComponentMeasurementWithoutLabel,
  classes,
  formatComponentMeasurement,
  handleCurrencyFormat,
} from '../helpers'
import { useAppState } from '~/state'
import { sortComponents } from './components-utils'
import { DrawerPopoutList, DrawerPopoutListCard } from '../drawer'
import { SearchBox } from '../forms'

type Component = SiteQueryComponent & {
  measurementFormatted: string
}

interface ComponentGroup {
  name: string
  measurement: number
  measurementFormatted: string
  score: number
  components: Component[]
  replacementCost: number
}

interface ComponentFullListProps {
  components?: SiteQueryComponent[] 
}

export const ComponentFullList = (props: ComponentFullListProps) => {
  const { asset, timeline, view } = useAppState()
  const [components, setComponents] = useState<SiteQueryComponent[]>([])
  const [componentGroups, setComponentGroups] = useState<ComponentGroup[]>([])
  const [filteredComponents, setFilteredComponents] = useState<SiteQueryComponent[]>([])
  const [filteredGroups, setFilteredGroups] = useState<ComponentGroup[]>([])
  const [search, setSearch] = useState<string | undefined>()
  const loaded = view.assetID ? !!asset.asset : !!timeline.activeSurvey

  useEffect(() => {
    if (!loaded) {
      return
    }
    const components = props.components || (
      view.assetID
        ? [...asset.asset.components]
        : timeline.activeSurvey.assets.reduce((a, b) => {
            return [...a, ...b.components]
          }, [])
    ).sort(sortComponents) as ComponentDtoT[]
    setComponents([...components])
    setFilteredComponents([...components].sort(sortComponents))

    const groups: {
      [key: string]: ComponentGroup
    } = {}

    for (const c of components) {
      const key = c.componentTypeFullName + c.materialTypeName

      if (!groups[key]) {
        groups[key] = {
          name: c.componentTypeFullName + (c.materialTypeName === '' ? '' : ' - ' + c.materialTypeName),
          measurement: 0,
          measurementFormatted: '',
          components: [],
          score: 0,
          replacementCost: 0,
        }
      }

      const m = calculateComponentMeasurementWithoutLabel(c)
      const formatted = calculateComponentMeasurement(c)
      groups[key].measurement += m
      groups[key].measurementFormatted = formatComponentMeasurement(c, groups[key].measurement)

      let score = 0
      if (c.condition === ComponentCondition.Excellent) {
        score += 5
      } else if (c.condition === ComponentCondition.Good) {
        score += 4
      } else if (c.condition === ComponentCondition.Average) {
        score += 3
      } else if (c.condition === ComponentCondition.Poor) {
        score += 2
      } else if (c.condition === ComponentCondition.VeryPoor) {
        score += 1
      } else if (c.condition === ComponentCondition.EndOfLife) {
        score += 0
      }

      if (c.costPerUnit !== 0) {
        if (formatted === 'No measurement') {
          groups[key].replacementCost = c.costPerUnit * (groups[key].components.length + 1)
        } else {
          groups[key].replacementCost += c.costPerUnit * m
        }
      }
      groups[key].score += score
      groups[key].components.push({
        ...c,
        measurementFormatted: formatted,
      })
    }

    const sortedKeys = Object.keys(groups).sort()

    const arr = sortedKeys.map((k) => groups[k])
    setComponentGroups(arr)
    setFilteredGroups(arr)
  }, [asset, timeline.activeSurvey, loaded])

  useEffect(() => {
    if (!search || search === '') {
      setFilteredComponents([...components].sort(sortComponents))
      setFilteredGroups(componentGroups)
      return
    }

    const s = search.toLocaleLowerCase()

    const matches: SiteQueryComponent[] = []

    for (const c of components) {
      const cLowerName = (c.name || '').toLocaleLowerCase()
      const cLowerType = c.componentTypeFullName.toLocaleLowerCase()
      let cMatches = cLowerName.indexOf(s) !== -1 || cLowerType.indexOf(s) !== -1
      if (cMatches) {
        matches.push(c)
        continue
      }
    }
    setFilteredComponents(matches.sort(sortComponents))

    const arr = componentGroups
      .map((k) => {
        const cLowerType = k.name.toLocaleLowerCase()
        let cMatches = cLowerType.indexOf(s) !== -1
        if (cMatches) {
          return k
        }
      })
      .filter((x) => !!x)
    setFilteredGroups(arr)
  }, [componentGroups, search])

  const Controls = useMemo(() => {
    return <SearchBox search={search} setSearch={setSearch} />
  }, [search])

  return (
    <DrawerPopoutList
      width={420}
      title='Components'
      total={components.length}
      count={filteredComponents.length}
      emptyText='There are no components to display.'
      controls={Controls}
    >
      {filteredGroups.map((c) => {
        return <ComponentGroup key={c.name} group={c} />
      })}
    </DrawerPopoutList>
  )
}

interface ComponentGroupProps {
  group: ComponentGroup
}

function ComponentGroup(props: ComponentGroupProps) {
  const [expanded, setExpanded] = useState(false)
  const { components } = useAppState()
  const user = useUser()

  return (
    <>
      <div
        className='component-group'
        onClick={() => {
          setExpanded(!expanded)
        }}
      >
        <div className='component-group-upper'>
          <span>{props.group.name}</span> <span>({props.group.components.length}) </span>
        </div>
        <div className='component-group-lower'>
          <span className='component-group-area'>{props.group.measurementFormatted}</span>
          <span
            className={classes({
              'component-group-condition': true,
              [getScoreClass(props.group.score / props.group.components.length)]: true,
            })}
          >
            {getScore(props.group.score / props.group.components.length)}
          </span>
          {props.group.replacementCost !== 0 && (
            <span className='component-group-area' title='Replacement cost'>
              {handleCurrencyFormat(props.group.replacementCost, user)}
            </span>
          )}
        </div>
      </div>
      {expanded &&
        props.group.components.map((c) => (
          <ComponentCard
            component={c}
            toRemove={components.componentToDelete}
            setToRemove={components.setComponentToDelete}
          />
        ))}
    </>
  )
}

interface ComponentCardProps {
  component: SiteQueryComponent
  toRemove: SiteQueryComponent
  setToRemove: (b: SiteQueryComponent) => void
}

function ComponentCard(props: ComponentCardProps) {
  const user = useUser()
  const { view, components, site, entityContext, timeline } = useAppState()
  const component = props.component
  const isDemoSite = site.site?.isDemoSite
  const canEdit = !isDemoSite && user.hasPermission(ClientPermission.ComponentsUpdate)
  const canRemove = !isDemoSite && user.hasPermission(ClientPermission.ComponentsRemove)
  const m = calculateComponentMeasurementWithoutLabel(props.component)

  return (
    <DrawerPopoutListCard
      key={view.surveyID + component.id}
      active={
        component.id === components.selectedComponent?.id ||
        component.id === components.componentToUpdate?.id ||
        component.id === props.toRemove?.id
      }
      className='component-card'
      onClick={(e) => {
        if (e.defaultPrevented) {
          return
        }
        entityContext.set(component)
      }}
    >
      <div className='component-card-upper'>
        {component.componentTypeFullName}
        {canRemove && (
          <i
            title='Remove'
            className='material-icons'
            onClick={(e) => {
              e.preventDefault()
              props.setToRemove(component)
            }}
          >
            delete
          </i>
        )}
        {canEdit && (
          <i
            title='Update'
            className='material-icons'
            onClick={(e) => {
              e.preventDefault()
              components.setComponentToUpdate(component)
              timeline.setActiveInteriorModel(timeline.activeSurvey.reconstructions.find((x) => x.matterport))
            }}
          >
            edit
          </i>
        )}
      </div>
      {component.name && <div className='component-card-middle'> {component.name}</div>}
      <div className='component-card-lower'>
        <div
          className={classes({
            'component-card-area': true,
          })}
          title='Measurement'
        >
          {calculateComponentMeasurement(component)}
        </div>
        <div
          className={classes({
            'component-card-condition': true,
            [component.condition]: true,
          })}
          title='Condition'
        >
          {(component.condition || '').replace(/([A-Z])/g, ' $1').trim()}
        </div>
        {props.component.costPerUnit !== 0 && (
          <div
            className={classes({
              'component-card-area': true,
            })}
          >
            {handleCurrencyFormat(
              props.component.costPerUnit * (props.component.measurement === MeasurementType.Point ? 1 : m),
              user
            )}
          </div>
        )}
      </div>
    </DrawerPopoutListCard>
  )
}

function getScore(score: number) {
  if (score >= 4.5) {
    return 'Excellent'
  } else if (score > 4) {
    return 'Good to Excellent'
  } else if (score > 3.8) {
    return 'Good'
  } else if (score >= 3.2) {
    return 'Average to Good'
  } else if (score > 2.8) {
    return 'Average'
  } else if (score >= 2.2) {
    return 'Poor to Average'
  } else if (score > 1.8) {
    return 'Poor'
  } else if (score >= 1.2) {
    return 'Very Poor to Poor'
  } else if (score > 0.8) {
    return 'Very Poor'
  } else if (score > 0.5) {
    return 'End of Life to Very Poor'
  } else {
    return 'End of Life'
  }
}

function getScoreClass(score: number): ComponentCondition {
  if (score >= 4.5) {
    return ComponentCondition.Excellent
  } else if (score > 4) {
    return ComponentCondition.Excellent
  } else if (score > 3.8) {
    return ComponentCondition.Good
  } else if (score >= 3.2) {
    return ComponentCondition.Good
  } else if (score > 2.8) {
    return ComponentCondition.Good
  } else if (score >= 2.2) {
    return ComponentCondition.Poor
  } else if (score > 1.8) {
    return ComponentCondition.Poor
  } else if (score >= 1.2) {
    return ComponentCondition.VeryPoor
  } else if (score > 0.8) {
    return ComponentCondition.VeryPoor
  } else if (score > 0.5) {
    return ComponentCondition.EndOfLife
  } else {
    return ComponentCondition.EndOfLife
  }
}
