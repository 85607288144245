import constate from 'constate'
import { useState } from 'react'
import { Config } from '~/config'
import { Currency, SignInOrgT, SignInResponseT, SignInSupplierT, SystemRole } from '~/models'

export interface UserInfo {
  id: string
  firstName: string
  lastName: string
  fullName: string
  email: string
  phoneNumber: string
  systemRole: SystemRole
  org: SignInOrgT
  orgs: SignInOrgT[]
  supplier: SignInSupplierT
  suppliers: SignInSupplierT[]
  showDemoDashboards: boolean
  showDemoSites: boolean
  requires2FA: boolean
  currentOrgId: string
  currentSupplierID: string
  policy: string[]
}

export enum DemoViewType {
  Advanced,
  Medium,
  Basic,
}

function getUserInfoFromSignIn(data: SignInResponseT): UserInfo {
  const orgs = data?.orgs || []
  const org = orgs.find((x) => x.id === data.currentOrgId)
  const suppliers = data?.suppliers || []
  const supplier = suppliers.find((x) => x.id === data.currentSupplierID)
  const userInfo = {
    id: data?.userId,
    firstName: data?.firstName,
    lastName: data?.lastName,
    fullName: data?.firstName + ' ' + data?.lastName,
    email: data?.email,
    systemRole: data?.systemRole,
    org,
    orgs: data?.orgs,
    supplier,
    suppliers: data?.suppliers,
    showDemoDashboards: data.showDemoDashboards,
    showDemoSites: data.showDemoSites,
    requires2FA: data.requires2FA,
    currentOrgId: data.currentOrgId,
    currentSupplierID: data.currentSupplierID,
    policy: data.policy,
    phoneNumber: data.phoneNumber,
  }
  return userInfo
}

export function useUserImpl() {
  const local = JSON.parse(localStorage.getItem('asseti_auth') || '{}') as SignInResponseT
  const [signIn, setSignIn] = useState<SignInResponseT>(local)
  const [loaded, setLoaded] = useState<boolean>(!!localStorage.getItem('asseti_auth'))
  const [ignoreIA, setIgnoreIA] = useState<boolean>(false)
  const [userInfo, setUserInfo] = useState<UserInfo>(
    getUserInfoFromSignIn(JSON.parse(localStorage.getItem('asseti_auth') || '{}') as SignInResponseT)
  )
  const [token, setToken] = useState<string>(localStorage.getItem('asseti_token_new'))
  const [originalPolicy, setOriginalPolicy] = useState<string[]>(signIn?.policy)
  const [demoViewType, setDemoViewType] = useState<DemoViewType>(DemoViewType.Advanced)
  window.__asseti_data_qs = `Key-Pair-Id=${signIn?.dataKeyID}&Signature=${signIn?.dataSignature}&Policy=${signIn?.dataPolicy}`
  window.__asseti_demo_qs = `Key-Pair-Id=${Config.DemoDataKeyID}&Signature=${Config.DemoDataSignature}&Policy=${Config.DemoDataPolicy}`

  const policyFromArray = (permissions: ClientPermission[]) => {
    const newPolicy = [BigInt(0), BigInt(0), BigInt(0), BigInt(0)]
    for (const permission of permissions) {
      const arrayIndex = Math.floor(permission / 64)
      const bitIndex = BigInt(1) << BigInt(permission % 64)
      newPolicy[arrayIndex] |= bitIndex
    }
    return newPolicy.map((p) => p.toString())
  }

  const isLoggedIn = typeof signIn?.token === 'string' && typeof signIn?.dataKeyID === 'string'

  return {
    signIn,
    ...userInfo,
    token,
    loaded,
    isLoggedIn,
    demoViewType,
    setSignIn: (data: SignInResponseT) => {
      for (const org of data.orgs) {
        if (!org.currency) {
          org.currency = Config.DefaultCurrency
        }
      }
      window.__asseti_data_qs = `Key-Pair-Id=${data.dataKeyID}&Signature=${data.dataSignature}&Policy=${data.dataPolicy}`
      window.__asseti_demo_qs = `Key-Pair-Id=${Config.DemoDataKeyID}&Signature=${Config.DemoDataSignature}&Policy=${Config.DemoDataPolicy}`
      localStorage.setItem('asseti_auth', JSON.stringify(data))
      localStorage.setItem('asseti_token_new', data.token)
      setSignIn(data)
      setUserInfo(getUserInfoFromSignIn(data))
      setToken(data.token)
      setOriginalPolicy(data.policy)
      setLoaded(true)
    },
    setToken: (token: string) => {
      localStorage.setItem('asseti_token_new', token)
    },
    hasPermission: (perm: ClientPermission) => {
      if (!Array.isArray(signIn.policy) || signIn.policy.length !== 4) {
        return false
      }

      const arrayIndex = Math.floor(perm / 64)
      const bitIndex = BigInt(1) << BigInt(perm % 64)
      const policy = BigInt(signIn.policy[arrayIndex])
      return (policy & bitIndex) !== BigInt(0)
    },
    logout: (callback?: () => void) => {
      localStorage.clear()

      if (callback) {
        callback()
      }
    },
    setOrgCurrency: (c: Currency) => {
      const s = { ...signIn }
      const org = s.orgs.find((o) => o.id === s.currentOrgId)
      org.currency = c
      setSignIn(s)
      setUserInfo(getUserInfoFromSignIn(s))
    },
    endSupportSession: () => {
      const s = { ...signIn }
      s.currentOrgId = ''
      s.orgs = []
      setSignIn(s)
      setUserInfo(getUserInfoFromSignIn(s))
    },
    setBasicDemoRole: () => {
      const newPolicy = policyFromArray([
        ClientPermission.SitesList,
        ClientPermission.SitesGet,
        ClientPermission.AssetsList,
        ClientPermission.AssetsGet,
        ClientPermission.AssetsCreate,
        ClientPermission.AssetsUpdate,
        ClientPermission.AssetsRemove,
        ClientPermission.BoundariesList,
        ClientPermission.BoundariesGet,
        ClientPermission.BoundariesCreate,
        ClientPermission.BoundariesUpdate,
        ClientPermission.BoundariesRemove,
        ClientPermission.DataViewStatus,
        ClientPermission.ImagesList,
        ClientPermission.TeamList,
        ClientPermission.AnnotationsRead,
        ClientPermission.AnnotationsCreate,
        ClientPermission.AnnotationsUpdate,
        ClientPermission.AnnotationsRemove,
      ])
      const s = { ...signIn }
      s.policy = newPolicy
      setSignIn(s)
      setUserInfo(getUserInfoFromSignIn(s))
      setDemoViewType(DemoViewType.Basic)
    },
    setMediumDemoRole: () => {
      const newPolicy = policyFromArray([
        ClientPermission.SitesList,
        ClientPermission.SitesGet,
        ClientPermission.AssetsList,
        ClientPermission.AssetsGet,
        ClientPermission.AssetsCreate,
        ClientPermission.AssetsUpdate,
        ClientPermission.AssetsRemove,
        ClientPermission.BoundariesList,
        ClientPermission.BoundariesGet,
        ClientPermission.BoundariesCreate,
        ClientPermission.BoundariesUpdate,
        ClientPermission.BoundariesRemove,
        ClientPermission.DataViewStatus,
        ClientPermission.ImagesList,
        ClientPermission.TeamList,
        ClientPermission.IssuesList,
        ClientPermission.IssuesCreate,
        ClientPermission.IssuesUpdate,
        ClientPermission.IssuesRemove,
        ClientPermission.ActivityList,
        ClientPermission.AnnotationsRead,
        ClientPermission.AnnotationsCreate,
        ClientPermission.AnnotationsUpdate,
        ClientPermission.AnnotationsRemove,
      ])
      const s = { ...signIn }
      s.policy = newPolicy
      setSignIn(s)
      setUserInfo(getUserInfoFromSignIn(s))
      setDemoViewType(DemoViewType.Medium)
    },
    setAdvancedDemoRole: () => {
      const s = { ...signIn }
      s.policy = originalPolicy
      setSignIn(s)
      setUserInfo(getUserInfoFromSignIn(s))
      setDemoViewType(DemoViewType.Advanced)
    },
    isInstantAssessmentOrg: isLoggedIn && signIn.orgs.find(o => o.id === signIn.currentOrgId)?.isIA && !ignoreIA,
    ignoreIA,
    setIgnoreIA,
  }
}

export const [UserStateProvider, useUser] = constate(useUserImpl)

export enum ClientPermission {
  SitesList = 0,
  SitesGet,
  SitesCreate,
  SitesUpdate,
  SitesRemove,
  AssetsList,
  AssetsGet,
  AssetsCreate,
  AssetsUpdate,
  AssetsRemove,
  BoundariesList,
  BoundariesGet,
  BoundariesCreate,
  BoundariesUpdate,
  BoundariesRemove,
  MonitoringZonesList,
  MonitoringZonesGet,
  MonitoringZonesCreate,
  MonitoringZonesUpdate,
  MonitoringZonesRemove,
  DataUpload,
  DataViewStatus,
  DashboardsList,
  DashboardsGet,
  DashboardsCreate,
  DashboardsUpdate,
  DashboardsRemove,
  ActivityList,
  ImagesList,
  IssuesList,
  IssuesGet,
  IssuesCreate,
  IssuesUpdate,
  IssuesRemove,
  TeamList,
  TeamInvite,
  TeamUpdate,
  TeamRemove,
  ManageOrg,
  ComponentsRead,
  MatterportAuth,
  MatterportCreate,
  AnnotationsRead,
  AnnotationsCreate,
  AnnotationsUpdate,
  AnnotationsRemove,
  CesiumAuth,
  CesiumCreate,
  AnalysisRead,
  AnalysisCreate,
  AnalysisUpdate,
  AnalysisRemove,
  ComponentsCreate,
  ComponentsUpdate,
  ComponentsRemove,
  ManageSiteGroups,
}
