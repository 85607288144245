import React, { useMemo, useState } from 'react'
import { useAppState } from '~/state'
import { OverviewCard, useLocation, useToasts } from '~/components'
import { ClientPermission, useUser } from '~/base'
import { SiteRemove } from './site-remove'
import { RiskType, SiteQuerySite } from '~/models'

interface SiteInfoProps {
  updateSite: () => void
  site: SiteQuerySite
  loading: boolean
}

export const SiteInfo = (props: SiteInfoProps) => {
  const { sites, view, timeline } = useAppState()
  const location = useLocation()
  const toasts = useToasts()
  const user = useUser()
  const canListIssues = !props.site?.isDemoSite && user.hasPermission(ClientPermission.IssuesList)
  const canUpdateSite = !props.site?.isDemoSite && user.hasPermission(ClientPermission.SitesUpdate)
  const canRemoveSite = !props.site?.isDemoSite && user.hasPermission(ClientPermission.SitesRemove)
  const [showDelete, setShowDelete] = useState(false)
  const isIA = user.isInstantAssessmentOrg

  const siteInfoProps = useMemo(() => {
    const lastUploadTime = (sites.summaryQuery?.data?.queryImageSummaryByOrg?.sites || []).find(
      (x) => x.siteID === view.siteID
    )?.lastUpload

    let numHighIssues = 0
    let numMediumIssues = 0
    let numLowIssues = 0

    for (const i of timeline.activeSurvey?.issues || []) {
      if (i.riskType === RiskType.High) {
        numHighIssues++
      } else if (i.riskType === RiskType.Medium) {
        numMediumIssues++
      } else if (i.riskType === RiskType.Low) {
        numLowIssues++
      }
    }

    return {
      lastUploadTime,
      numAssets: (timeline.activeSurvey?.assets || []).length,
      numHighIssues,
      numLowIssues,
      numMediumIssues,
    }
  }, [timeline.activeSurvey])

  return (
    <>
      <OverviewCard
        {...siteInfoProps}
        isDemo={props.site?.isDemoSite}
        number={props.site?.number}
        type='Site'
        title={props.site?.name}
        haveAddress
        address={props.site?.address?.place_name}
        canUpdate={canUpdateSite}
        canRemove={canRemoveSite}
        onClickEdit={props.updateSite}
        onClickDelete={() => setShowDelete(true)}
        canListOverview={!isIA && canListIssues && !user.org.isBasic}
        groups={(isIA ? [] : (sites.groups || [])).filter(g => g.sites.findIndex(s => s.s.id === props.site?.id) !== -1)}
      />
      {showDelete && (
        <SiteRemove
          hide={() => setShowDelete(false)}
          onRemove={() => {
            toasts.addTopLeft('Site successfully deleted.')
            sites.refetch().then(() => {
              location.setLocation('/')
            })
          }}
        />
      )}
    </>
  )
}
